import { EntityId } from "../types/global.js";
import { uuidv4 } from "../ui_general/ar_utils.js";
import { Meditation, MeditationSummaryData } from "./meditation";
import Userdata from "./Userdata.js";

type Stats = {
  /**
    'timestamp' corresponds to the last time that one of the properties on this Journal instance was updated. We use this timestamp  when merging local and server copies of the data to determine which one to keep.
  */
  timestamp: string | undefined;
}

export class JournalEntry {

  public readonly uuid: EntityId;
  public readonly id: EntityId;
  public readonly date: Date;
  public readonly meditationId: EntityId;
  public readonly meditationData: MeditationSummaryData;
  public deleted: boolean;
  public text: string | null;
  public userStats: Stats = {
    timestamp: undefined,
  };

  constructor({
    text,
    meditationId,
    meditationData,
    uuid = uuidv4(),
    date = new Date(),
  }: {
    text?: string | null,
    meditationId: EntityId,
    meditationData: MeditationSummaryData
    uuid?: EntityId,
    date?: Date,
  }) {
    this.uuid = uuid;
    this.id = this.uuid;
    this.date = date;
    this.text = text?.trim() ?? "";
    this.meditationId = meditationId;
    this.meditationData = Object.assign({}, meditationData);
    this.deleted = false;
    this.userStats = {
      timestamp: undefined,
    };
    delete this.meditationData.transcript;
  }

  saveJournal(text: string) {
    this.instanceUpdated();
    this.text = text.trim() ?? null;

    if (this.text === null || this.text === "") {
      this.deleteJournalEntry();
    } else {
      this.deleted = false;
      window.Userdata.journalData.usageData.set(this.uuid, this);
    }

    this.syncEntry();
  }

  deleteJournalEntry() {
    this.instanceUpdated();
    this.text = null;
    this.deleted = true;
    window.Userdata.journalData.usageData.set(this.uuid, this);
    this.syncEntry();
    this.animateDeletingJournal();
  }

  /**
    Returns all journal entries associated with this same meditation;
  **/
  getRelatedEntries(meditationId: EntityId): JournalEntry[] {
    return window.Userdata.journalData.getJournalsByKey('meditationId', meditationId);
  }

  private instanceUpdated(): string {
    return (this.userStats.timestamp = new Date().toString());
  }

  private syncEntry(): void {
    Meditation.getInstance({ meditationSummaryData: this.meditationData })?.watchJournals();

    // ...to Local Storage
    this.updateLSJournals();

    // ...to server
    window.Userdata.saveToServer("journal_data");
  }

  private updateLSJournals(): void {

    // const localStorageJournalEntries = localStorage.getItem("AR_Userdata_journal_data");
    // // console.log('ls', localStorageJournalEntries)

    // const journalEntries = window.Userdata.journalData.usageData;

    // // Update an existing entry?
    // const findExistingEntry = [...journalEntries.values()].find((entry: JournalEntry) =>
    //   entry.date === this.date &&
    //   entry.meditationId === this.meditationId
    // );

    // if (findExistingEntry) {
    //   journalEntries.set(findExistingEntry.uuid, this);
    // }

    // // Filter empty entries
    // const filteredJournalEntries: Map<string, JournalEntry> =
    //   new Map(
    //     Array.from(journalEntries).filter(([_, journalEntry]: [string, JournalEntry]) => {
    //       return journalEntry.text; // Now checking the single entry's text
    //     })
    //   );

    // console.log("syncEntry...", filteredJournalEntries);

    const journalEntries = window.Userdata.journalData.usageData;

    localStorage.setItem(
      "AR_Userdata_journal_data",
      JSON.stringify(Userdata.serializeMap(journalEntries)),
    );
  }

  private animateDeletingJournal(): void {
    document
      .querySelectorAll<HTMLElement>(`div.journal_entry[data-uuid='${this.uuid}']`)
      .forEach((journalEntryElement: HTMLElement) => {
        const journalElementHeightPx = journalEntryElement.offsetHeight + "px";

        // Must set height to absolute value to animate it collapsing
        journalEntryElement.style.height = journalElementHeightPx;

        // Force reflow by accessing the computed style
        window.getComputedStyle(journalEntryElement).height;

        // Guaranteed to happen in separate step due to prior reflow
        journalEntryElement.dataset.delete = String(true);

        /**
          We animate the margin-bottom collapsing after the CSS height transition finishes.
          This makes it smoother and allows us to delete the element in a separate step.
        **/
        function hideJournalFromDOM() {
          journalEntryElement.style.setProperty("margin-bottom", "0", "important");
          journalEntryElement.removeEventListener("transitionend", hideJournalFromDOM);

          journalEntryElement.addEventListener("transitionend", () => {
            journalEntryElement.remove();
          });
        }

        journalEntryElement.addEventListener("transitionend", hideJournalFromDOM);
      });
  }

}

import { Assessment } from "../classes/assessment";
import { EventRecording } from "../classes/EventRecording";
import { Meditation, MeditationSummaryData } from "../classes/meditation";
import { SPC } from "../classes/SPC";
import Userdata from "../classes/Userdata";
import { ARContentNameType as ARContentTypeName, EntityId } from "../types/global";

export class UserUsageHandler {
  /*
    handles just one of the following:
    - meditation,
    - spc,
    - online_event
    - assessment
  */

  public keyName: ARContentTypeName;
  public usageData: Map<EntityId, any>;

  constructor(keyName: ARContentTypeName) {
    this.keyName = keyName;
    this.usageData = new Map();
  }
}

export class MeditationUsageHandler extends UserUsageHandler {
  /**
    this handler is accessed on the Userdata singleton, as Userdata.meditationData.

    Any information about the meditation stats for a user are contained on this object.
    It has a Map (named usageData) which contains each meditation that the user has done. These are instances of the Meditation class.
  */

  public usageData: Map<EntityId, any>

  constructor(keyName: ARContentTypeName = 'meditation_data') {
    super(keyName);
    this.keyName = keyName;
    this.usageData = Userdata.deserializeMap(localStorage.getItem(`AR_Userdata_${keyName}`)) ?? new Map();

    console.log(`new Handler: ${keyName}`, this.usageData);
  }

  update(meditationSummaryData: MeditationSummaryData) {
    console.log(`${this.keyName} -------UPDATE-----`);
    console.log(this.usageData);

    this.usageData.set(String(meditationSummaryData.meditationId), meditationSummaryData);

    Userdata.saveToLS(this.keyName, Userdata.serializeMap(this.usageData));

    // localStorage.setItem(`AR_Userdata_${this.keyName}`, JSON.stringify(Userdata.serializeMap(this.usageData)));

    // console.log('meditationData.usageData.size:', this.usageData.size);
    // console.log('usageStatsObj', usageStatsObj)
    // console.log('this.usageData', this.usageData)
    // console.log('this.usageData serialized', serializeMap(this.usageData));

    window.Userdata.saveToServer(this.keyName);
  }

  sortByLastPlayed() {
    /**
      Sorts the usageData by when it was last played, latest comes first
    **/
    const recentHistoryLimit = 50;
    const list = Array.from(this.usageData)
      .filter(([id, data]) => data.userStats.lastPlayed)
      .sort(
        (a, b) => {
          const dateA = new Date(b[1].userStats.lastPlayed).getTime();
          const dateB = new Date(a[1].userStats.lastPlayed).getTime();

          if (isNaN(dateA)) console.warn('dateA is NaN', a)
          if (isNaN(dateB)) console.warn('dateB is NaN', b)

          return dateB - dateA;
        }
      )
      .slice(0, recentHistoryLimit);
    console.log('list', list)
    return list;
  }

  getFavourites() {
    /**
      Return the items in this.usageData that have the favourites property set true
    */
    const favMeditations: Meditation[] = [];
    this.usageData.forEach((meditation) => {
      if (meditation.userStats.favourite) {
        favMeditations.push(meditation);
      }
    });
    return favMeditations;
  }
}

export class SPCUsageHandler extends UserUsageHandler {
  /**
    this handler is accessed on the Userdata singleton, as Userdata.meditationData.

    Any information about the SPC stats for a user are contained on this object.
    It has a Map (named usageData) which contains each SPC that the user has done. These are instances of the SPC class.

  */

  public usageData: Map<EntityId, SPC>;

  constructor(keyName: ARContentTypeName = 'spc_data') {
    super(keyName);
    this.keyName = keyName;
    this.usageData = Userdata.deserializeMap(localStorage.getItem(`AR_Userdata_${keyName}`))
      ?? new Map();

    console.log(`new Handler: ${keyName}`, this.usageData);
  }

  update(usageStatsObj: SPC) {
    console.log(`${this.keyName} -------UPDATE-----`);

    this.usageData.set(String(usageStatsObj.spcId), usageStatsObj);
    console.log('spc update', this.usageData);

    Userdata.saveToLS(this.keyName, Userdata.serializeMap(this.usageData));

    // console.log('spcData.usageData.size:', this.usageData.size);
    // console.log('usageStatsObj', usageStatsObj)
    // console.log('this.usageData', this.usageData)
    // console.log('this.usageData serialized', serializeMap(this.usageData));

    window.Userdata.saveToServer(this.keyName);
  }

  getListOfStartedSPCs() {
    return new Map(
      [...this.usageData].filter(spc => spc[1].userStats.started)
    );
  }

  getFavourites() {
    /**
      Return the items in this.usageData that have the favourites property set true
    */
    const favSPCs: SPC[] = [];
    this.usageData.forEach((spc) => {
      if (spc.userStats.favourite) {
        favSPCs.push(spc);
      }
    });
    return favSPCs;
  }
}

export class AssessmentUsageHandler extends UserUsageHandler {

  public usageData: Map<EntityId, Assessment>;

  constructor(keyName: ARContentTypeName = 'assessment_data') {
    super(keyName);
    this.keyName = keyName;
    this.usageData = Userdata.deserializeMap(localStorage.getItem(`AR_Userdata_${keyName}`))
      ?? new Map();

    console.log(`new Handler: ${keyName}`, this.usageData);
  }
}

export class EventRecordingUsageHandler extends UserUsageHandler {

  public usageData: Map<EntityId, EventRecording>;

  constructor(keyName: ARContentTypeName = 'eventrecording_data') {
    super(keyName);
    this.keyName = keyName;
    this.usageData = Userdata.deserializeMap(localStorage.getItem(`AR_Userdata_${keyName}`))
      ?? new Map();

    console.log(`new Handler: ${keyName}`, this.usageData);
  }
}

import { printSPCPill } from "./meditation_row.js";
import { printTaxonomyPillsContainer } from "../ui_components/taxonomyPill.ts";
import { createMeditationJournalPaneContainer } from "../journal/journal_component.ts";
import { printJournalEntriesDivList } from "../journal/journal_component.ts";

export function createMeditationPaneContents({ row, meditation }) {
  console.log('ex0 ', row, meditation)
  // meditation = new Meditation(meditation);

  const meditationPaneContents = document.createElement("div");
  meditationPaneContents.classList.add("meditation_contents");

  const playerContainer = document.createElement("div");
  playerContainer.classList.add("meditation_player_container");
  meditationPaneContents.appendChild(playerContainer);

  const player = document.createElement("meditation-player");
  player.initialize(meditation);
  // Store the timestamp in LS so we can initialise it at that time again if need be?

  playerContainer.appendChild(player);

  const description = document.createElement("div");
  description.classList.add("description");
  description.innerHTML = `
    ${row.dataset.description || "In this meditation, <span style='opacity: 0.4'>placeholder description... quod aliquam libero libero laudantium vitae voluptatem. Ut voluptates eos eum dolores possimus quia repellendus recusandae. Nostrum ipsam pariatur ad et qui et culpa laboriosam.</span>"}
  `;
  meditationPaneContents.appendChild(description);

  const taxonomyPills = printTaxonomyPillsContainer({ row, postInstance: meditation });
  if (taxonomyPills) {
    meditationPaneContents.appendChild(taxonomyPills);
  }

  const spcPill = printSPCPill(row);
  if (spcPill) {
    meditationPaneContents.appendChild(spcPill);
  }

  // const dateRecorded = document.createElement('div');
  // dateRecorded.classList.add('date_recorded');
  // dateRecorded.innerHTML = dateformat(row.dateRecorded, "mid");
  // meditationPaneContents.appendChild(dateRecorded);

  const journalContainer = createMeditationJournalPaneContainer({ meditation });

  // Remove current entry from previous, if it exists.
  const previouslyStartedEntry = window.Userdata.journalData.findJournal({
    date: new Date(),
    meditationId: meditation.meditationId,
  });

  let previousEntries = (window.Userdata.journalData.toMap(
    window.Userdata.journalData.getJournalsByKey('meditationId', meditation.meditationId))
  );

  if (previouslyStartedEntry) {
    previousEntries = new Map([...previousEntries.entries()]
      .filter(([key, _]) => key !== previouslyStartedEntry.id));
  }

  // Build list of previous entries
  journalContainer.insertAdjacentElement(
    "beforeend",
    printJournalEntriesDivList(previousEntries),
  );
  meditationPaneContents.insertAdjacentElement("beforeend", journalContainer);

  return meditationPaneContents;
}

import * as app from "../data/ar_appstate";
import { restAPIRequest } from "../restapi/restapi_requests";
import { createMeditationRow } from "./meditation_row.js";
// import { createMeditationRow } from "./meditation_row.js";
import { getLibraryFilterSettings } from "../ui_components/library_filter";
import { icons } from "../ui_general/icons";

export async function uiGetMeditationPosts(argConfig = {
  page: 1,
  postsPerPage: 20,
  postType: 'meditation',
  ...getLibraryFilterSettings("meditation"),
}) {

  // const config = { ...argConfig };



  const libraryMain = document.querySelector(
    "section#meditation div#meditation_main",
  );
  const meditationList = document.querySelector(
    "section#meditation div#meditation_list",
  );
  const meditationPagination = document.querySelector(
    "section#meditation div#meditation_main div.pagination_wrap",
  );
  const loadSpinner = document.createElement("loading-spinner");

  if (libraryMain && !libraryMain.querySelector('loading-spinner')) {
    libraryMain.appendChild(loadSpinner);
  }
  meditationList.classList.add("global_blur");
  meditationPagination.classList.add("global_blur");

  return restAPIRequest(argConfig)
    .then((data) => {

      app.userState.recordingLibraryState.page = data.page;
      app.userState.recordingLibraryState.totalPages = data.totalPages;

      processMeditationPosts(data);

      document.dispatchEvent(
        new CustomEvent("restAPILoadComplete", {
          bubbles: true,
          composed: true,
          detail: {
            page: data.page,
            totalPages: data.totalPages,
            section: "meditation",
          },
        }),
      );
    })
    .finally(() => {
      app.userState.recordingLibraryState.isLoading = false;

      const loadingSpinner = libraryMain.querySelector("loading-spinner");

      if (loadingSpinner) {
        libraryMain.removeChild(loadingSpinner);
      }

      meditationList.classList.remove("global_blur");
      meditationPagination.classList.remove("global_blur");
      removeCSSHighlight('meditation');
      applyCSSHighlight('meditation');
    })
    .catch((error) => {
      console.error("uiGetMeditationPosts()", error);
      meditationList.innerHTML = "";

      const retryButton = document.createElement('button-std');
      retryButton.innerText = "Retry";
      retryButton.setAttribute('icon', icons.repeat);
      retryButton.addEventListener('click', () => {
        meditationList.innerHTML = "";
        uiGetMeditationPosts();
        // maybe make it also retry SPCs?
      });

      const errorBox = document.createElement('div');
      errorBox.classList.add('error_box');
      errorBox.innerHTML = `
        <p>Couldn't load meditations</p>
      `;
      // todo: if network error, print it here.
      errorBox.appendChild(retryButton);

      meditationList.appendChild(errorBox);
    });
}

export function processMeditationPosts({
  posts: posts,
  target: target,
  totalPages: totalPages,
  page: page,
} = {}) {
  // console.log("processMeditationPosts()", posts, "target", target);
  // console.log("Meditations:", posts, "Total Pages:", totalPages);

  const meditationList = document.querySelector(
    "section#meditation div#meditation_list",
  );
  if (target === undefined) target = meditationList;
  // console.log("processMeditation: target: ", target);

  // Update pages count widgets
  document.querySelector(
    "section#meditation #meditation_list_prev_next_wrap div#totalPages",
  ).innerText = totalPages || "";
  document.querySelector(
    "section#meditation #meditation_list_prev_next_wrap div#currentPage",
  ).innerText = page || "";

  // Clear list
  target.innerHTML = "";

  // Update list
  posts.forEach((meditationSummaryData) => {
    // console.log('post', post)
    const meditationRow = createMeditationRow({
      meditationSummaryData: meditationSummaryData,
      page: "library",
    });
    target.appendChild(meditationRow);

    // const meditationController = MeditationController.getInstance(meditationSummaryData);
    // target.appendChild(meditationController.newView({ page: 'library', showMenu: true }));
  });
  return { posts: posts, target: target, totalPages: totalPages, page: page };
}

export function applyCSSHighlight(postSlug = 'meditation') {
  Object.values(getLibraryFilterSettings(postSlug)).forEach((term, taxonomy) => {
    if (term !== "") {
      if (taxonomy !== "duration") {
        createCSSHighlight({ term: term });
      }
    }
  });
}

export function removeCSSHighlight(postSlug = 'meditation') {
  document
    .querySelectorAll(`style[title=${postSlug}LibraryFilterHighlights]`)
    .forEach((sheet) => sheet.remove());
}

export function createCSSHighlight({ postSlug = "meditation", term }) {
  const title = `${postSlug}LibraryFilterHighlights`;

  const highlightSheet = document.createElement("style");
  document.head.appendChild(highlightSheet);
  highlightSheet.setAttribute("title", title);
  highlightSheet.dataset['term'] = term;
  highlightSheet.sheet.insertRule(
    `
      body {
        section#${postSlug} {
          div.term_bubble[data-slug='${term}'] {
            order: 0;
            // outline-color: hsl(208, 100%, 61%);
            // outline-width: 1px;
            // outline-style: solid;
            opacity: 1 !important;
            //background: white;
          }
          div.term_bubble {
            opacity: 0.3 !important;
          }
        }
      }
    `,
  );

  return highlightSheet;
}

import dateformat from "dateformat";
import { JournalEntry } from "../classes/journal_entry";
// @ts-ignore
import { ChatEntry } from "../classes/chat.js";
import Userdata from "../classes/Userdata";
import { ARContentNameType, EntityId } from "../types/global";

export class UserContentHandler {

  public keyName: ARContentNameType;
  public usageData: Map<EntityId, any>;

  constructor(keyName: ARContentNameType) {
    this.keyName = keyName;
    this.usageData = new Map();
  }

  readDataFromStorage(keyName: ARContentNameType) {
    this.usageData =
      JSON.parse(localStorage.getItem(`AR_Userdata_${keyName}`) ?? "") ?? [];
  }
}

export class JournalHandler extends UserContentHandler {
  public keyName: ARContentNameType;
  public usageData: Map<EntityId, JournalEntry>;

  constructor(keyName: ARContentNameType = 'journal_data') {
    super(keyName);
    this.keyName = keyName;
    this.usageData = this.readJournalsFromStorage();
    console.log(`new Handler: ${keyName}`, this.usageData);
  }

  readJournalsFromStorage(): Map<EntityId, JournalEntry> {
    return Userdata.deserializeMap(localStorage.getItem(`AR_Userdata_${this.keyName}`)) ?? new Map();
  }

  storeJournal(journalEntry: JournalEntry) {
    if (this.checkKey(journalEntry.uuid)) {
      this.usageData.set(journalEntry.uuid, journalEntry);
    };
  }

  findJournal({ date, meditationId, uuid }: { date: Date, meditationId: EntityId, uuid?: EntityId }): JournalEntry | null {
    if (uuid) {
      return this.usageData.get(uuid) ?? null;
    }

    return [...this.usageData.values()].find((journalEntry) => {
      // console.log('journalEntry.date', dateFormat(journalEntry.date, 'dateYYYYMMSS'));

      return (
        journalEntry.meditationId === meditationId &&
        journalEntry.deleted !== true &&
        dateformat(journalEntry.date, "dateYYYYMMSS") ===
        dateformat(date, "dateYYYYMMSS")
      );
    }) ?? null;
  }

  getJournalsByKey(keyName: 'uuid' | 'meditationId', keyVal: EntityId): JournalEntry[] {
    if (this.usageData) {
      return [...this.usageData.values()].filter((entry: JournalEntry) =>
        String(entry[keyName]) === String(keyVal)
        &&
        entry.deleted !== Boolean(true)
      );
    }
    return [];
  }

  public toMap(journalEntriesArray: JournalEntry[]): Map<EntityId, JournalEntry> {
    return new Map(journalEntriesArray
      .map(entry => [entry.uuid, entry])
    );
  }

  getJournalsSortedByNewest(): JournalEntry[] {
    return [...this.usageData.values()]
      .sort((journalEntry1, journalEntry2) => {
        return Number(
          journalEntry1.date < journalEntry2.date
        );
      });
  }

  private checkKey(key: EntityId): boolean {
    const uuid: EntityId = key;

    if (typeof uuid !== 'string' || !uuid) {
      console.error('wrong uuid: ', key);
      throw new Error('Invalid key type or null key for storeJournal() !');
    } else {
      return true;
    }
  }
}

export class ChatHandler extends UserContentHandler {
  public keyName: ARContentNameType;
  public data: Map<EntityId, ChatEntry>;

  constructor(keyName: ARContentNameType = 'chat_data') {
    super(keyName);
    this.keyName = keyName;
    this.data = new Map();
    console.log(`new Handler: ${keyName}`, this.data);
  }
}

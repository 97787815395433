import dateFormat from "dateformat";
//@ts-ignore
import { linkToEventRecordingPaneBlock } from "../blocks/event_recording_view_block.js";
import { EventRecording } from "../classes/event_recording.js";

export function createEventRecordingRow({ recording }: { recording: EventRecording }): HTMLElement {
  // console.log('e r', recording)

  const eventRecordingRow: HTMLElement = document.createElement('div');
  eventRecordingRow.classList.add('event_recording_row', 'horizontal');
  eventRecordingRow.dataset['canAccess'] = String(recording.canAccess);
  eventRecordingRow.dataset['postID'] = String(recording.postId);
  eventRecordingRow.dataset['recordingExpired'] = String(recording.access.areExpired);
  eventRecordingRow.dataset['eventHasEnded'] = String(recording.source.eventHasEnded);
  eventRecordingRow.dataset['eventTypeShort'] = String(recording.source.eventTypeShort);

  eventRecordingRow.innerHTML = `
    <img class='featured' src='${import.meta.env.VITE_HOST_URL}/${recording.source.featuredImage.image_url_medium_large}' />
    <div class='title'>${recording.source.title}</div>

    <div class='expiry_date'>
      <div class='label'>Access expires</div>
      <div class='date'>on ${dateFormat(recording.access.expiryDate.date, "mid")}</div>
      <div class='in_x_days hide'>in ${recording.access.daysUntilExpiryCasual}</div>
    </div>

    <div class='progress'>
      ${recording.source.eventTypeShort === 'Course'
      ? "Week " + recording.source.currentWeek + " of " + recording.source.totalWeeks
      : ""
    }</div>
    `;

  if (Boolean(recording.canAccess) === true) {
    eventRecordingRow.addEventListener("click", () => {
      linkToEventRecordingPaneBlock(recording);
    })
  }

  const expiryToggle = eventRecordingRow.querySelector('div.expiry_date');
  expiryToggle?.addEventListener('click', (event) => {
    const dateElement = expiryToggle.querySelector('.date');
    const inXDaysElement = expiryToggle.querySelector('.in_x_days');

    dateElement?.classList.toggle('hide');
    inXDaysElement?.classList.toggle('hide');

    // Stop propagation to prevent parent click handler from firing
    event.stopPropagation();
  });

  return eventRecordingRow;
}

// turn this into a web component instead

function formatDays(days) {
  // Handle zero or negative cases
  if (days <= 0) {
    return "today";
  }

  // If it's one day or less
  if (days <= 1) {
    return "today";
  }

  // If it's less than a week
  if (days < 7) {
    return days === 1 ? "1 day" : `${days} days`;
  }

  // If it's less than 30 days
  if (days < 30) {
    const weeks = Math.floor(days / 7);
    const remainingDays = days % 7;

    // Format weeks part
    const weeksPart = weeks === 1 ? "1 week" : `${weeks} weeks`;

    // If 0 or just 1-2 days remaining, just return weeks
    if (remainingDays <= 2) {
      return weeksPart;
    }

    // Format days part for 3+ remaining days
    const daysPart = remainingDays === 1 ? "1 day" : `${remainingDays} days`;

    return `${weeksPart} and ${daysPart}`;
  }

  // If it's 30 days or more
  const months = Math.round(days / 30);
  return `about ${months} ${months === 1 ? "month" : "months"}`;
}

import * as ar from "./ar_globals";
import * as app from "./ar_appstate";

export const userDevice = () => {
  const userAgentString = navigator.userAgent;

  if (/(iPad|iPhone)/.test(userAgentString)) {
    return "iOS";
  } else if (/Android/.test(userAgentString)) {
    return "Android";
  } else if (/Macintosh/.test(userAgentString)) {
    if (navigator.maxTouchPoints > 0) {
      // iPad Pro identifies itself as Macintosh but has > 0 maxTouchPoints
      return "iOS"
    } else {
      return "macOS";
    }
  } else if (/Windows/.test(userAgentString)) {
    return "Windows";
  } else if (/Linux/.test(userAgentString)) {
    return "Linux";
  }
};

export function getEnvironment() {
  if (ar.domain.host.includes("attachmentrepair.com")) {
    return "Production";
  } else {
    return "Development";
  }
}

// App functions
export function isTouchDevice() {
  return (
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );
}

// Init functions
export function touchLogging() {
  if (app.config.touchLogging) {
    // Debugging Functions
    window.addEventListener("touchstart", (event) => {
      const touchedElement = event.target;
      console.log("Touched:", touchedElement, event.target);
    });
  }
}

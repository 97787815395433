import { icons } from "./icons.ts"
import { arURL } from "../data/ar_globals";
import { settingsBlock, settingsBlockCallback } from "../blocks/settings_block";
import { billingBlock, billingBlockCallback } from "../blocks/billing_block";
import {
  personalBlock,
  personalBlockCallback,
  personalBlockClose,
} from "../blocks/personal_block";
import {
  assessmentsBlock,
  assessmentsBlockCallback,
} from "../blocks/assessments_block";
import { JournalEntry } from "../classes/journal_entry.ts";

/**
  type:
  - 'info_item'
  - 'toggle_item'
  - 'confirm_action'
  - 'single_action'
  - 'sub_menu'
**/

export function meditationMenu(meditation) {
  console.log('menu', meditation);

  const menu = {
    dataset: meditation,
    functions: {},
    menu_items: [
      {
        label: `MeditationId: ${meditation.meditationId}`,
        enabled: true,
        icon: "iconamoon:apps-fill",
        type: "info_item",
      },
      {
        label: "Playcount",
        enabled: true,
        icon: "mingcute:play-fill",
        type: "info_item",
        data: meditation.userStats.playcount ?? 0,
      },
      {
        label: "Last Played",
        enabled: true,
        icon: "mingcute:time-fill",
        type: "info_item",
        data: meditation.userStats.lastPlayed ?? null,
      },
      {
        label: "Downloaded",
        enabled: true,
        icon: "iconamoon:cloud",
        type: "toggle_item",
      },
      {
        label: "Favourite",
        enabled: true,
        icon: icons.fav,
        type: "toggle_item",
        function: () => meditation.toggleFavourite(),
        actionType: "toggle",
        id: meditation.meditationId,
        data:
          window.Userdata.meditationData.usageData.get(meditation.meditationId)
            ?.userStats ?? false,
      },
      {
        label: "Completed",
        enabled: true,
        icon: icons.check,
        type: "toggle_item",
        function: () => meditation.toggleCompleted(),
        actionType: "toggle",
        id: meditation.meditationId,
        data:
          window.Userdata.meditationData.usageData.get(meditation.meditationId)
            ?.userStats.completed ?? false,
      },
      {
        label: "Share Link",
        icon: "iconamoon:share-2-bold",
        type: "toggle_item",
      },
      {
        label: "Journal",
        icon: "ooui:journal-ltr",
      },
      {
        label: "Chats",
      },
    ],
  };
  return menu;
}

export function spcMenu(spc) {
  const menu = {
    dataset: spc,
    functions: {
      addFav: (event, spc) => {
        event.currentTarget.classList.add("on");
        spc.addFavourite();
      },
      removeFav: (event, spc) => {
        event.currentTarget.classList.remove("on");
        spc.removeFavourite();
      },
    },
    menu_items: [
      {
        label: `SPC ID: ${spc.spcId}`,
        enabled: true,
        icon: "iconamoon:apps-fill",
        type: "info_item",
      },
      {
        label: "Favourite",
        enabled: true,
        icon: icons.fav,
        type: "toggle_item",
        function: () => spc.toggleFavourite(),
        actionType: "toggle",
        id: spc.spcId,
        data:
          window.Userdata.spcData.usageData.get(spc.spcId)?.userStats
          ?? false,
      },
      {
        enabled: true,
        label: "Mark Complete",
        icon: icons.check.off,
        type: "toggle_item",
      },
    ],
  };
  return menu;
}

export function deleteMenu(dataset, tippyInstance) {
  const menu = {
    dataset: dataset,
    functions: {
      close: () => {
        if (tippyInstance) {
          tippyInstance.hide();
        }
      },
      delete: (dataset) => {
        new JournalEntry(
          window.Userdata.journalData.usageData.get(dataset.uuid)
        ).deleteJournalEntry();
        menu.functions.close();
      },
    },
    menu_items: [
      {
        enabled: true,
        active: true,
        order: 1,
        label: "Delete",
        icon: icons.bin,
        type: "action",
        size: "normal",
        function: {
          action: (uuid) => menu.functions.delete(uuid),
        },
      },
      {
        enabled: false,
        active: true,
        order: 2,
        label: "Cancel",
        icon: icons.close,
        type: "close_menu",
        function: {
          action: () => menu.functions.close(),
        },
      },
    ],
  };

  return menu;
}

export const profileMenuItems = {
  personal: {
    visible: true,
    enabled: true,
    order: 1,
    label: "Personal",
    icon: icons.profile,
    content: personalBlock,
    type: "nav",
    size: "normal",
    callback: {
      open: personalBlockCallback,
      close: personalBlockClose,
    },
  },
  assessments: {
    visible: true,
    enabled: false,
    order: 2,
    label: "Assessments",
    icon: icons.assessments,
    content: assessmentsBlock,
    type: "nav",
    size: "normal",
    callback: { open: assessmentsBlockCallback },
  },
  membership: {
    visible: true,
    enabled: false,
    order: 3,
    label: "Membership",
    icon: icons.membership,
    content: billingBlock,
    type: "nav",
    size: "normal",
    callback: { open: billingBlockCallback },
  },
  settings: {
    visible: true,
    enabled: false,
    order: 4,
    label: "Settings",
    icon: icons.settings,
    content: settingsBlock,
    type: "nav",
    size: "normal",
    callback: { open: settingsBlockCallback },
  },
};

export const socialMenuItems = {
  newsletter: {
    enabled: false,
    active: true,
    order: 1,
    label: "Newsletter",
    icon: icons.newsletter,
    content: "",
    type: "link",
    size: "small",
    url: `https://attachmentrepair.com/newsletter?utm_source='${arURL.utm_source}'`,
  },
  tiktok: {
    enabled: true,
    active: true,
    order: 2,
    label: "Tiktok",
    icon: icons.tiktok,
    content: "",
    type: "link",
    size: "small",
    url: `https://www.tiktok.com/@attachmentrepair/?utm_source='${arURL.utm_source}'`,
  },
  youtube: {
    enabled: true,
    active: true,
    order: 3,
    label: "Youtube",
    icon: icons.youtube,
    content: "",
    type: "link",
    size: "small",
    url: `https://www.youtube.com/channel/UCcBp5ec4Bz7jvQIsGg9pG3w/?utm_source=${arURL.utm_source}`,
  },
  facebook: {
    enabled: true,
    active: true,
    order: 4,
    label: "Facebook",
    icon: icons.facebook,
    content: "",
    type: "link",
    size: "small",
    url: `https://www.facebook.com/attachmentrepair/?utm_source='${arURL.utm_source}'`,
  },
  reddit: {
    enabled: true,
    active: true,
    order: 5,
    label: "Reddit",
    icon: icons.reddit,
    content: "",
    type: "link",
    size: "small",
    url: `https://www.reddit.com/r/attachment_repair/?utm_source='${arURL.utm_source}'`,
  },
};

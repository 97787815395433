import { registerTheUser } from "../restapi/restapi_register_user";
import { logTheUserIn } from "../restapi/restapi_login_user";
import { icons } from "../ui_general/icons.ts";
import { initialiseTippyOn } from "../ui_general/tippy.ts";

export const registerForm = document.createElement("div");
registerForm.setAttribute("id", "register_box");
registerForm.innerHTML = `
  `;

// Components
// Button for Login
export const registerButton = document.createElement("button-std");
registerButton.setAttribute("id", "register_submit");
registerButton.setAttribute("hue", 210);
registerButton.setAttribute("icon", icons.register);
registerButton.setAttribute("disabled", true);
registerButton.disabled = true;
registerButton.textContent = "Register";

// Input for Email
export const emailForm = document.createElement("input");
emailForm.setAttribute("type", "email");
emailForm.setAttribute("id", "register_email");
emailForm.setAttribute("placeholder", "Email Address");
emailForm.setAttribute("autocomplete", "email");
emailForm.setAttribute("pattern", "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,}");
emailForm.setAttribute("required", true);

// Input for Password
const passwordRequirementMsg = `
<div class='password-requirements-message'>
❌ Password must contain:
  <ul>
    <li>1 uppercase, 1 lowercase</li>
    <li>1 number</li>
    <li>1 special character (!@#$%^&*)</li>
    <li>8 characters minimum</li>
  </ul>
</div>`;

export const passwordForm = document.createElement("input");
passwordForm.classList.add('password-form');
passwordForm.setAttribute("type", "password");
passwordForm.setAttribute("id", "register_password");
passwordForm.setAttribute("placeholder", "Password");
passwordForm.setAttribute("autocomplete", "new-password");
passwordForm.setAttribute("spellcheck", "false");
passwordForm.setAttribute("required", true);
passwordForm.setAttribute("minlength", 8);
passwordForm.setAttribute("pattern", "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#\\$%\\^&\\*]).+$");
passwordForm.setAttribute("title", passwordRequirementMsg);

initialiseTippyOn({
  element: passwordForm,
  content: passwordRequirementMsg,
  allowHTML: true,
  offset: [0, 8],
  theme: "tooltip",
  trigger: "focus",
});

export const registerStatusText = document.createElement("div");
registerStatusText.setAttribute("id", "register_status_text");

const passwordVisibility = document.createElement("div");
passwordVisibility.classList.add("password-visibility");
passwordVisibility.setAttribute('aria-controls', 'register_password');
passwordVisibility.setAttribute('aria-role', 'button');
passwordVisibility.setAttribute('aria-expanded', 'false');
passwordVisibility.innerHTML = `
  <iconify-icon icon="mingcute:eye-close-line" class="password-visibility-icon" noobserver></iconify-icon>
`;

function togglePasswordVisibility() {
  console.log('passwordForm', passwordForm)
  const icon = passwordVisibility.querySelector('.password-visibility-icon');

  if (passwordForm.type === 'password') {
    passwordForm.type = 'text';
    passwordVisibility.setAttribute('aria-expanded', 'true');
    icon.icon = 'mingcute:eye-2-line';
  } else {
    passwordForm.type = 'password';
    passwordVisibility.setAttribute('aria-expanded', 'false');
    icon.icon = 'mingcute:eye-close-line';
  }
}
passwordVisibility.addEventListener("click", togglePasswordVisibility);

export const passwordFormContainer = document.createElement("div");
passwordFormContainer.id = "password-form-container";
passwordFormContainer.appendChild(passwordForm);
passwordFormContainer.appendChild(passwordVisibility)

registerForm.append(
  emailForm,
  passwordFormContainer,
  registerButton,
  registerStatusText,
);

emailForm.addEventListener("input", toggleRegisterButton);
passwordForm.addEventListener("input", toggleRegisterButton);
passwordForm.addEventListener("change", toggleRegisterButton);
passwordForm.addEventListener("blur", toggleRegisterButton);


function toggleRegisterButton() {

  emailForm.classList.toggle('valid', emailForm.validity.valid);
  emailForm.classList.toggle('invalid', !emailForm.validity.valid);

  // If no value, leave it in default state
  if (!emailForm.value) {
    emailForm.classList.remove('invalid');
  }

  passwordForm.classList.toggle('valid', passwordForm.validity.valid);
  passwordForm.classList.toggle('invalid', !passwordForm.validity.valid);

  // If no value, leave it in default state
  if (!passwordForm.value) {
    passwordForm.classList.remove('invalid');
  }

  if (passwordForm.validity.valid) {
    registerStatusText.innerHTML = "";
  }

  if (emailForm.validity.valid && passwordForm.validity.valid) {
    registerButton.removeAttribute("disabled");
    registerStatusText.innerHTML = "";
  } else {
    registerButton.setAttribute("disabled", true);
  }
}

passwordForm.addEventListener("blur", () => {
  if (passwordForm.validity.valid || passwordForm.value.length === 0) {
    registerStatusText.innerhTML = "";
  } else {
    registerStatusText.innerHTML = passwordRequirementMsg;
  }
});

emailForm.addEventListener("blur", () => {
  if (emailForm.validity.valid) {
    registerStatusText.innerText = "";
  } else {
    registerStatusText.innerText = "❌ Invalid Email";
  }
});

registerButton.addEventListener("click", (event) => registrationFlow(event));

export function registrationFlow(event) {

  if (!emailForm.validity.valid || !passwordForm.validity.valid) {
    event.preventDefault();
    return false;
  }

  registerStatusText.innerText = "Registering...";

  // Disable the form
  emailForm.setAttribute("disabled", true);
  passwordForm.setAttribute("disabled", true);
  registerButton.setAttribute("disabled", true);

  // Store it here so we can reuse it to log the user in incase they
  // remove it from the password field before we can access it again.
  const passwordValue = passwordForm.value;

  registerTheUser(emailForm.value, passwordForm.value).then((response) => {
    if (response.status === 200) {
      console.log("registerTheUser() status ok");
      /**

          Registration was successful. We log the user in so it is convenient
          for them going forward.

        **/
      registerStatusText.innerText =
        "✅ Registration successful! Logging you in...";

      logTheUserIn(response.data.email, passwordValue).then((response) => {
        if (response.data.status === 200) {
          // switch to login tab when finished
          // This is handled automatically by setUILoggedIn()
        }
      });
    } else {
      // registration failed
      emailForm.removeAttribute("disabled");
      passwordForm.removeAttribute("disabled");
      registerButton.removeAttribute("disabled");
      registerStatusText.innerText = response.message;
    }
  });
}

export function keyEnterRegister(event) {
  if (event.key == "Enter") {
    registerButton.click();
  }
}

emailForm.addEventListener("keypress", (event) => keyEnterRegister(event));
passwordForm.addEventListener("keypress", (event) => keyEnterRegister(event));

// @ts-ignore
import { getUserData, updateUserData } from "../restapi/restapi_update_user";
import { ARContentNameType, EntityId, profileData } from "../types/global";
import { ChatHandler, JournalHandler } from "../user/user_content_handler";
import { AssessmentUsageHandler, EventRecordingUsageHandler, MeditationUsageHandler, SPCUsageHandler } from "../user/user_usage_handler";
import { JournalEntry } from "./journal_entry";
import { Meditation } from "./meditation";
import { SPC } from "./spc";

class Userdata {

  static instance: Userdata | null = null;

  /**
    Returns the Userdata instance that contains all the information related to the user: meditation plays, SPC completions, profile info, etc.

    Singleton pattern ensures only ever one instance of Userdata
  */
  static getInstance(): Userdata {
    if (!this.instance) {
      this.instance = new this();
    }
    return this.instance;
  }

  static localStorageUserData = [
    "AR_Userdata_Account",
    "AR_Userdata_wpData",
    "AR_Userdata_JWT",
    "AR_Userdata_PersonalFields",
    "AR_Userdata_journal_data",
    "AR_Userdata_Settings_theme",
    "AR_Userdata_Settings_meditation_libraryFilter_duration",
    "AR_Userdata_Settings_meditation_libraryFilter_purpose",
    "AR_Userdata_Settings_meditation_libraryFilter_issue_targeted",
    "AR_Userdata_Settings_meditation_libraryFilter_title",
    "AR_Userdata_Settings_meditation_libraryFilter_schema",
    "AR_Userdata_Settings_meditation_buttonView_current",
    "AR_Userdata_Settings_spc_tab_current",
    "AR_Userdata_Settings_spc_libraryFilter_issue_targeted",
    "AR_Userdata_Settings_courses_buttonView_current",
  ];

  static localStorageCustomData = [
    "AR_Userdata_profile_data",
    "AR_Userdata_meditation_data",
    "AR_Userdata_journal_data",
    "AR_Userdata_spc_data",
    "AR_Userdata_chat_data",
    "AR_Userdata_assessment_data",
  ];

  static UsageHandlers = {
    meditation_data: "meditationData",
    spc_data: "spcData",
    assessment_data: "assessmentData",
    event_recordings_data: "eventRecordingsData",
  };

  static ContentHandlers = {
    journal_data: "journalData",
    chat_data: "chatData",
  };

  public meditationData: MeditationUsageHandler;
  public spcData: SPCUsageHandler;
  public profileData: profileData;
  public journalData: JournalHandler;
  public chatData: ChatHandler;
  public assessmentData: AssessmentUsageHandler;
  public eventRecordingData: EventRecordingUsageHandler;

  public _wpData: object;
  public _jwt: string | null;
  public isLoggedIn: boolean;
  public appState: object;
  public settings: object;
  public customData: object;

  constructor() {
    // The only userdata that exists regardless of whether they are logged in or not.
    this._wpData = {};
    this._jwt = null;
    this.isLoggedIn = false; // true|false
    this.appState = {};

    this.settings = {};
    this.customData = this.getCustomData();

    // User-generated Content

    this.profileData = {
      lastUpdated: undefined,
      nickname: "",
      birthday: "",
      gender: "",
      bio: "",
      psychology: {
        issueTargeted: {
        },
        attachmentStyle: {
        },
        dmmStyle: {
        },
        schemas: {
        },
      },
    };

    // this.profileData = new UserContentHandler('profile_data');
    this.journalData = new JournalHandler("journal_data");
    this.chatData = new ChatHandler("chat_data");

    // User's AR Content Stats
    this.spcData = new SPCUsageHandler("spc_data");
    this.meditationData = new MeditationUsageHandler("meditation_data");
    this.assessmentData = new AssessmentUsageHandler("assessment_data");
    this.eventRecordingData = new EventRecordingUsageHandler("eventrecording_data");

    this.setupEventListeners();
  }

  setupEventListeners() {
    document.addEventListener("loginEvent", () => {
      console.log("🚪✅ loginEvent...");
      this.setupUserInstance();
    });
    document.addEventListener("logoutEvent", () => {
      console.log("🚪❌ logoutEvent...");
      this.clearUserInstance();
      this.clearLSData();
    });
  }

  setupUserInstance(): void {
    // get local data and server data to reconcile and save into the object.

    // Account Management
    this._wpData = this.getWpData();
    this._jwt = this.jwt;

    this.settings = {};
    this.customData = this.getCustomData();

    // Flags
    this.isLoggedIn = true;
    // console.log('👤 Userdata.setupUserInstance()', this);

    this.profileData = this.readLSData('profileData');
  }

  clearUserInstance() {
    this.clearLSData();

    // Clear User-content
    this.journalData = new JournalHandler();
    this.chatData = new ChatHandler();

    this.spcData = new SPCUsageHandler();
    this.meditationData = new MeditationUsageHandler();
    this.EventRecordingData = new EventRecordingUsageHandler();
    this.assessmentData = new AssessmentUsageHandler();

    Userdata.instance = null;
    window.Userdata = Userdata.getInstance();
    console.log("👤 ❌ Userdata.clearUserInstance()");
  }

  // CustomData
  getCustomData(path) {
    if (path) {
      console.log(`userData.getCustomData(${path})`, path);
      return JSON.parse(localStorage.getItem(`AR_Userdata_CustomData_${path}`));
    } else {
      const customData = {};
      Userdata.localStorageCustomData.forEach((fieldName) => {
        const lsData = JSON.parse(
          localStorage.getItem(`AR_Userdata_CustomData_${fieldName}`),
        );
        customData[fieldName] = lsData;
      });
      return customData;
    }
  }

  setCustomData(path, data) {
    const customData = {
      meditationUsage: {
        id: "meditationDataObj",
      },
      journalData: {
        uuid: "journalEntryObj",
      },
      profileData: {
        personal: {
          nickname: null,
          birthday: null,
          gender: null,
          personalBio: null,
        },
      },
      assessmentData: {
        id: "resultObj", // contains user responses and calculated result
      },
      chatData: [
        {},
        {}, // array of chatInstance objects
      ],
      settingsData: {
        appearanceMode: "light",
        notifications: {
          newContent: {
            spc: false,
            meditation: false,
            onlineEvent: false,
          },
          newFeatures: false,
          liveEvents: {
            all: false,
            onlineEvent: [], // list of events they have registered for.
          },
        },
      },
      appState: {
        libraryFilterSettings: {},
        route: {},
      },
    };

    /**

      Functions to define and reconile each of these data,
      between the localCopy and serverCopy.

    **/

    if (path) {
      console.log("set", path);
      this.customData[path] = data;
      console.log("setted", this.customData[path]);
      localStorage.setItem(
        `AR_Userdata_CustomData_${path}`,
        JSON.stringify(data),
      );
    }

    return customData;
  }

  /**

    JWT

  **/
  get jwt() {
    return this._jwt ?? localStorage.getItem("AR_Userdata_JWT");
  }

  set jwt(token: string) {
    this._jwt = token;
    localStorage.setItem("AR_Userdata_JWT", token);

    if (!token) {
      localStorage.removeItem("AR_Userdata_JWT");
    }
  }

  getWpData(key) {
    if (key) {
      return (
        this._wpData[key] ??
        JSON.parse(localStorage.getItem("AR_Userdata_wpData"))?.[key]
      );
    }
    return (
      this._wpData ?? JSON.parse(localStorage.getItem("AR_Userdata_wpData"))
    );
  }

  setWpData(wpAccountFields) {
    this._wpData = wpAccountFields;
    localStorage.setItem("AR_Userdata_wpData", JSON.stringify(this._wpData));
  }

  /**

    Methods to Access Data

  **/

  profile(key) {
    return this.customData.profileData.personal[key];
  }

  /**
    Settings
  **/
  getSettings(path) {
    // console.log('getSettings() path:', `AR_Userdata_Settings_${path}`)
    return (
      this.settings[path] ??
      JSON.parse(localStorage.getItem(`AR_Userdata_Settings_${path}`))
    );
  }

  setSettings(path, data) {
    this.settings[path] = data;
    localStorage.setItem(`AR_Userdata_Settings_${path}`, JSON.stringify(data));

    if (!data) {
      localStorage.removeItem(`AR_Userdata_Settings_${path}`);
    }
  }

  /**

    General Methods

  **/

  readLSData(keyName: ARContentNameType) {
    if (keyName) {
      return JSON.parse(localStorage.getItem(`AR_Userdata_${keyName}`));
    } else {
      Userdata.localStorageUserData.forEach(keyName => {
        console.log('readLSData', keyName);
      })
    }
  }

  static saveToLS(keyName: ARContentNameType | null = null, data: any) {
    console.log('saveToLS...', data);

    /**
      Save one or multiple types
    **/
    if (keyName && data) {
      console.log('saveToLS(),..:', keyName, data);
      localStorage.setItem(`AR_Userdata_${keyName}`, JSON.stringify(data));
      return;
    }
  }

  clearLSData(key: string | null = null) {
    if (key) {
      localStorage.removeItem(key);
    } else {
      // Remove all keys
      Userdata.localStorageUserData.forEach((fieldName) => {
        localStorage.removeItem(fieldName);
      });
      Userdata.localStorageCustomData.forEach((fieldName) => {
        localStorage.removeItem(fieldName);
      });

      // todo: Leaving this here until we setup proper way to handle profileData
      localStorage.removeItem("AR_Userdata_CustomData_profileData");
    }
  }

  async saveToServer(keyName: ARContentNameType) {
    /**

      This function would be fine to use with a service-worker
      or when the user leaves the app.

      [] Need to implement some kind of debouncing or rate limiting. Can a simple time counter function to limit to at least 10 seconds or something.
      And we'd need to assemble the types of requests and sum them up.

    **/

    await this.reconcileUserData(keyName).then((mergedData) => {
      console.log("saveToServer() ready to send...", keyName, mergedData);

      /**
        UsageHandlers
      **/
      // if (Userdata.UsageHandlers[keyName]) {
      mergedData = Userdata.serializeMap(mergedData);
      // }

      switch (keyName) {
        /**
          ContentHandlers
        **/
        case "journal_data":
          break;
        case "profile_data":
          break;
        case "chat_data":
          break;
        default:
          break;
      }

      console.log("saveToServer() presend:", mergedData);

      if (mergedData) {
        updateUserData({ [keyName]: mergedData });
        // Userdata.saveToLS(keyName, mergedData);
      }
    });
  }

  async reconcileUserData(keyName: string) {
    console.log("reconcileUserData() keyName:", keyName);

    /**
      localData needs to know if its serialized Map or an Array.... based on key name.
    **/

    // let localData = JSON.parse(localStorage.getItem(`AR_Userdata_${keyName}`)) ?? null;

    let localData;
    switch (keyName) {
      /**
        Usage Handlers...
      **/
      case "meditation_data":
        localData = this.meditationData.usageData;
        break;
      case "spc_data":
        localData = this.spcData.usageData;
        break;
      case "assessment_data":
        localData = this.assessmentData.usageData;
        break;

      /**
        Content Handlers...
      **/
      case "journal_data":
        localData = this.journalData.usageData;
        break;
      case "profile_data":
        localData = this.profileData.data;
        break;
      case "chat_data":
        localData = this.chatData.data;
        break;
      default:
        throw new Error("reconcileUserData(): mergedData wrong keyName...");
    }

    // console.log('reconcileUserData() localData', localData);

    return getUserData([keyName]).then((response) => {
      const serverData = response.data;

      // console.log('getUserData() response:', response);
      // console.log('localData', localData);
      // console.log('serverData', keyName, serverData[keyName]);

      if (serverData[keyName] && response.status === 200) {
        console.log('return merged data...');
        let mergedData = this.mergeLocalAndServerData(
          localData,
          serverData,
          keyName,
        );

        if (!mergedData) return localData;

        /**
          Here mergedData is sometimes:
          - empty string '{}'
          - null
          - Map
        **/

        // console.log('time to merge', keyName, mergedData);

        if (Userdata.UsageHandlers[keyName] || Userdata.ContentHandlers[keyName]) {

          if (!mergedData && (mergedData === null || mergedData === undefined || mergedData === "{}")) {
            mergedData = Userdata.deserializeMap(mergedData);
          }

          // console.log('merged proper:', mergedData)
          if (Userdata.UsageHandlers[keyName]) {

            this[Userdata.UsageHandlers[keyName]].usageData = mergedData;

          } else if (Userdata.ContentHandlers[keyName]) {

            if (keyName === 'journal_data') {
              const journalEntries = mergedData as Map<string, JournalEntry>;
              journalEntries.forEach((journalEntry: JournalEntry) => {
                this.journalData.storeJournal(journalEntry);
              });
            }
          }

          return mergedData;
        } else {
          // Serialize before storing
          switch (keyName) {
            /**
              ContentHandlers
            **/
            case "profile_data":
              this.profileData.data = mergedData;
              break;
            case "chat_data":
              this.chatData.data = mergedData;
              break;
            default:
              break;
          }

          // console.log('serialized and merged:', mergedData);

          // Save as the new canonical local source
          Userdata.saveToLS(keyName, mergedData);
          // localStorage.setItem(`AR_Userdata_${keyName}`, mergedData);

          return mergedData;
        }
      } else {
        // console.log('return local data...');
        return localData;
      }
    });
  }

  mergeLocalAndServerData(localCopy, serverCopy, keyName: ARContentNameType) {
    // merge then compare timestamps for dupes, keep newest

    // console.log('mergeLocalAndServerData()')
    // console.log('localCopy', localCopy);
    // console.log('serverCopy', serverCopy[keyName]);

    // Start with localCopy
    if (keyName === 'profileData') {
      //
    } else if (Array.isArray(localCopy)) {
      // console.log('USER-CONTENT')
      return this.mergeUserContentHandler(localCopy, serverCopy, keyName);


    } else if (localCopy instanceof Map) {
      // console.log('USER-USAGE')
      return this.mergeUserUsageHandler(
        localCopy,
        serverCopy[keyName],
        keyName,
      );
    }
  }

  mergeUserContentHandler(localCopy, serverCopy, keyName: ARContentNameType) {
    const merged = [...localCopy];
    if (serverCopy.hasOwnProperty([keyName])) {
      // console.log("mergeUserContentHandler()", serverCopy[keyName]);

      /**
        It might not need parsing... how to check. If its a string.
      */
      let deserializedServerCopy = serverCopy[keyName];
      if (typeof deserializedServerCopy === "string") {
        deserializedServerCopy = JSON.parse(serverCopy[keyName]);
        console.log(
          "mergeUserContentHandler() deserialized",
          deserializedServerCopy,
        );
      }

      deserializedServerCopy.forEach((serverItem) => {
        const uuid = merged.findIndex(
          (localItem) => localItem.uuid === serverItem.uuid,
        );

        if (uuid < 0) {
          merged.push(serverItem);
        } else {
          // Keep it if its a record of a deleted uuid
          if (serverItem.deleted === true) {
            merged[uuid] = serverItem;
          }

          // Keep whichever is newer
          if (new Date(serverItem.date) > new Date(merged[uuid].date)) {
            merged[uuid] = serverItem;
          }
        }
      });

      console.log("mergeUserContentHandler() merged", merged);

      // const filtered = Object.values(merged).reduce((acc, obj) => {
      //   const current = acc[obj.uuid];
      //   if (!current || obj.date > current.date) {
      //     return acc;
      //   }
      // }, {});
      // console.log('filtered', filtered);
    }

    return merged;
  }

  mergeUserUsageHandler(localCopy, serverCopy, keyName: ARContentNameType) {
    console.log("mergeUserUsageHandler()");
    // console.log('--localCopy', localCopy);
    // console.log('--serverCopy', serverCopy);

    /**
      localCopy: Should always be Map now

      serverCopy: ?

      the serverCopy will be a serializeMap. So here is where we deserialize it into our Map.

      [] then we need to update this function to make sure it works with maps as inputs

      since the serialize methods are instance methods... we can't access them here unless we initialise a new handler. or, i guess we can call the

    **/

    /**
      todo: make this typesafe and work for all our content types
    */
    if (serverCopy.__type === "Map") {
      serverCopy = new Map(serverCopy.data);
    } else {
      serverCopy = Userdata.deserializeMap(serverCopy);
    }

    // console.log('--deserialized serverCopy', serverCopy);
    const mergedCopy = new Map(serverCopy);
    // console.log('--mergedCopy', mergedCopy);

    localCopy.forEach((localItem: JournalEntry | SPC | Meditation, mapKey: EntityId) => {
      const serverItem = mergedCopy.get(mapKey);

      if (
        !serverItem ||
        new Date(localItem.userStats.timestamp) >
        new Date(serverItem.userStats.timestamp)
      ) {

        mergedCopy.set(String(mapKey), localItem);
      }
    });

    return mergedCopy;
  }

  /**

    Data methods

  */
  static serializeMap(map: Map<EntityId, any>): object {
    if (map === null) {
      console.log("null, return blank Map...");
      return {
        __type: "Map",
        data: [],
      };
    }

    if (!(map instanceof Map)) {
      throw new Error("Input is not a Map");
    }

    // console.log("Map entries before serialization:", map);
    const entriesArray = Array.from(map.entries());

    return {
      __type: "Map",
      data: entriesArray,
    };
  }

  static deserializeMap(serializedMap: string | Map<EntityId, any> | null): Map<EntityId, any> | null {
    // console.log('deserializeMap', typeof serializedMap, serializedMap);

    if (serializedMap === null) {
      return null;
    }

    if (serializedMap instanceof Map) {
      return serializedMap;
    }

    if (!serializedMap || serializedMap === "{}") {
      return new Map();
    }

    // Parse the JSON string into an object
    let parsedObj;
    try {
      parsedObj = JSON.parse(serializedMap);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return null;
    }

    // Check if the parsed object is a serialized Map
    if (
      parsedObj &&
      parsedObj.__type === "Map" &&
      Array.isArray(parsedObj.data)
    ) {
      return new Map(parsedObj.data);
    } else {
      console.error("deserializeMap(): ")
    }

    return null
  }
}

export default Userdata;

/**
  Define the Userdata singleton. We put it on window to make it explicit that it's global. From anywhere in the app we want to be able to read and write to the Userdata object. It contains all the data about the user's activity: meditations they listen to, SPC progress, their profile, etc.

  As it is a singleton instance, it only contains the data, not methods. Methods are on the window.Usermethods instead.
*/
window.Userdata = Userdata.getInstance();
window.Usermethods = Userdata;

import $ from "jquery";
import * as ar from "../data/ar_globals.js";
import * as db from "../database/indexdb.js";
import { authHeader } from "../restapi/restapi_login_user.js";
import { apiURL } from "../restapi/restapi_requests.js";
import * as ui from "../ui_general/create_menu";
import * as tippy from "../ui_general/tippy.ts";
import * as chars from "../chat/ar-chat-characters.js";
import * as sync from "../chat/ar-chat-sync.js";
import { icons } from "../ui_general/icons.ts";

import {
  formatDate,
  formatMessage,
  timeStampNow,
  formatDisplay,
} from "../ui_general/ar_utils.ts";

const chatList = document.querySelector("div#chat-list");

const charIcons = {
  PNR: "Perfect Nurturer",
};

// Chat instance builder
export class ChatEntry {
  static modelTokens = {
    "gpt-4": 8192,
    "gpt-3.5-turbo-1106": 16385,
    "gpt-3.5-turbo": 4096,
  };

  constructor(
    {
      chatName,
      chatID,
      message_history,
      character,
      chatCreatedTimestamp,
      chatModifiedTimestamp,
    },
    isNew = true,
  ) {
    // console.log('chatBuilder: ' , {chatName, chatID, message_history, character, chatCreatedTimestamp, chatModifiedTimestamp});

    this.chatID = chatID || "AR_chat_" + Math.floor(Math.random() * 100000000);
    this.chatName = chatName || "New Chat"; //this.chatID || chatID;
    this.messageHistory = message_history || [];
    this.character = character;
    this.chatCreatedTimestamp = chatCreatedTimestamp || timeStampNow();
    this.chatModifiedTimestamp = chatModifiedTimestamp || undefined;
    this.deleted = false;

    this.chatBotSection = $("#chatbot");
    this.chatListContainer = $("#chat-list-container");
    this.chatList = $("#chat-list");
    this.mainChatArea = $("#main-chat-area");

    // temporarily block since the URLs are wrong.

    this.thisChat = `div.chat-view[data-chat-id='${this.chatID}']`;
    this.createChat(isNew);

    this.storeSafeAreaInsetBottom = getComputedStyle(
      document.documentElement,
    ).getPropertyValue("--safe-area-bottom");
  }

  getChatData() {
    return {
      chatInstanceData: {
        chatName: this.chatName,
        chatID: this.chatID,
        character: this.character,
        message_history: this.messageHistory,
        chatCreatedTimestamp: this.chatCreatedTimestamp,
        chatModifiedTimestamp: this.chatModifiedTimestamp,
        deleted: this.deleted,
      },
    };
  }

  createChat(isNew) {
    // console.log('.createChat(): ', this.getChatData());
    this.createSidebarItem();
    this.createChatView();

    if (isNew) {
      db.saveToIndexedDB({
        storeName: "chatInstanceData",
        actionType: "create",
        data: this.getChatData().chatInstanceData,
      })
        .then((result) => {
          // console.log(result)
        })
        .catch((error) => console.error(error));

      sync.syncChatsToServer();
    }
  }

  deleteChat() {
    // console.log("Delete chat");
    this.deleted = true;
    this.$sidebarItem.remove();
    $(`div.chat-view[data-chat-id='${this.chatID}']`).remove();

    db.saveToIndexedDB({
      storeName: "chatInstanceData",
      actionType: "delete",
      data: this.getChatData().chatInstanceData,
    })
      .then((result) => {
        // console.log(result)
      })
      .catch((error) => console.error(error));

    sync.syncChatsToServer();

    // delete the chat object instance too?
  }

  createSidebarItem() {
    // deselect all
    // console.log('create sidebar chatlist: ', chatList)
    this.chatList.find(`div.chat_sidebar`).removeClass("active");
    let formattedDate = formatDate(
      this.chatModifiedTimestamp || this.chatCreatedTimestamp,
    );

    // create item

    /*
          The date format should truncate to just timestamp if it's today,
          and to just the name of the date if it's in the last 7 days,
          and to DD M if its prior to that, and DD.MM.YY if it's prior to that
          */
    this.$sidebarItem = $(`
                        <div class='chat_sidebar active' data-chat-id='${this.chatID}'>
                            <div class='icon'>
                                <img src='' alt='New Chat' />
                            </div>
                            <div class='chat_name'>${this.chatName}</div>
                            <div class='chat_date'>${formattedDate}</div>
                        </div>
                    `);

    this.sidebarItem = `div.chat_sidebar[data-chat-id='${this.chatID}']`;

    this.$closeButton = $(`
                <div class='close_x'>
                  <iconify-icon noobserver icon='${icons.close}'></iconify-icon>
                </div>`);
    this.$sidebarItem.append(this.$closeButton);

    this.chatList.append(this.$sidebarItem);

    // behaviours
    this.$closeButton.on("click", () => this.deleteChat());
    this.$sidebarItem.on("click", () => this.selectChat());
  }

  selectChat() {
    // Sidebar Item
    this.chatList.find(`div.chat_sidebar`).removeClass("active");
    this.$sidebarItem.addClass("active");

    // Chat Area
    this.mainChatArea
      .find(`div.chat-view`)
      .addClass("hidden")
      .removeClass("active");

    this.mainChatArea
      .find(`div[data-chat-id='${this.chatID}']`)
      .removeClass("hidden")
      .addClass("active");

    // UI State
    this.chatListContainer.addClass("out");
    this.mainChatArea.addClass("in");
    this.mainChatArea.removeClass("out");

    // Scroll to bottom (todo: abstract this into a single method)
    // desktop
    this.$chatArea.get(0).scrollTop = this.$chatArea.get(0).scrollHeight;
    // mobile
    this.$messagesArea.get(0).scrollTop =
      this.$messagesArea.get(0).scrollHeight;

    // Focus input
    this.mainChatArea.on("transitionend", (event) => {
      // console.log("selectChat()");
      // this.$messageBox.focus();
      this.mainChatArea.off("transitionend"); // only on transition in, not out
    });

    return this; // allows for calling the method on the constructor
  }

  createCharacterSelector() {
    this.$selectCharacter = $(`
                        <div class='character-selector'>
                            <div class='header'>Characters</div>
                            </div>
                        </div>
                    `);

    $.each(chars.ARcharacters, (key, character) => {
      const $characterContainer = $(`<div class='character'>`);
      const $characterName = $(`<div class='name'>${character.name}</div>`);
      const $characterIcon = $(
        `<div class='icon'><img src='' alt='Profile icon' /></div>`,
      );
      const $characterDescription = $(
        `<div class='description'>${character.description}</div>`,
      );
      const $characterDescLong = $(
        `<div class='description_long'>${character.descriptionLong}</div>`,
      );

      $characterContainer.append($characterName);
      $characterContainer.append($characterIcon);
      $characterContainer.append($characterDescription);
      $characterContainer.append($characterDescLong);
      $characterContainer.attr("data-character-id", character.ID);

      // [] append to top of div.chat-area.

      $characterContainer.on("click", (event) => {
        const thisCharacter = `div.character[data-character-id='${character.ID}']`;
        this.character = character;

        this.startChat();

        // Print conversation Starters
        if (this.conversationStarters) {
          const convStartersHTML = this.conversationStarters.map((starter) => {
            const convo_starter_html = $(`
                            <div class='convo_starter'>
                              <iconify-icon noobserver icon="${icons.chatBubble}"></iconify-icon>
                              ${starter}
                            </div>
                        `);

            convo_starter_html.on("click", (event) => {
              this.sendForm(event, convo_starter_html.text());
              $(".convo_starter_container").addClass("hidden");
              $(".convo_starter").off(); // disable event listener
            });

            return convo_starter_html;
          });

          const convStarterContainer = $(
            `<div class='convo_starter_container'></div>`,
          );
          convStarterContainer.append(convStartersHTML);

          $(this.$chatArea).append(convStarterContainer);
        }

        // Don't allow re-click
        $(`${this.thisChat} ${thisCharacter}`).off("click");
      });

      this.$selectCharacter.append($characterContainer);
    });
  }

  startChat() {
    // console.log('startChat() chatID: ', this.chatID)
    const thisCharacter = `div.character[data-character-id='${this.character.ID}']`;

    this.sendMessage("assistant", this.character.welcomeMessage);

    // Set the chat character for PHP to know which pre-prompt to use
    this.characterID = this.character.ID;
    (this.characterIcon = this.character.icon),
      (this.conversationStarters = this.character.conversationStarters);

    // Select the chosen Character in the list
    $(`${this.thisChat} ${thisCharacter}`).addClass("selected");

    // Delete other character options
    $(`${this.thisChat}`).find(`div.header`).remove();
    $(`${this.thisChat}`)
      .find(`div.character:not([data-character-id='${this.character.ID}'])`)
      .remove();

    // Update sidebar chat item with Label and Icon
    $(`div.chat_sidebar[data-chat-id='${this.chatID}']`)
      // Assign character
      .attr("data-character-id", this.characterID)
      // Label
      .append(
        `<div class='character_pill' data-character-id='${this.character.ID}'>${this.character.name}</div>`,
      );
    // Icon
    // .find('div.icon img').attr('src', this.character.icon);

    // Update chat-view with character-id
    $(`${this.thisChat}`).attr("data-character-id", this.characterID);

    // Add classes to indicate the conversation has started
    $(`${this.thisChat}`).addClass("chat_started");

    // Unhide message <form>
    $(`${this.thisChat}`).find(`form.chat-form`).removeClass("hidden");

    // Activate input
    this.enableForm(this.chatID);
    // this.$messageBox.focus();
  }

  createChatView() {
    this.$messageBox = $(
      `<textarea
                  id="chat_textarea_${this.chatID}"
                  disabled
                  class="input-user-message"
                  placeholder="Message"
                  rows="1"
                  maxlength="2000"
                  autocomplete="off"
                  autocorrect="on"></textarea>`,
    );

    this.$messageBox.on("input", function keepTextAreaSize(e) {
      const chatBotSection = $("#chatbot");

      // auto adjust size of box
      this.style.height = "auto"; // setting to auto first allows it to shrink
      this.style.height = `${this.scrollHeight}px`; // this allows it to grow

      // scroll to bottom. necessary for newlines after the textarea reaches max-height.
      this.scrollTop = this.scrollHeight;

      // truncate to 2000 chars
      if (this.value.length > 2000) {
        this.value = this.value.substring(0, 2000);
      }

      // Enable or disable the send button based on the presence of text
      if (
        this.value.trim().length > 0 &&
        chatBotSection.attr("data-sending") === "false"
      ) {
        $(this).siblings(".send_message_button_wrap").removeClass("disabled");
      } else {
        $(this).siblings(".send_message_button_wrap").addClass("disabled");
      }
    });

    this.$messageBox.on("keydown", (event) => {
      // Check for touch device
      const isDesktop = !ar.isTouchDevice();

      // Make 'Enter' key trigger sendForm() on Desktop.
      // iOS Enter will create a new line, and the Send button will send.
      if (isDesktop && event.key === "Enter") {
        if (!event.shiftKey && !event.altKey) {
          if (!$(this.$sendButton).hasClass("disabled")) {
            this.sendForm(event);
          }
        }
      }
    });
    this.$messageBox.on("focus", (event) => {
      // console.log("messageBox.on(focus):  ");
      if (this.$form.hasClass("hidden")) {
        // console.log(' --- form is hidden, so abort shrinking the messageBoxPadding');
        return;
      }

      // console.log(" --- ⌨️ Guessing keyboard is active: '.keyboardActive' set");
      document.documentElement.classList.add("keyboardActive");

      // [] the box can get focused but it seems to immediately unfocus and the keyboard doesn't get called?

      const keyboardOn = getComputedStyle(
        document.documentElement,
      ).getPropertyValue("--bottom-keyboard-margin-off");

      setTimeout(() => {
        // console.log(" --- timeout code run: shrink the messagearea bottom margin");

        // // shrink the margin below text input
        document.documentElement.style.setProperty(
          "--safe-area-bottom",
          keyboardOn,
        );
      }, 250);
    });

    this.$messageBox.on("touchstart", (event) => {
      // Store the start coordinates
      window.startX = event.touches[0].clientX;
      window.startY = event.touches[0].clientY;
    });

    this.$messageBox.on("touchend", (event) => {
      // console.log("messageBox.on touchend: Guess if keyboard is still active?");

      const messageBoxForm = document.querySelector(
        `div.chat-view[data-chat-ID='${this.chatID}'] form.chat-form`,
      );

      // Get the last touch coordinates
      const endX = event.changedTouches[0].clientX;
      const endY = event.changedTouches[0].clientY;

      // Determine the element at the end position
      const targetElement = document.elementFromPoint(endX, endY);

      // console.log(" --- endX: ", endX, " | endY: ", endY);
      // console.log(" --- targetElement: ", targetElement);

      if (targetElement.classList.contains("input-user-message")) {
        // You can now compare this with the originating element or perform other actions
        // console.log(" --- --- Ended touch on element:", targetElement);
        // console.log(" --- --- ⌨️ Guessing keyboard is active: '.keyboardActive' set");
        document.documentElement.classList.add("keyboardActive");

        const keyboardOn = getComputedStyle(
          document.documentElement,
        ).getPropertyValue("--bottom-keyboard-margin-off");

        setTimeout(() => {
          // console.log("timeout code run: shrink the messagearea bottom margin");

          // shrink the margin below text input
          document.documentElement.style.setProperty(
            "--safe-area-bottom",
            keyboardOn,
          );

          // shrink the viewport
          // document.documentElement.style.setProperty(
          //   "--viewport-shrink-amount",
          //   "336px",
          // );

          // setTimeout(() => {
          //   // scroll <html> element to top to compensate for
          //   // shrunken viewport
          //   this.$messagesArea.scrollTop(
          //     this.$messagesArea.prop("scrollHeight"),
          //   );
          // }, 100);
        }, 10);
      }

      messageBoxForm.addEventListener(
        "touchmove",
        (event) => {
          // due to Safari viewport bug, we can use this to prevent the user
          // from touch-dragging the <form> element and inadvertently scrolling the viewport
          event.preventDefault();
        },
        false,
      );
    });

    this.$messageBox.on("blur", (event) => {
      // console.log("messageBox.on blur: focus out: reset the bottom messagearea margin");

      if (event.target.parentNode === this.$messageBox.parent().get(0)) {
        // console.log(" --- sibling clicked! cancel blur event");
        event.preventDefault();
      }

      document.documentElement.classList.remove("keyboardActive");
      // console.log(
      //   "--- ❌ ⌨️ Guessing keyboard is inactive: '.keyboardActive' removed",
      // );

      const keyboardOff = getComputedStyle(
        document.documentElement,
      ).getPropertyValue("--bottom-keyboard-margin-on");

      // restore margin under textarea
      document.documentElement.style.setProperty(
        "--safe-area-bottom",
        keyboardOff,
      );

      // restore the viewport
      document.documentElement.style.setProperty(
        "--viewport-shrink-amount",
        "0px",
      );

      // this.$messageBox.focus();
    });

    // Create action button

    // Action Button
    // const actionMenu = `
    //    <div class='menu'>
    //        <div class='menu_item'>Size:
    //            <button type='button'>-</button><button type='button'>+</button>
    //        </div>
    //        <div class='menu_item'>Summarise Chat</div>
    //        <div class='menu_item menu_parent'>
    //            <div class='label' onclick='this.parentElement.classList.toggle("open");'>Export Chat</div>
    //            <div class='menu_children'>
    //                <div class='menu_item child'>PDF</div>
    //                <div class='menu_item child'>Text</div>
    //            </div>
    //        </div>
    //    </div>
    //    `;
    const actionMenuObj = new ui.CreateMenu(
      {
        menu_items: [
          {
            label: "Summarise Chat",
            type: "menu_item",
            icon: "iconamoon:box-bold",
          },
          {
            label: "Export Chat",
            type: "menu_item",
            children: [
              {
                label: "PDF",
                type: "menu_item",
                icon: "",
              },
              {
                label: "Text",
                type: "menu_item",
                icon: "",
              },
            ],
            icon: "iconamoon:arrow-top-right-3-square",
          },
          {
            label: "Rename Chat",
            type: "menu_item",
            icon: "iconamoon:cheque-bold",
          },
          {
            label: "Delete Chat",
            type: "menu_item",
            icon: "iconamoon:trash-duotone",
          },
        ],
      },
      {
        selector: `div.chat-view[data-chat-id="${this.chatID}"] form.chat-form button.action-menu`,
        theme: "menu",
        placement: "top",
        allowHTML: true,
        offset: [10, 20],
      },
    );

    this.$actionIcon = $(
      `<iconify-icon noobserver icon='${icons.lightning}' class="action"></iconify-icon>`,
    );
    this.$actionInput = $(
      `<button class='action-menu' type='button' aria-expanded='true' data-tippy-content=''>Action</button>`,
    );
    this.$actionButton = $(
      `<div class='action_menu_button_wrap'></div>`,
    ).append(this.$actionInput, this.$actionIcon);

    this.$actionButton.on("click", (e) => {
      // e.preventDefault(); // type='button' makes this redundant, but it's a good habit to have.
      // console.log("⚡ action button ");
      // tippy.js gets triggered here.
      // has to be initialised later in the code, after this $actionButton is appended to the DOM.
    });

    // Send Button
    this.$sendInput = $(
      `<input type="submit" name="submit" value="Send" class="submit-message" />`,
    );
    this.$sendIcon = $(
      `<iconify-icon noobserver icon="${icons.sendMessage}" class="send"></iconify-icon>`,
    );
    this.$sendButton = $(
      `<div class='send_message_button_wrap disabled'></div>`,
    ).append(this.$sendInput, this.$sendIcon);

    // Form Children

    // Prevent the virtual keyboard from disappearing when
    // sibling elements in the form (actionButton and sendButton) are clicked
    const formChildren = [this.$actionButton, this.$sendButton];

    formChildren.forEach((element) => {
      element.on("mousedown", (event) => {
        event.preventDefault();
      });
    });

    const newLocal = this;
    newLocal.$extraControls = $(`
                        <div class='extra_controls'>
                            <div class='feedback'>Feedback</div>
                            <div class='feedback'>Export Chat</div>
                            <div class='feedback'>Character Settings</div>
                        </div>`);

    this.$tokenProgress = $(`
                        <div class='token_progress'>
                            <div class='label'>Chat Memory</div>
                            <div class='bar'></div>
                        </div>
                        `);

    this.$form = $(`<form class="chat-form hidden"></form>`);
    this.$form.append(this.$actionButton);
    this.$form.append(this.$messageBox);
    this.$form.append(this.$sendButton);
    // this.$form.append(this.$sendIcon);
    this.$form.append(this.$tokenProgress);
    this.$form.append(this.$extraControls);
    this.$form.on("submit", (event) => {
      event.preventDefault();
      this.sendForm(event);
    });

    this.$chatArea = $(`<div class='chat-area'></div>`);
    this.$messagesArea = $(`<div class='messages-area'></div>`);
    this.$convoIdeas = $(`<div class="convo_ideas">
                            <button>Ideas</button>
                            <button>Directions</button>
                            <button>Summarise Chat</button>
                            <button>Mode</button>
                            <button>Meditation</button>
                        </div>`);

    this.$chatContainer = $(`
                        <div class='chat-view active' data-chat-id='${this.chatID}'></div>
                    `);

    this.$chatArea.append(this.$messagesArea);
    this.$chatArea.append(this.$convoIdeas);
    this.$chatContainer.append(this.$chatArea);
    this.$chatContainer.append(this.$form);

    this.createCharacterSelector();
    this.$chatArea.prepend(this.$selectCharacter);

    this.mainChatArea
      .children()
      .not(".back_button")
      .addClass("hidden")
      .removeClass("active");
    this.mainChatArea.append(this.$chatContainer);

    // Scroll chat area
    let observer = new MutationObserver(() => {
      // console.log('new msg')
      // desktop
      this.$chatArea.get(0).scrollTop = this.$chatArea.get(0).scrollHeight;

      // mobile
      this.$messagesArea.get(0).scrollTop =
        this.$messagesArea.get(0).scrollHeight;

      // todo: modify desktop chatArea CSS height so that it's the same as the mobile one and we can remove it's separate code.
    });
    observer.observe(this.$messagesArea.get(0), { childList: true });

    // Populate a pre-existing chat
    if (this.messageHistory.length !== 0) {
      this.startChat();
      this.messageHistory.forEach((message) =>
        this.sendMessage(message.role, message.content),
      );
    }

    // Initialise Tippy on the Action Menu
    tippy.initialiseTippyOn({
      selector: `div.chat-view[data-chat-id="${this.chatID}"] form.chat-form div.action_menu_button_wrap`,
      theme: "menu",
      placement: "top",
      content: actionMenuObj.menu,
      allowHTML: true,
      offset: [10, 20],
      selectAll: true,
    });
  }

  disableForm() {
    // console.log('disabling form...')
    this.$sendButton.addClass("disabled"); //attr('disabled', true);
  }

  enableForm(chatID) {
    $(`div.chat-view[data-chat-id='${chatID}'] textarea`).attr(
      "disabled",
      false,
    );
  }

  sendForm(event, message = undefined, convStarter = null) {
    // Main chat submission
    event.preventDefault();

    // Abort if sendButton is disabled. We do this instead of just disabling sendButton
    // with CSS 'pointer-event' property so that it can still receive touch-events
    // that are necessary to prevent the virtual keyboard from losing focus when the disabled
    // sendButton is touched.
    // console.log("disabled? ", this.$sendButton.prop("disabled"));
    if (this.$sendButton.hasClass("disabled")) {
      // console.log("sendButton is disabled, cancelling sendForm()");
      return;
    }

    // message
    message = message ? message.trim() : this.$messageBox.val().trim();
    // console.log("message: ", message);

    const userMessage = {
      role: "user",
      content: message,
    };

    // global flag on chatBot to indicate a message is being sent.
    // other functions can check this to allow/block activity.
    this.chatBotSection.attr("data-sending", true);

    // update message history with user's message
    // AI's message gets updated by the server
    this.messageHistory.push(userMessage);

    // populate chat area with user's message
    this.sendMessage("user", message);
    this.sendMessage("assistant", "", "loading");

    // input
    this.$messageBox.val(""); // Clear the input after sending
    this.$messageBox.css("height", "");

    // form
    this.disableForm();
    this.scrollSendButtonIntoView();

    this.sendMessageToServer(message);

    // Refocus textarea if keyboard is active at time of send.
    // Should always happen on desktop
    if (
      !ar.isTouchDevice ||
      document.documentElement.classList.contains("keyboardActive")
    ) {
      this.$messageBox.focus();
    }
  }

  sendMessageToServer(message) {
    // Prepare the data to send
    const data = this.getChatData();
    data.message = message;

    // Use fetch to send the POST request
    fetch(apiURL("ar", "chatbot"), {
      method: "POST",
      // credentials: "same-origin",
      headers: {
        Authorization: authHeader(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log('Success:', response);
        if (response.success) {
          // Handle the successful response
          const assistantMessage = {
            role: "assistant",
            content: response.data.message,
          };

          this.messageHistory.push(assistantMessage);

          // Set modified timestamp
          this.chatModifiedTimestamp = timeStampNow();
          $(`${this.sidebarItem} div.chat_date`).text(
            formatDisplay(this.chatModifiedTimestamp),
          );

          // Get summarised chat name
          if (response.data.chatNameSummarised) {
            this.chatName = response.data.chatNameSummarised;
            // console.log("ChatNameSummarised: ", this.chatName);
            $(`${this.sidebarItem} div.chat_name`).text(this.chatName);
          }

          db.saveToIndexedDB({
            storeName: "chatInstanceData",
            actionType: "update",
            data: [this.getChatData().chatInstanceData],
          })
            .then((result) => {
              // console.log(result)
            })
            .catch((error) => console.error(error));

          sync.syncChatsToServer();

          // print bot's response into chat.
          this.sendMessage("assistant", response.data.message);

          this.updateTokenBar(
            response.data.usage,
            response.data.model,
            response.data.pre_prompt_chars,
          );
        } else {
          this.sendMessage("assistant", response.data, "error");
        }
        this.scrollSendButtonIntoView();

        // new user message was composed and is ready to send...
        if (this.$messageBox[0].value.length > 0) {
          this.$sendButton.removeClass("disabled");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        this.sendMessage(
          "assistant",
          "Could not process your message. Please try again.",
          "error",
        );
      })
      .finally(() => {
        // Perform cleanup actions like removing loading indicators
        this.$chatArea.find(".loading").remove();
        this.enableForm();
        this.chatBotSection.attr("data-sending", false);
      });
  }

  summariseChatName() {
    // console.log("summarise chat name:");

    // prepend character's welcome message
    const summarisedChat = [...this.messageHistory];
    const welcomeMsg = {
      role: "assistant",
      content: this.character.welcomeMessage,
    };
    summarisedChat.unshift(welcomeMsg);
    // console.log("summarisedChat", summarisedChat);
  }

  updateTokenBar(tokens, model, pre_prompt_chars) {
    // turn tokens into a percentage of total

    // the pre_prompt tokens are included in the usage, but we want to hide this
    // from the user so that the bar only reflects their own perceived usage (otherwise bots with longer pre-prompts show the conversation as having excessive progress bar at the start)
    // - so, we subtract pre_token from both usage and max to normalise the progress bar
    // [] todo: use a proper JS (or better, serverside PHP) tokenizer to count the actual pre_prompt tokens instead of approximating.
    const pre_tokens = pre_prompt_chars / 5;
    const max = ChatEntry.modelTokens[model] - pre_tokens;
    const usage = tokens.total_tokens - pre_tokens;
    const percentage = (usage / max) * 100 + "%";

    $(`${this.thisChat} div.bar`).css("width", percentage);
  }

  sendMessage(role = "assistant", message = "", status) {
    let label;
    switch (status) {
      case "error":
        label = "Error:";
        break;
      case "loading":
        label = "";
        break;
      default:
        label = "";
    }

    status = status ? status : "";
    message = formatMessage(message);
    const retryButton =
      status === "error" ? "<div class='retry'>Retry</div>" : "";

    const messageHTML = `<div class="message ${role} ${status}">${label} ${message} ${retryButton}</div>`;
    this.$messagesArea.append(messageHTML);

    // fade-in effect
    const newMessage = this.$messagesArea.children().last();
    setTimeout(() => newMessage.addClass("fade_in"));
  }

  scrollSendButtonIntoView() {
    // Only fire if button is below viewport
    if (
      this.$sendButton.offset().top - $(window).scrollTop() >
      $(window).height()
    ) {
      // console.log("start scroll");
      $("html, body").animate(
        {
          scrollTop:
            this.$sendButton.offset().top -
            $(window).height() +
            this.$sendButton.outerHeight() +
            70,
        },
        5000,
      );
      // console.log("focus message box");
      // this.$messageBox.focus();
    }
  }

  prepareChats() {
    // Focus the Chatbox
    $(document).on("keydown", function (event) {
      if (event.key === "/" && (event.ctrlKey || event.metaKey)) {
        this.$messageBox.focus();
      }
    });
  }
}

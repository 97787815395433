import { restAPIRequest } from "../restapi/restapi_requests";
import { createSPCRow } from "../ui_components/spc_row.ts";
import { icons } from "../ui_general/icons.ts";
import * as app from "../data/ar_appstate";
import { SPC } from "../classes/spc";
import {
  createFilterButton,
  createLibraryFilter,
  loadRESTFilter
} from "../ui_components/library_filter.js";
import { printTaxonomyPill } from "../taxonomies/print_taxonomy_pill.ts";

export async function spcLibraryInit() {
  // console.log('spcLibraryInit', 'token', app.userState.spcLibraryState, 'first init')

  // Check if we've already synced from server at app-launch to avoid doing it again
  if (app.userState.spcLibraryState && !app.state.spcLibraryInit) {
    await window.Userdata.reconcileUserData("spc_data").then(() => {
      app.state.spcLibraryInit = true;

      uiGetSPCPosts();
      uiUpdateStartedSPCListDOM();
      updateSpcFavsListDOM();

      /**
        Select the saved tab
      */
      const savedTab = localStorage.getItem('AR_Userdata_Settings_spc_tab_current');
      document.querySelector(`div.spc_filter_tab[data-target='${savedTab}']`)?.click();

      document.addEventListener("loginEvent", function (event) {
        uiGetSPCPosts();
        uiUpdateStartedSPCListDOM();
      });

      document.addEventListener("logoutEvent", () => {
        uiGetSPCPosts();
        uiUpdateStartedSPCListDOM();

        // Clear Favs
        const subsectionFavourites = (document.querySelector(
          "#spc_favs div.spc_list",
        ).innerHTML = "");

        // Clear Stared
        const subsectionStarted = (document.querySelector(
          "#spc_started div.spc_list",
        ).innerHTML = "");
      });
    });
  } else {
    uiGetSPCPosts();
  }
}

export const sectionCourses = document.createElement("section");
sectionCourses.setAttribute("id", "courses");
sectionCourses.classList.add("membership", "hidden");
sectionCourses.innerHTML = `
  <div class="container">

    <div class="section_header">
      <div class='header_wrap'>
        <iconify-icon noobserver icon='${icons.postType.courses}'></iconify-icon>
        <div class='label'>Self-Paced Courses</div>
      </div>
    </div>

    <div class="contents">

      <div class="button_view_set">
        <div class='button_view_wrap current' data-name='spc_main'>
          <div class="button_view">
            <iconify-icon noobserver icon='${icons.sortingLeft}'></iconify-icon>
            <div class="label">Courses</div>
          </div>
        </div>
        <div class='button_view_wrap' data-name='spc_started'>
          <div class="button_view">
            <iconify-icon noobserver icon="${icons.clock}"></iconify-icon>
            <div class="label">Started</div>
          </div>
        </div>
        <div class='button_view_wrap' data-name='spc_favs'>
          <div class="button_view">
            <iconify-icon noobserver icon="${icons.fav.off}"></iconify-icon>
            <div class="label">Favourites</div>
          </div>
        </div>
      </div>

      <div id="spc_main" class="block view_set current">

        <div id="spc_filter_selector">
          <div class="spc_filter_tab" data-target='spc_foryou'>For you</div>
          <div class="spc_filter_tab active" data-target='spc_all'>
            All Courses
            <span class='count'></span>
            </div>
        </div>

        <div id="spc_list_for_you_container" class='spc_list_container' data-target='spc_foryou'>
          <div id="foryou_explainer">
            <p>Courses containing <strong>your issues</strong><span class='count'></span></p>
            <div id="foryou_taxonomypills_container" class='taxonomy_container'></div>
          </div>
          <div id="spc_list_for_you" class="spc_list">
          </div>
        </div>

        <div id="spc_list_all_container" class='spc_list_container active' data-target='spc_all'>

          <div id="spc_list_all" class="spc_list">
          </div>

          <div id="spc_list_prev_next_wrap" class='pagination_wrap' style='display: none'>
            <button-arrow direction="left" section="spc"></button-arrow>
            <div class="pageCount">
              <div class="count_widget" id='currentPage'></div>/
              <div class="count_widget" id='totalPages'></div>
            </div>
            <button-arrow direction="right" section="spc"></button-arrow>
          </div>

        </div>

      </div>

      <div id="spc_started" class="block view_set">
        <div class="subheader">Started</div>
        <div class='spc_list' id='spc_started_list'></div>
      </div>

      <div id="spc_favs" class="block view_set">
        <div class="subheader">Favourites</div>
        <div class='spc_list' id="spc_favs_list"></div>
      </div>


    </div>
  </div>
  `;

// sectionCourses
//   .querySelector(`div[data-name=spc_main]`)
//   .appendChild(createFilterButton('spc'));

document.addEventListener("DOMContentLoaded", () => {
  updateSpcFavsListDOM();
  uiUpdateStartedSPCListDOM();
  uiUpdateSPCForYouListDOM();
});

document.addEventListener("loginEvent", function (event) {
  loadRESTFilter('spc');
  uiGetSPCPosts();
  uiUpdateStartedSPCListDOM();
  uiUpdateSPCForYouListDOM();
});

document.addEventListener("logoutEvent", () => {
  uiGetSPCPosts();
});

document.addEventListener("spcFavUpdateEvent", () => {
  updateSpcFavsListDOM();
});

document.addEventListener("spcLoadEvent", async (event) => {
  if (app.userState.spcLibraryState.isLoading) return;

  const { direction } = event.detail;

  app.userState.spcLibraryState.isLoading = true;

  if (direction === "left" && app.userState.spcLibraryState.page > 1) {
    app.userState.spcLibraryState.page -= 1;
  } else if (direction === "right") {
    app.userState.spcLibraryState.page += 1;
  }

  try {
    // Invoke the network request and wait for it to complete
    await uiGetSPCPosts({
      page: app.userState.spcLibraryState.page,
    });
  } catch (error) {
    console.error("Failed to fetch SPC posts:", error);
  } finally {
    console.log("dispatch", app.userState.spcLibraryState);
    const loadCompleteEvent = new CustomEvent("restAPILoadComplete", {
      bubbles: true,
      composed: true,
      detail: {
        page: app.userState.spcLibraryState.page,
        totalPages: app.userState.spcLibraryState.totalPages,
        section: "spc",
      },
    });

    document.dispatchEvent(loadCompleteEvent);
    app.userState.spcLibraryState.isLoading = false;

    // Scroll to top
    sectionCourses.scrollTo(0, 0);
  }
});

document.addEventListener("spcUpdatePlaycount", () =>
  uiUpdateStartedSPCListDOM(),
);

const spcTabs = sectionCourses.querySelectorAll('div.spc_filter_tab');
const spcLists = sectionCourses.querySelectorAll('div.spc_list_container')
spcTabs.forEach(activeTab => {
  activeTab.addEventListener('click', () => {
    spcTabs.forEach(tab => tab.classList.remove('active'));
    activeTab.classList.add('active');
    localStorage.setItem('AR_Userdata_Settings_spc_tab_current', activeTab.dataset.target);

    spcLists.forEach(list => list.classList.remove('active'));
    sectionCourses.querySelector(`div.spc_list_container[data-target=${activeTab.dataset.target}`).classList.add('active');
  });
})

export function uiUpdateSPCForYouListDOM() {
  // get pills
  const container = document.querySelector('div#foryou_taxonomypills_container');
  const spcForYouList = document.querySelector('div#spc_list_for_you');
  const spcForYouCount = document.querySelector('div#foryou_explainer span.count');
  let count = 0;

  if (container && spcForYouList) {
    container.innerHTML = "";
    spcForYouList.innerHTML = "";
  } else {
    return;
  }

  const issues = window.Userdata.profileData?.psychology?.issueTargeted;
  if (issues) {
    Object.keys(issues).forEach(termSlug => {
      container.appendChild(printTaxonomyPill(termSlug));
    });

    /**

      Ideally, copy courses out of 'all', and filter them
      get the actual data, then pass it through 'process posts'

      For now, just copy the dom items. That'll only work so long as we get them all
      in one request.

    */

    document.querySelectorAll('div#spc_list_all div.spc_row').forEach(spcRow => {
      const spcInstance = SPC.instances.get(Number(spcRow.dataset['postId']));

      if (spcInstance) {
        const issueTargeted = JSON.parse(spcInstance.taxonomyList.issue_targeted);
        const hasCommonTaxonomy = issueTargeted.some(key => key in issues);

        if (hasCommonTaxonomy) {
          const newRow = createSPCRow({ spc: spcInstance, showTaxonomies: true });
          spcForYouList.appendChild(newRow);
          spcInstance.updateCompletions(); // This needs to be called after SPCRows are added to the DOM. In the future it needs to be refactored so that it can access the completion-bars and update them directly.
          spcForYouCount.innerText = count += 1;
        }
      }
    });
  }
}

window.uiUpdateSPCForYouListDOM = uiUpdateSPCForYouListDOM;

function updateSpcFavsListDOM() {
  // return;
  const favsListDOM = sectionCourses.querySelector("div#spc_favs_list");
  const favs = window.Userdata.spcData.getFavourites();
  processSPCPosts({ posts: favs, target: favsListDOM });
}

export async function uiGetSPCPosts({
  page = app.userState.spcLibraryState.page,
  postType = "spc",
  postsPerPage = 50,
} = {}) {
  const config = { postType, page, postsPerPage };
  console.log('uiGetSPCPosts config', config)

  const spcMain = sectionCourses.querySelector("div#spc_main");
  const spcList = sectionCourses.querySelector("div#spc_list_all");
  const spcPagination = sectionCourses.querySelector("div.pagination_wrap");
  const loadSpinner = document.createElement("loading-spinner");

  if (spcMain && !spcMain.querySelector("loading-spinner")) {
    spcMain.appendChild(loadSpinner);
  }
  spcList.classList.add("global_blur");
  spcPagination.classList.add("global_blur");

  return restAPIRequest(config)
    .then((data) => {
      app.userState.spcLibraryState.page = data.page;
      app.userState.spcLibraryState.totalPages = data.totalPages;
      processSPCPosts(data);

      document.dispatchEvent(
        new CustomEvent("restAPILoadComplete", {
          bubbles: true,
          composed: true,
          detail: {
            page: data.page,
            totalPages: data.totalPages,
            section: "spc",
          },
        }),
      );

      const allCoursesCount = document.querySelector('div.spc_filter_tab[data-target=spc_all]').innerHTML = `All Courses <div class='count'>(${data.posts.length})</span>`;
    })
    .finally((data) => {
      app.userState.spcLibraryState.isLoading = false;

      const loadingSpinner = spcMain.querySelector("loading-spinner");

      if (loadingSpinner) {
        spcMain.removeChild(loadingSpinner);
      }

      spcList.classList.remove("global_blur");
      spcPagination.classList.remove("global_blur");

      uiUpdateSPCForYouListDOM();

    })
    .catch((error) => {
      console.error("uiGetSPCPosts()", error);
      spcList.innerHTML = "";

      const retryButton = document.createElement('button-std');
      retryButton.innerText = "Retry";
      retryButton.setAttribute('icon', icons.repeat);
      retryButton.addEventListener('click', () => {
        spcList.innerHTML = "";
        uiGetSPCPosts();
        // maybe make it also retry meditations?
      });

      const errorBox = document.createElement('div');
      errorBox.classList.add('error_box');
      errorBox.innerHTML = `
        <p>Couldn't load Courses</p>
      `;
      // todo: if network error, print it here.
      errorBox.appendChild(retryButton);

      spcList.appendChild(errorBox);
    });
}

export function processSPCPosts({
  posts: spcPosts,
  target: target,
  totalPages: totalPages,
  page: page,
} = {}) {
  const spcList = sectionCourses.querySelector("div#spc_list_all");
  if (target === undefined) target = spcList;

  // Update pages count widgets
  /**
    Check this... need a better way to handle it
  */
  if (document.querySelector("#spc_list_prev_next_wrap")) {
    document.querySelector(
      "#spc_list_prev_next_wrap div#totalPages",
    ).innerText = totalPages || "";
    document.querySelector(
      "#spc_list_prev_next_wrap div#currentPage",
    ).innerText = page || "";
  }

  if (spcPosts) {
    target.innerHTML = "";
    spcPosts.forEach((spcPost) => {
      const spc = new SPC(spcPost);
      target.appendChild(createSPCRow({ spc, showTaxonomies: true }));
      spc.updateCompletions();
    });
  }
}

export function uiUpdateStartedSPCListDOM() {
  const spcStartedListDOM = document.querySelector("div#spc_started_list");

  if (spcStartedListDOM) {
    const startedSPCs = window.Userdata.spcData.getListOfStartedSPCs();

    if (startedSPCs.size) {
      spcStartedListDOM.innerHTML = "";
      startedSPCs.forEach((spc) => {
        spcStartedListDOM.appendChild(createSPCRow({ spc, showTaxonomies: true }))
      });
    }
  }
}

/**
  Just to check it quickly and easily.
*/
window.spcc = window.Userdata.spcData.usageData;

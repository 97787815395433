// @ts-ignore
import { createMeditationRow } from "../meditation/meditation_row.js";
// @ts-ignore
import { createMenuWidget } from "../ui_components/menu_widget.js";
import { icons } from "../ui_general/icons.js";
import dateFormat from "dateformat";
import { JournalEntry } from "../classes/journal_entry.js";
import { MeditationSummaryData } from "../classes/meditation.js";
import { EntityId } from "../types/global.js";
import { createFullScreenModal } from "../ui_components/full_screen_modal.js";

export function journalEntryComponentDiv(journalEntry: JournalEntry, linkToMeditation = false) {
  const journalEntryDiv = document.createElement("div");

  journalEntryDiv.classList.add("journal_entry");
  journalEntryDiv.dataset.uuid = journalEntry.uuid;
  journalEntryDiv.dataset.meditationId = String(journalEntry.meditationId);

  journalEntryDiv.innerHTML = `
    <div class='journal_contents'>

      <div class='journal_date'>
        <iconify-icon noobserver icon='${icons.postType.journal}'></iconify-icon>
        <div class='date'>${dateFormat(journalEntry.date, "fullDate")}</div>
      </div>

      <div class='journal_text'>${journalEntry.text?.replaceAll("\n", "<br/>") ?? ""}</div>
    </div>
  `;

  if (linkToMeditation) {
    journalEntryDiv.innerHTML += `<div class='from'>From</div>`;
    journalEntryDiv.appendChild(
      createMeditationRow({
        meditationSummaryData: journalEntry.meditationData,
        showTaxonomies: false,
        size: "mini",
      }),
    );
  }

  /**
    Add code to spawn a fullScreenModal (if we are in a meditation pane)
  */
  // const fullScreenModal = createFullScreenModal();

  // journalEntryDiv.addEventListener('click', () => {
  //   if (document.querySelector('div.pane_wrap')) {
  //     console.log('ok click');
  //     document.body.appendChild(fullScreenModal);
  //   }
  // });


  // Menu button
  journalEntryDiv.insertAdjacentElement("afterbegin", createMenuWidget("delete", journalEntry));

  return journalEntryDiv;
}

/**
  This function does two things:
  1. Make a textarea to create new journal entries
  2. Populate it with today's journal entry for this meditation

  - todo: Extract the textArea functions so that they can be used for editing entries
**/
export function createMeditationJournalPaneContainer({ meditation }: { meditation: MeditationSummaryData }) {

  const maxLength = 5000;
  const meditationJournalContainer = document.createElement("div");
  meditationJournalContainer.classList.add("meditation_journal_container");
  meditationJournalContainer.innerHTML = `
    <div class='subheader'>Journal</div>
    <div class='status_notice'>Journal saved</div>
  `;

  const textArea = document.createElement('textarea');
  textArea.classList.add('meditation_journal');
  textArea.setAttribute('rows', String(8));
  textArea.setAttribute('maxLength', String(maxLength));

  // Limit textArea character input length
  ["input", "change"].forEach(eventType => {
    textArea.addEventListener(eventType, () => {
      if (textArea.value.length > maxLength) {
        textArea.value = textArea.value.substring(0, maxLength);
      }
    });
  });

  const existingJournalEntry = window.Userdata.journalData.findJournal({
    date: new Date(),
    meditationId: meditation.meditationId,
  });

  const journalEntry = existingJournalEntry
    ? new JournalEntry({
      text: existingJournalEntry.text,
      uuid: existingJournalEntry?.uuid,
      date: existingJournalEntry?.date,
      meditationId: existingJournalEntry.meditationId,
      meditationData: existingJournalEntry.meditationData,
    })
    : new JournalEntry({
      meditationId: meditation.meditationId,
      meditationData: meditation,
    });

  textArea.value = journalEntry.text ?? "";

  // Store Journal entry
  // Add some counter to save it every minute,
  // and a little widget to notify
  ["blur", "DOMNodeRemoved"].forEach(eventType => {
    textArea.addEventListener(eventType, () => {
      journalEntry.saveJournal(textArea.value);

      const updatedNotice = meditationJournalContainer.querySelector<HTMLElement>('div.status_notice');
      if (updatedNotice) {
        updatedNotice.classList.add('show');
        setTimeout(() => {
          updatedNotice.classList.remove('show');
        }, 2000);
      }
    });
  })

  meditationJournalContainer.appendChild(textArea);

  return meditationJournalContainer;
}

export function displayJournalEntries({
  journalEntries = new Map(),
  target,
  link_to_meditation = true,
  uuidToOmit = null,
}: {
  journalEntries?: Map<EntityId, JournalEntry>,
  uuidToOmit?: EntityId | null
  target?: Element | null,
  link_to_meditation?: boolean
} = {}): void {
  if (journalEntries.size === 0) {
    journalEntries = window.Userdata.journalData.usageData;
  }

  if (!target) {
    target = document.querySelector(
      "section#journal div#meditation_journal_previous",
    );
  }

  const journalEntriesArray: JournalEntry[] = [...journalEntries.values()]
    .sort((journalEntry1, journalEntry2) => {
      return Number(journalEntry1.date < journalEntry2.date);
    });

  // Display all except this one
  if (uuidToOmit) {
    journalEntriesArray.values().filter(journalEntry => journalEntry.uuid !== uuidToOmit);
  }

  if (journalEntriesArray && target) {
    target.innerHTML = "";
    journalEntriesArray.forEach((journalEntry: JournalEntry) => {
      if (!journalEntry?.deleted) {
        target.appendChild(journalEntryComponentDiv(journalEntry, link_to_meditation));
      }
    });
  }
}

export function printJournalEntriesDivList(journalEntries: Map<EntityId, JournalEntry>, uuidToOmit?: EntityId): HTMLElement | null {

  if (journalEntries.size) {
    const createEntriesContainer = () => {
      const entriesContainer = document.createElement("div");
      entriesContainer.classList.add('journal_list');
      entriesContainer.setAttribute("id", "meditation_journal_previous");
      entriesContainer.innerHTML = `<div class='subheader'>Previous Entries</div>`;
      return entriesContainer;
    };
    const entriesContainer = createEntriesContainer();

    // todo: needs to be modified to return the HTMLElement instead of appending it to the target
    displayJournalEntries({
      journalEntries: journalEntries,
      target: entriesContainer,
      uuidToOmit: uuidToOmit,
      link_to_meditation: false,
    });

    return entriesContainer;
  } else {
    return document.createElement('div');
  }
}

import "./ar_globals";

/**
  User state of the app that changes based on user preferences and actions
*/
export const state = {
  chatInit: false,
  journalInit: false,
  meditationLibraryInit: false,
  spcLibraryInit: false,
  recordingLibraryInit: false,
}

export const userState = {
  hasLoginToken: Boolean(window.Userdata?.jwt ?? false),
  meditationLibraryState: {
    page: 1,
    isLoading: false,
    totalPages: undefined,
  },
  spcLibraryState: {
    page: 1,
    isLoading: false,
    totalPages: undefined,
  },
  recordingLibraryState: {
    page: 1,
    isLoading: false,
    totalPages: undefined,
  },
};

/**
  Configuration settings for the app defined by the developer
*/
export const config = {
  debugBox: true,
  touchLogging: false,
}

/**
  Custom post type info for the app defined by the developer
*/
export const info = {
  spc: {
    taxonomies: {
      issue_targeted: {
        slug: "issue_targeted",
        name: "Issue Targeted",
        nameShort: "Issue",
        enabled: true,
      },
      schemas: {
        slug: "schemas",
        name: "Schemas",
        nameShort: "Schema",
        enabled: true,
      },
    },
    filters: {
      issue_targeted: {
        enabled: true,
        input: "select",
        order: 1,
        label: "In particular...",
        label: "",
        name: "Issue Targeted",
        nameShort: "Issue",
        type: "taxonomy",
      },
      schemas: {
        enabled: true,
        input: "select",
        order: 2,
        label: "Schemas",
        label: "",
        name: "Schemas",
        nameShort: "Schema",
        type: "taxonomy",
      },
    },
  },
  meditation: {
    options: {
      backSeekOptions: [5, 15],
    },
    taxonomies: {
      purpose: {
        slug: "purpose",
        name: "Purpose",
        nameShort: "Purpose",
        enabled: true,
      },
      issue_targeted: {
        slug: "issue_targeted",
        name: "Issue Targeted",
        nameShort: "Issue",
        enabled: true,
      },
      schemas: {
        slug: "schemas",
        name: "Schemas",
        nameShort: "Schema",
        enabled: false,
      },
      techniques: {
        slug: "techniques",
        name: "Techniques",
        nameShort: "Technique",
        enabled: false,
      },
      inner_critics: {
        slug: "inner_critics",
        name: "Inner Critics",
        nameShort: "Critics",
        enabled: false,
      },
      modes: {
        slug: "modes",
        name: "Modes",
        nameShort: "Mode",
        enabled: false,
      },
      attachment_styles: {
        slug: "attachment_styles",
        name: "Attachment Styles",
        nameShort: "Style",
        enabled: false,
      },
      dmm_type: {
        slug: "dmm_type",
        name: "DMM Type",
        nameShort: "DMM",
        enabled: false,
      },
    },
    filters: {
      purpose: {
        enabled: true,
        input: "input[type='radio']",
        inputType: "radio",
        elementType: "input",
        dataType: "string",
        order: 1,
        label: "",
        name: "I want to work on...",
        filterType: "taxonomy",
        type: "taxonomy",
        slug: "purpose",
      },
      issue_targeted: {
        enabled: true,
        input: "select",
        inputType: "select",
        elementType: "select",
        dataType: "string",
        order: 2,
        label: "",
        name: "In particular...",
        filterType: "taxonomy",
        type: "taxonomy",
        slug: "issue_targeted",
      },
      techniques: {
        enabled: false,
        input: "select",
        inputType: "select",
        elementType: "select",
        dataType: "string",
        order: 3,
        label: "",
        name: "Method",
        filterType: "taxonomy",
        type: "taxonomy",
        slug: 'techniques',
      },
      duration: {
        enabled: true,
        input: "input[type='radio']",
        inputType: "radio",
        elementType: "input",
        dataType: "number",
        order: 4,
        label: "",
        name: "Duration",
        slug: "duration",
      },
      keywords: {
        enabled: true,
        input: "input[type='text']",
        inputType: "text",
        elementType: "input",
        dataType: "string",
        order: 3,
        label: "",
        name: "Keywords",
        slug: 'keywords',
        placeholder: "Must include these terms...",
      },
      schemas: {
        enabled: false,
        input: "input[type='checkbox']",
        inputType: "checkbox",
        elementType: "input",
        dataType: "string",
        order: 4,
        label: "",
        name: "Schemas",
        filterType: "taxonomy",
        type: "taxonomy",
        slug: 'schemas'
      },
      inner_critics: {
        enabled: false,
        input: undefined,
        type: "taxonomy",
      },
      modes: {
        enabled: false,
        input: undefined,
        type: "taxonomy",
      },
      attachment_styles: {
        enabled: false,
        input: undefined,
        type: "taxonomy",
      },
      dmm_type: {
        enabled: false,
        input: undefined,
        type: "taxonomy",
      },
      membership_level: {
        enabled: false,
        input: undefined,
      },
    },
  },
  enabledTaxonomies: function (postType) {
    return Object.entries(this[postType].taxonomies)
      .filter(([_, value]) => value.enabled)
      .map(([key]) => key);
  },
  enabledFilters: function (postType) {
    return Object.entries(this[postType].filters)
      .filter(([_, value]) => value.enabled)
      .map(([key]) => key);
  },
};

import { EntityId } from "../types/global";
import { Meditation } from "./meditation";
import { MembershipLevel } from "../types/membership_level";
import { OnlineEvent } from "./online_event";

export type EventRecordingSummaryData = {
  postType: "event-recording";
  postId: EntityId;
  membershipLevel: MembershipLevel;
  canAccess: boolean;

  // Recordings section
  access: {
    customUserAccess: boolean;
    areAvailable: boolean;
    areExpired: boolean;
    expiryDate: Date;
    list: Array<Recordings>;
  };

  // Assessments section
  assessments: {
    postFormAvailableDate: string | null;
    followupAvailableDate: string | null;
    preFormURL: string | null;
    postFormURL: string | null;
    followupFormURL: string | null;
  };

  // Source event fields
  source: {
    title: string;
    coupon: string | null;
    recommendedNextCourses: Array<OnlineEvent>;
    recordingType: string;
    featuredImage: {
      image_url_full: string;
      image_url_large: string;
      image_url_medium: string;
      image_url_medium_large: string;
      image_url_thumbnail: string;
    };
    onlineEventId: EntityId;
    eventType: string;
    eventHasEnded: boolean;
    eventTypeShort: string;
    description: string;
    totalWeeks: number;
    currentWeek: number;
  };
};

type Stats = {
  favourite: boolean;
  timestamp: string | undefined;
  completed: boolean;
  started: boolean;
  lastPlayed: string | undefined;
};

type Recordings = {
  week_number: string,
  bonus_class: boolean,
  bonus_class_title: string,
  date: string,
  notes: string,
  presentation_slides: string,
  homework: string,
  options: Array<RecordingOption>,
  extra_meditations: Array<Meditation>, // its an array of WP post objects???
}

type RecordingOption = {
  option_number: number,
  link_to_video: string,
  guided_meditations: Array<Meditation>, // its an array of WP post objects???
}

type ARBasics = {
  value: boolean,
  label: 'True' | 'False'
}

export class EventRecording implements EventRecordingSummaryData {
  /**
    Track all the instances and ensure there is only ever one of each
  */
  public static instances: Map<EntityId, EventRecording> = new Map();

  /**
    Instead of initialising this Class with `new` it should be initialised with this method. It returns a new instance if one doesn't exist.

    eg. const recording = EventRecording.getInstance(EventRecordingSummaryData);
  */
  public static getInstance(params: {
    eventRecordingSummaryData?: EventRecordingSummaryData;
    recording_id?: EntityId,
  }): EventRecording | undefined {
    // Access an existing EventRecording by the ID
    if (params.recording_id) {
      if (EventRecording.instances.has(params.recording_id)) {
        return EventRecording.instances.get(params.recording_id)!;
      }
    }

    // Initialise new EventRecording or get one by the ID
    if (params.eventRecordingSummaryData) {
      const postId: EntityId = params.eventRecordingSummaryData.postId;

      if (EventRecording.instances.has(postId)) {
        return EventRecording.instances.get(postId);
      }

      const instance: EventRecording = new EventRecording(params.eventRecordingSummaryData);
      EventRecording.instances.set(String(postId), instance);

      return instance;
    }

    return undefined;
  }

  // Required properties from EventRecordingSummaryData
  public readonly postType!: EventRecordingSummaryData['postType'];
  public readonly postId!: EventRecordingSummaryData['postId'];
  public readonly membershipLevel!: EventRecordingSummaryData['membershipLevel'];
  public readonly canAccess!: EventRecordingSummaryData['canAccess'];

  // Recordings section
  public readonly access!: EventRecordingSummaryData['access'];

  // Assessments section
  public readonly assessments!: EventRecordingSummaryData['assessments'];

  // Source section
  public readonly source!: EventRecordingSummaryData['source'];

  // Additional properties
  public readonly id: EntityId;
  public userStats: Stats = {
    favourite: false,
    timestamp: undefined,
    completed: false,
    lastPlayed: undefined,
    started: false,
  };

  private constructor(eventRecordingSummaryData: EventRecordingSummaryData) {
    this.id = eventRecordingSummaryData.postId;
    Object.assign(this, eventRecordingSummaryData);

    this.initialiseSelf();
  }

  private initialiseSelf(): void {
    EventRecording.instances.set(this.postId, this);
    this.loadUserStats();
  }

  private loadUserStats(): void {
    this.userStats = window.Userdata.spcData.usageData.get(this.postId)
      ?.userStats ?? this.userStats;
  }

  private instanceUpdated() {
    return (this.userStats.timestamp = new Date().toString());
  }
}

import { lecturePartPill } from "../ui_general/ar_utils.ts";
import { slidePane } from "../ui_components/slide_pane.js";
import { createMeditationRow } from "../meditation/meditation_row.js";
import { icons } from "../ui_general/icons.ts";
import { printTaxonomyPillsContainer } from "../ui_components/taxonomyPill.ts";
import { SPC } from "../classes/spc.ts";

export function linkToSPCPaneBlock(spc) {
  const spcPaneBlock = createSPCPaneBlock(spc);
  const spcInstance = SPC.getInstance({ spcSummaryData: spc });

  slidePane({
    element: spcPaneBlock,
    title: "Self-Paced Course",
    callback: {
      open: [
        () => createSPCFilesList(spcInstance, spcPaneBlock),
        () => spcInstance.updateCompletions(),
      ],
    },
    dataset: spc,
  });
}

export function createSPCFilesList(spc, spcPaneBlock) {
  // console.log('createSPCFilesList()')

  // print the modules list here
  if (spc.includedModules) {
    const filesList = spc.includedModules.map((module, index) => {
      // console.log('module', module)
      const moduleContainer = document.createElement("div");
      moduleContainer.classList.add("module_container");
      moduleContainer.innerHTML = `
        <div class='module_header'>
          <div class='index'>${index + 1}</div>
          <div class='module_title'>${module.module_title}</div>
        </div>

        <div class='module_files'>
        </div>
      `;

      if (module.files) {
        module.files.forEach((file) => {
          const fileElement = document.createElement("div");
          fileElement.classList.add("file");
          fileElement.dataset["canAccess"] = file.canAccess;

          if (file.acf_fc_layout === "lecture") {
            const fileClass = "lecture";
            fileElement.classList.add(fileClass);
            let format; // video|audio|noAccess|null

            // Check the canAccess value here.
            // console.log('spcBlock file', file)
            // console.log('spcBlock canAccess?', file.canAccess);

            // Display a padlock instead of the audio/video player if locked.

            // let player = "";
            let player = document.createElement("div");

            if (!file.lecture_file) {
              format = null;
              player.innerHTML = `<div class='no_lecture'></div>`;
            } else {
              if (file.canAccess) {
                if (file.video_url) {
                  format = "video";
                  player = document.createElement("video");
                  player.setAttribute("controls", true);
                  player.setAttribute("controlsList", "nodownload");
                  player.innerHTML = `<source src="${file.video_url}" type="video/mp4">
                  Your browser does not support the video tag.`;

                  player.addEventListener("play", () => {
                    console.log("playing SPC lecture video");
                    spc.updatePlaycount();
                  });
                } else if (file.audio_url) {
                  format = "audio";
                  player = document.createElement("audio");
                  player.setAttribute("controls", true);
                  player.setAttribute("controlsList", "nodownload");
                  player.innerHTML = `source src="${file.audio_url}" type="video/mp4">
                  Your browser does not support the audio tag.`;

                  player.addEventListener("play", () => {
                    console.log("playing SPC audio");
                    spc.updatePlaycount();
                  });
                }
              } else {
                format = "noAccess";
                player.innerHTML = `
                  <div class='fileNoAccess'>
                    <iconify-icon noobserver class='noaccess' icon="${icons.padlock}"></iconify-icon>
                  </div>`;
              }
            }

            // Define HTML
            if (format === null) {
              fileElement.innerHTML = null;
            } else {
              const lectureTitle = lecturePartPill({
                title: file.title,
                regex: /Part \d{1,2}/,
                cssClass: "part",
              });

              fileElement.innerHTML = `
                <div class='lecture'>
                  <div class='player ${format}' data-can-access='${file.canAccess}'></div>
                  <div class='lecture_title'>${lectureTitle}</div>
                  <div class='lecture_duration'>${file.duration} mins</div>
                </div>
                `;
              fileElement.querySelector(".player").appendChild(player);
            }
          } else if (file.acf_fc_layout === "meditation") {
            const fileClass = "meditation";
            fileElement.classList.add(fileClass);

            const meditationSummaryData = file.meditation_library_all;
            const meditationRow = createMeditationRow({
              meditationSummaryData,
              page: "spc",
            });
            fileElement.appendChild(meditationRow);
          } else if (file.acf_fc_layout === "document") {
            const fileClass = "document";
            fileElement.classList.add(fileClass);
            fileElement.innerHTML = `
              <div class='document'>
                <div class='document_title'>${file.title}</div>
                <div class='document_format'>${file.format.label}</div>
              </div>
              `;
          }

          fileElement.addEventListener("click", () => {
            console.log("notify SPC");
            spc.updatePlaycount();
          });

          moduleContainer
            .querySelector(".module_files")
            .appendChild(fileElement);
        });
      }

      return moduleContainer;
    });

    filesList.forEach((fileElement) => {
      spcPaneBlock.querySelector("div.modules_list").appendChild(fileElement);
    });
  }
}

export function createSPCPaneBlock(spc) {
  // console.log('createSPCPaneBlock()');
  // console.log('spc', spc);

  const spcPaneBlock = document.createElement("div");
  spcPaneBlock.classList.add("block", "spc_block");
  spcPaneBlock.dataset["canAccess"] = spc.canAccess;
  spcPaneBlock.innerHTML = `
    <div class='image_header'>
      <img src='${import.meta.env.VITE_HOST_URL}/${spc.images.spc_image_url_full}' />
    </div>

    <div class='spc_title'>${spc.title}</div>
    <div class='summary'>${spc.summarySentence}</div>
    <div class='extra_data'></div>

    <div class='completion_bar_container'>
      <completion-bar></completion-bar>
    </div>

    <div class='modules_list'></div>
  `;

  const taxonomyPills = printTaxonomyPillsContainer({ postInstance: spc });
  if (taxonomyPills) {
    spcPaneBlock.querySelector("div.extra_data").appendChild(taxonomyPills);
  }

  return spcPaneBlock;
}

customElements.define(
  "button-std",
  class ButtonStd extends HTMLElement {
    constructor() {
      super();

      this.hue = `323`; // pink
      this.attachShadow({ mode: "open" });
      this.render();

      // Create a MutationObserver to observe attribute changes
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === "attributes") {
            // console.log('Change attribute:', mutation.attributeName, this.getAttribute(mutation.attributeName))
          }
        });
      });

      observer.observe(this, { attributes: true });
    }

    connectedCallback() {
      // console.log('this.getAttribute("icon)', this.getAttribute("icon"))
      this.setHue();
      this.updateIcon(this.getAttribute("icon")); // Set initial icon
      // this.updateOnClick(this.getAttribute("onclick")); // Set initial onclick

      if (this.hasAttribute("network")) {
        const attrValue = this.getAttribute("network");
        this.addEventListener("click", () => {
          this.setAttribute("disabled", true);
          // const loadSpinner = document.createElement("loading-spinner");
        });
      }
    }

    disconnectedCallback() {
      // console.log("Button1 removed from page.");
    }

    adoptedCallback() {
      // console.log("Custom element moved to new page.");
    }

    setHue() {
      this.hue = this.getAttribute("hue") || this.hue;
      this.render();
    }

    updateLabel(newLabel) {
      this.shadowRoot.querySelector("slot[name='label']").textContent =
        newLabel;
    }

    updateIcon() {
      const icon = this.getAttribute("icon");
      const iconElement = this.shadowRoot.querySelector("iconify-icon");

      // Update the icon attribute or clean up if it doesn't exist
      if (icon) {
        iconElement.setAttribute("icon", icon);
      } else {
        iconElement.removeAttribute("icon");
      }
    }

    // updateOnClick(newOnClick) {
    //   this.setAttribute("onclick", newOnClick);
    // }

    render() {
      this.shadowRoot.innerHTML = `
        <style>

        :host {
          --cyan: 210;
          --lime: 120;
          --yellow: 40;
          --hue: ${this.hue};

          --bg-h: var(--hue);
          --bg-s: 80%;
          --bg-l: 90%;
          --bg-a: 1.0;

          --border-width: 3px;
          --border-colour: hsla(var(--hue), 100%, 95%, 1.0);

          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          column-gap: 8px;

          height: fit-content;

          padding: 5px 20px;
          margin:  0;

          appearance: none;

          background: hsla(var(--bg-h), var(--bg-s), var(--bg-l), var(--bg-a));

          border-style: solid;
          border-width: 0 0 var(--border-width);
          border-radius: 10px;
          border-color: var(--border-colour);
          border-bottom-color: hsl(var(--hue), 72.4%, 71.6%);

          color: hsl(var(--hue), 93.2%, 11.6%);
          font-family: inherit;
          font-size: 18px;
          cursor: pointer;

          box-sizing: border-box;
          margin: 0;

          position: relative;
          top: 0;

          iconify-icon {
            font-size: inherit;
            color: inherit;
            opacity: 0.75;
            display: none;

            &[icon] {
              display: inline-block;
            }
          }
        }

        :host([size='micro']) {
          --border-width: 1px;
          border-width: var(--border-width);
          padding: 3px 5px;
          border-radius: 4px;
          font-size: 12px
        }
        :host([size='small']) {
          --border-width: 2px;
          padding: 6px 9px;
          border-radius: 8px;
          font-size: 14px;
          border-width: var(--border-width);
        }
        :host([size='normal']) {
          --border-width: 3px;
          padding: 7px 15px;
          border-radius: 10px;
          font-size: 16px;
          border-width: var(--border-width);
        }
        :host([size='big']) {
          --border-width: 3px;
          padding: 10px 30px;
          border-radius: 12px;
          font-size: 18px;
          border-width: var(--border-width);
          flex-grow: 1;
        }
        :host(:active) {
          border-bottom-width: var(--border-width);
          top: var(--border-width);
        }
        :host([type='clear']) {
          --color: #fff;
          color: #fffe;
          border-width: 2px !important;
          border-color: #fff5;
          background-color: hsla(var(--bg-h), var(--bg-s), var(--bg-l), 0);
        }
        :host([type='flat']) {
          --color: #fff;
          color: #000e;
          border-width: 0px !important;
          background-color: hsla(var(--bg-h), var(--bg-s), var(--bg-l), 1);
        }
        :host([type='flat']):active {
          top: 1px;
        }
        :host([disabled]) {
          filter: grayscale(0.75);
          cursor: not-allowed;
          opacity: 0.5;
          color: #000b;
          pointer-events: none;

          & > * {
            pointer-events: none;
          }
        }
        :host([id='apply_filter']) {
          flex-grow: 1;
        }
        :host {
          span.label {
            height: 25px;
            opacity: 0.8;
          }
        }
        ::slotted(*) {
          opacity: 0.5;
        }
      </style>

      <slot name='icon'>
        <iconify-icon noobserver icon='undefined'></iconify-icon>
      </slot>
      <slot></slot>
        `;
    }
  },
);

import interact from "interactjs";
import Sortable from "sortablejs";
import "iconify-icon";
import dateformat, { masks } from "dateformat";

import tippy, { roundArrow } from "tippy.js";
import "tippy.js/dist/tippy.css"; // Tippy docs: "optional for styling"
import "tippy.js/themes/light.css";
import "tippy.js/dist/svg-arrow.css";
import "tippy.js/animations/scale.css";
import "tippy.js/animations/scale-subtle.css";
import "tippy.js/animations/shift-away.css";

// Append the icon to the body or wherever it's needed
// document.body.appendChild(createIcon('homeIcon'));
// console.log('icon', createIcon('homeIcon'));

// necessary to make it globally available.
window.interact = interact;
window.Sortable = Sortable;
window.tippy = tippy;
window.roundArrow = roundArrow;
window.dateformat = dateformat;
window.dateformatMasks = masks;

// todo: install Vibrant

// Dateformat Masks
// Presets: dateformatMasks.fullDate - Saturday, June 9, 2007
dateformatMasks.mid = "dS mmm yyyy"; // 28th Jul 2024
dateformatMasks.short = "dS mmm"; // 28th Jul 2024
dateformatMasks.dateYYYYMMSS = "yyyymmdd"; // 20240901

// PWA Asset Generator:
// - https://github.com/elegantapp/pwa-asset-generator
// For icons, splash screens

import { icons } from "../ui_general/icons.ts";

customElements.define(
  "button-arrow",
  class ButtonArrow extends HTMLElement {
    static get observedAttributes() {
      return ["direction", "onclick"];
    }

    constructor() {
      super();
      this.attachShadow({ mode: "open", inherits: true });
      this.render();
    }

    // Method to update component based on attributes
    render() {
      const direction = this.getAttribute("direction") || "right"; // Default direction is 'right'
      const arrow = direction == "right" ? "arrowRight" : "arrowLeft";

      this.shadowRoot.innerHTML = `
        <style>
          div.button_arrow {
            display: flex;
            flex-grow: 1;
            justify-content: center;
            align-items: center;

            background: var(--button-arrow-background);
            color: var(--button-arrow-color);
            padding: 2px 28px;
            width: fit-content;
            height: 1em;
            font-size: 24px;
            cursor: pointer;
            border-radius: 12px;
            border-bottom: 2px solid var(--button-arrow-border-bottom);

            &:active {
              position: relative;
              top: 2px;
            }

            &.disabled {
              pointer-events: none;
              opacity: 0.3;
              cursor: notallowed;
            }
          }
        </style>

        <div class='button_arrow'>
          <iconify-icon noobserver icon='${icons[arrow]}'></iconify-icon>
        </div>
      `;
    }

    connectedCallback() {
      document.addEventListener("restAPILoadComplete", () =>
        this.updateDisabledState(event),
      );
      this.addEventListener("click", this.handleClick);
    }

    // React to attribute changes
    attributeChangedCallback(name, oldValue, newValue) {
      if (name === "direction" && oldValue !== newValue) {
        this.render();
        this.updateDisabledState(event); // Update state when attribute changes
      }
    }

    updateDisabledState(event) {
      const section = this.getAttribute("section");

      if (event.detail && event.detail.section === section) {
        // console.log('updateDisabledState() event:', event)
        const direction = this.getAttribute("direction");
        const button = this.shadowRoot.querySelector(".button_arrow");

        const { page, totalPages } = event.detail;

        if (direction === "right") {
          if (page >= totalPages) {
            // console.log(`On last ${section} page ➡️`);
            button.classList.add("disabled");
          } else {
            button.classList.remove("disabled");
          }
        }

        if (direction === "left") {
          if (page <= 1) {
            // console.log(`⬅️ On first ${section} page`);
            button.classList.add("disabled");
          } else {
            button.classList.remove("disabled");
          }
        }
      }
    }

    handleClick = async () => {
      const sectionName = this.getAttribute("section");
      const direction = this.getAttribute("direction");
      const eventPageLoad = new CustomEvent(`${sectionName}_load_event`, {
        bubbles: true,
        composed: true,
        detail: { direction },
      });

      // [] Check this later about adding " || pageState.isLoading" check.
      if (!this.isDisabled()) {
        this.dispatchEvent(eventPageLoad);
      } else {
        return;
      }
    };

    isDisabled = () => {
      return this.shadowRoot
        .querySelector(".button_arrow")
        .classList.contains("disabled");
    };

    disconnectedCallback() {
      this.removeEventListener("click", this.handleClick);
    }

    adoptedCallback() { }
  },
);

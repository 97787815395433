export const AppDB = "AR_App";
export const DB_VER = 14;
export const appTitle = "Attachment Repair Membership";

/**
  This is the app viewport. All code happens within this container div.
*/
window.viewPort = document.querySelector("div#app_viewport");

export const domain = {
  protocol: window.location.protocol, // http | https
  label: "",
  hostname: window.location.hostname, // eg. https://attachmentrepair.com
  host: window.location.host, // hostname plus :port
};
// console.log(domain.protocol, domain.hostname, domain.host)

export const restApiHostUrl = import.meta.env.VITE_HOST_URL;
// export const restApiHostUrl = 'https://attachmentrepair.com';

export const arURL = {
  utm_source: "ar_app",
};

export const tgChannels = {
  frontendErrors: "-1002434015927",
  userFeedback: "",
};

// export const meditationTaxonomyTerms = {
//   purpose: {
//     enabled: true,
//     input: "input",
//     order: 1,
//     label: "I want to work on...",
//     name: "Purpose",
//     nameShort: "Purpose",
//     type: "taxonomy",
//   },
//   issue_targeted: {
//     enabled: true,
//     input: "select",
//     order: 2,
//     label: "Issue Targeted",
//     name: "Issue Targeted",
//     nameShort: "Issue",
//     type: "taxonomy",
//   },
//   techniques: {
//     enabled: false,
//     input: "select",
//     order: 3,
//     label: "Meditation Techniques",
//     name: "Meditation Techniques",
//     nameShort: "Method",
//     type: "taxonomy",
//   },
//   schemas: {
//     enabled: false,
//     input: undefined,
//     type: "taxonomy",
//   },
//   inner_critics: {
//     enabled: false,
//     input: undefined,
//     type: "taxonomy",
//   },
//   modes: {
//     enabled: false,
//     input: undefined,
//     type: "taxonomy",
//   },
//   attachment_styles: {
//     enabled: false,
//     input: undefined,
//     type: "taxonomy",
//   },
//   dmm_type: {
//     enabled: false,
//     input: undefined,
//     type: "taxonomy",
//   },
// };

// export const spcTaxonomyTerms = {
//   issue_targeted: {
//     enabled: true,
//     input: "select",
//     order: 1,
//     label: "In particular...",
//     label: "",
//     name: "Issue Targeted",
//     nameShort: "Issue",
//     type: "taxonomy",
//   },
// };

// Globally catch all errors to forward them to telegram

import { tgChannels } from "../data/ar_globals";
import * as check from "../data/ar_checks";
import { apiURL } from "../restapi/restapi_requests";
import { authHeader } from "../restapi/restapi_login_user";

if (check.getEnvironment() === "Production") {
  window.onerror = function (message, source, lineno, colno, error) {
    const text = `🦔 🦔 🦔 💨 ${message}, ${source}:${lineno}:${colno}`;
    console.error(text);
    messageToTelegram(text);
  };
}

async function messageToTelegram(message) {
  const url = apiURL("mkt", "error_message");
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader(),
    },
    body: JSON.stringify({
      message: message,
      channel: tgChannels.frontendErrors,
    }),
  });

  const result = await response.json();

  if (response.ok) {
    // result...

    return result;
  } else {
    throw new Error(result.message);
  }
}

import { slidePane } from "../ui_components/slide_pane.js";
import { createMeditationRow } from "../meditation/meditation_row.js";
import { EventRecording } from "../classes/event_recording.ts";
import dateFormat from "dateformat";
import { initialiseTippyOn } from "../ui_general/tippy.ts";


export function linkToEventRecordingPaneBlock(recording) {
  const recordingPaneBlock = createRecordingPaneBlock(recording);
  const recordingInstance = EventRecording.getInstance({ recordingSummaryData: recording });

  slidePane({
    element: recordingPaneBlock,
    title: "Recording",
    callback: {
      open: [
        () => createRecordingFilesList(recording, recordingPaneBlock),
        // () => recordingInstance.updateCompletions(),
      ],
    },
    dataset: recording,
  });
}

export function createRecordingFilesList(recording, recordingPaneBlock) {
  console.log('createRecordingFilesList()', recording);

  /**

    Loop over the weeks
    Display a box for each one

    - extra meditation

  */

  // print the modules list here
  if (recording.access.list) {
    const recordingsList = [...recording.access.list].reverse().map((module, index) => {

      const collapseId = `week${module.week_number}`;
      /**
        Each module is defined here.
      */
      const moduleContainer = document.createElement("div");
      moduleContainer.classList.add("module_container", `week${module.week_number}`, "collapse-box");
      moduleContainer.setAttribute('id', collapseId);
      moduleContainer.innerHTML = `

        <input class='collapse_toggle' id='${collapseId}-checkbox' type='checkbox'>
        <label class='collapse_toggle week_toggle' for='${collapseId}-checkbox'>
          <div class='module_header'>
            <div class='index weeknum'>Week ${module.week_number}
              <div class='date_recorded'>${dateFormat(module.date, 'short')}</div>
            </div>
            <div class='module_title'>${module.curriculum?.class_title ?? ''}</div>
          </div>
        </label>

        <div class='collapse-contents'>

          <div class='module_contents'>
            <div class='notes module_box'>
              <div class='subheader3'>Notes</div>
              <div class='module_box_content'>
                ${stripLeadingBrTag(module.notes) || 'No notes.'}
              </div>
            </div>
            <div class='homework module_box'>
              <div class='subheader3'>Homework</div>
              <div class='module_box_content'>
                ${stripLeadingBrTag(module.homework)}
              </div>
            </div>
            <div class='slides module_box'>
              <div class='subheader3'>Class Slides</div>
              <div class='module_box_content'>
                <a href='${module.presentation_slides}' target='_blank'>View Week ${module.week_number} slides</a>
              </div>
            </div>
            <div class='recordings module_box'>
              <div class='subheader3'>Recordings</div>
              <div class='option_tab_container' id='option_tab_container_w${module.week_number}'></div>
            </div>
          </div>
        </div>
      `;

      if (module.options) {
        const recordingsContainer = moduleContainer.querySelector('div.recordings');
        const optionTabContainer = recordingsContainer.querySelector(`div.option_tab_container`);

        module.options.forEach((option) => {

          const sessionName = option.option_number === "1" ? "(Morning)" : "(Evening)";
          const optionTabId = `option_tab_w${module.week_number}_s${option.option_number}`;
          const activeTab = option.option_number === "1" ? 'active' : 'inactive';
          const optionTab = document.createElement('div');
          optionTab.classList.add('option_tab', activeTab);
          optionTab.setAttribute('id', optionTabId);
          optionTab.innerHTML = `Option ${option.option_number} ${sessionName}`;
          optionTab.addEventListener('click', () => {
            // remove active class from others
            optionTabContainer.querySelectorAll('div.option_tab').forEach(tab => {
              tab.classList.remove('active');
            })
            optionTab.classList.add('active');
            recordingsContainer.querySelectorAll('div.recordings div.option_container').forEach(optionContainer => {
              optionContainer.classList.remove('inactive');
              if (optionContainer.dataset.optionId === optionTab.id) {
                optionContainer.classList.add('active');
              } else {
                optionContainer.classList.remove('active');
              }
            });
          })

          const optionContainer = document.createElement('div');
          const qnaId = `qna_w${module.week_number}_s${option.option_number}`;
          const qnaDisable = !option.question_and_answer ? "disabled" : "";
          const qnaText = option.question_and_answer?.replace(/(Q|A)\d*:|(Q|A):/g, (match) => `<strong>${match}</strong>`) || "No Q&A for this session.";

          optionContainer.classList.add('option_container', `option_${option.option_number}`, activeTab);
          optionContainer.dataset.optionId = optionTabId;
          optionContainer.innerHTML = `

            <div class='lecture'></div>

            <div class='gm'>
              <div class='subheader5'>Guided Meditations from the Lecture</div>
              <div id='guided_meditation_list' class='meditation_list'></div>
            </div>

            <div class='qna_section collapse-box' id='${qnaId}'>
              <input class='collapse_toggle' id='${qnaId}-checkbox' type='checkbox' ${qnaDisable ? 'checked' : ''}>
              <label class='collapse_toggle ${qnaDisable}' for='${qnaId}-checkbox'>
                <div class='subheader5'>Question & Answer transcript</div>
              </label>
              <div class='collapse-contents qna_text'>
              ${qnaText}
              </div>
            </div>
          `;

          if (option.question_and_answer) {
            const qnaTippyWidget = document.createElement('div');
            qnaTippyWidget.classList.add('qna_tippy_widget');
            qnaTippyWidget.innerHTML = '?';
            qnaTippyWidget.addEventListener('click', (event) => {
              event.preventDefault();
              event.stopPropagation();
            })
            initialiseTippyOn({
              element: qnaTippyWidget,
              content: 'These are transcribed from the live class. They have been anonymised.',
              placement: 'top',
              theme: 'light',
              arrow: true,
              interactive: true,
              interactiveBorder: 10,
              interactiveDebounce: 0,
              delay: [500, 0],
              trigger: 'click',
              hideOnClick: true,
              onShow: () => {
                qnaTippyWidget.classList.add('active');
              },
              onHide: () => {
                qnaTippyWidget.classList.remove('active');
              }
            });
            optionContainer.querySelector('label').appendChild(qnaTippyWidget);
          }

          optionTabContainer.appendChild(optionTab);
          recordingsContainer.appendChild(optionContainer);

          /**
            Get the vimeo lecture and embed it
          */
          const lectureContainer = optionContainer.querySelector('div.lecture');
          const vimeo_url_parts = option.link_to_video.match(/(\d{9,10})\/(\w{10})/);
          const video_container = `
            <div class='vimeo' style='padding: 61% 0 0 0;position:relative;'>
              <iframe
                src='https://player.vimeo.com/video/${vimeo_url_parts[1]}?h=${vimeo_url_parts[2]}&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479/embed'
                allow='autoplay; fullscreen; picture-in-picture'
                allowfullscreen
                frameborder='0'
                style='position:absolute;top:0;left:0;width:100%;height:100%;'>
              </iframe>
            </div>
          `;
          lectureContainer.insertAdjacentHTML('afterbegin', video_container);


          /**
            Loop over and include guided meditation widgets
          */
          if (option.guided_meditations) {

            const guidedMeditationsList = optionContainer.querySelector('div#guided_meditation_list');
            option.guided_meditations.forEach(meditationSummaryData => {
              if (!meditationSummaryData) return;

              guidedMeditationsList.appendChild(
                createMeditationRow({
                  meditationSummaryData,
                  page: "recording",
                  showTaxonomies: false,
                })
              );
            })
          }
        });
      }


      /**
        Loop over and include extra guided meditations
      */
      if (module.extra_meditations.meditation_library_all.length) {
        const extraMeditations = document.createElement('div');
        extraMeditations.setAttribute('id', `extra_guided_meditations_w${module.week_number}`);
        extraMeditations.classList.add('meditation_list');
        extraMeditations.innerHTML = `
          <div class='subheader3'>Extra guided meditations</div>
          <div class='notes'>
            ${stripLeadingBrTag(module.extra_meditations.notes)}</div>
          <div class='meditation_list'></div>
        `;

        const extraMeditationList = extraMeditations.querySelector('div.meditation_list');
        module.extra_meditations.meditation_library_all.forEach(meditationSummaryData => {
          extraMeditationList.appendChild(
            createMeditationRow({
              meditationSummaryData,
              page: "recording",
              showTaxonomies: false,
            })
          );
        });

        moduleContainer.querySelector('div.module_contents').appendChild(extraMeditations);

      }

      return moduleContainer;
    });

    recordingsList.forEach((moduleContainer) => {
      recordingPaneBlock.querySelector("div.modules_list").appendChild(moduleContainer);
    });
  }
}

export function createRecordingPaneBlock(recording) {
  // console.log('createSPCPaneBlock()');
  // console.log('spc', spc);

  const eventType = `event_type_${recording.source.eventTypeShort.toLowerCase()}`;
  console.log('et', eventType);
  const recordingPaneBlock = document.createElement("div");
  recordingPaneBlock.classList.add("block", "recording_block", eventType);
  recordingPaneBlock.dataset["canAccess"] = String(recording.canAccess);
  recordingPaneBlock.innerHTML = `
    <div class='image_header'>
      <img src='${import.meta.env.VITE_HOST_URL}/${recording.source.featuredImage.image_url_medium_large}' />
    </div>

    <div class='recording_title'>${recording.source.title}</div>
    <div class='intro_description'>${recording.source.description ?? ""}</div>
    <div class='extra_data'>
      <div class='event_type'>${recording.source.eventType}</div>
    </div>

    <!--<div class='completion_bar_container'>
      <completion-bar></completion-bar>
    </div>-->

    <div class='modules_list'></div>
  `;

  return recordingPaneBlock;
}

/**
  If a block of Wordpress text has a leading <br> tag, we strip it out.
*/
export function stripLeadingBrTag(text) {
  if (text.startsWith('<br>')) {
    return text.substring(4);
  }
  return text;
}

import { slidePane } from "../ui_components/slide_pane.js";
import { printUpgradeButton } from "../ui_components/upgrade_button.js";
import { printTaxonomyPillsContainer } from "../ui_components/taxonomyPill";
import { icons } from "../ui_general/icons.ts";
import "../meditation/meditation_player.ts";
import { createMeditationPaneContents } from "./meditation_pane.js";
import { createMenuWidget } from "../ui_components/menu_widget.js";
import { membershipPill } from "../ui_components/membership_pill.js";
import { Meditation } from "../classes/meditation";

export function createMeditationRow({
  meditationSummaryData,
  page: page = "library",
  showTaxonomies = true,
  showMenu = true,
  size = "normal",
}) {
  const meditation = Meditation.getInstance({ meditationSummaryData: meditationSummaryData });
  // meditation.controller.addView(`bob ${meditation.meditationId}`);
  // console.log("createMeditationRow", meditation.title);
  // console.log("createMeditationRow", meditation.userStats);

  const row = createBlankRow(meditation, size);
  meditation.updateRowDataset(row);

  if (Boolean(meditation.userStats.completed) === true) {
    row.querySelector("iconify-icon.completed").classList.add("on");
  }
  if (Boolean(meditation.canAccess) === true) {
    attachMeditationRowEventListeners({ row, meditation });

    if (showMenu) {
      row
        .querySelector("div.controls")
        .append(createMenuWidget("meditation", meditation, row, "vertical"));
    }
  } else {
    row
      .querySelector("div.meditation_row_contents")
      .appendChild(printUpgradeButton(meditation));
  }

  if (size === "mini") {
    return row;
  }

  if (showTaxonomies) {
    const taxonomyPills = printTaxonomyPillsContainer({ row, postInstance: meditation });
    if (taxonomyPills) {
      row.querySelector("div.extra_data").appendChild(taxonomyPills);
    }

    // const spcPill = printSPCPill(row);
    // if (spcPill) {
    //   row.querySelector("div.extra_data").appendChild(spcPill);
    // }
  }

  // Journal
  const journalCount = document.createElement("div");
  journalCount.classList.add("journal_count");
  journalCount.innerHTML = `
    <iconify-icon noobserver icon='${icons.postType.journal}' class='journal'></iconify-icon>
    <div class='label'>Journal</div>
    <span class='count'>${meditation.journalEntries().length}</span>
  `;
  row.querySelector("div.side_data").appendChild(journalCount);
  journalCount.dataset.journalCount = String(meditation.journalEntries().length);

  if (meditation.journalEntries().length) {

    // always create it, but with no count property on the dataset. then display it conditionally with the css.
  }

  // Transcript
  if (meditation.transcript) {
    const transcript = document.createElement("div");
    transcript.classList.add("transcript");
    transcript.innerHTML = `
      <iconify-icon noobserver icon='${icons.postType.transcript}' class='journal'></iconify-icon>
      <div class='label'>Transcript</div>
    `;

    row.querySelector("div.side_data").appendChild(transcript);
  }

  // if we have both, then set them to be the mini-version of them so they fit
  if (meditation.journalEntries().length && meditation.transcript) {
    const minis = ["div.journal_count", "div.transcript"];
    minis.forEach((mini) => row.querySelector(mini).classList.add("mini"));
  }

  return row;
}

export function createBlankRow(postData, size) {
  const blankRow = document.createElement("div");
  blankRow.className = `meditation_row ${size}`;

  const dateRecorded = dateformat(postData.dateRecorded, "mid");

  blankRow.innerHTML = `
    <div class='meditation_row_contents'>
     	<div class='meditation_data'>

        <div class='audioTrigger play'>
          <iconify-icon noobserver icon='${icons.lotusBold}'></iconify-icon>
        </div>

        <div class='audioTrigger pause' style='display: none'>
          <iconify-icon noobserver icon='${icons.pause}'></iconify-icon>
        </div>

        <div class='title'>
          <iconify-icon noobserver icon='${icons.check.on}' class='completed'></iconify-icon>
          ${postData.title}
        </div>

        <div class='side_data'>
          <div class='duration'>${postData.duration} mins</div>

          <div class='controls'>
            <div class='source_spc pill' data-tippy-content='it works' style='display: none'>SPC</div>
          </div>

        </div>
      </div>

      <div class='extra_data'></div>
    </div>

    <div class='row_secondary_data'>
      ${membershipPill(postData.membershipLevel).outerHTML}
      <div class='star_rating'></div>
      <div class='up_down_vote_widget'></div>
      <div class='date_recorded'>${dateRecorded}</div>
    </div>
    `;
  return blankRow;
}

export function printSPCPill(row) {
  if (row.dataset.spc_id !== undefined) {
    const pillsContainer = document.createElement("div");
    pillsContainer.classList.add("pills_container");

    const spcPill = document.createElement("div");
    spcPill.classList.add("spc_pill");
    spcPill.innerHTML = `
      <div class='label'>Source</div>
      <div class='postType'>Self-Paced Course</div>
      <img class='spc_image' src='${import.meta.env.VITE_HOST_URL}/${row.dataset.spc_image_url_thumbnail}' />
      <div class='spc_title'>${row.dataset.spc_name}</div>
    `;
    pillsContainer.appendChild(spcPill);
    return pillsContainer;

    /*

    - [] enable this later when we can make individual calls to the REST API to get the spcPost to pass in.
    const spcId = row.dataset.postId;

    const spcPost = getSPCList(spcId) ?? restAPIRequest({spcId: spcId});

    spcPill.addEventListener("click", () => {
        linkToSPCPaneBlock(spcPost)
    });

    */
  }
}

export function setDatasetProperties(element, data) {
  // console.log('setDatasetProperties:', data)
  delete data.player;
  // delete data.transcript;

  Object.entries(data).forEach(([key, value]) => {
    // Convert key from kebab-case to camelCase for dataset compatibility
    const sanitizedKey = key.replace(/-([a-zA-Z])/g, (match, group1) =>
      group1.toUpperCase(),
    ); // Uppercase letters following dashes
    if (typeof value === "object" || Array.isArray(value)) {
      // console.log('Objvalue', key, value);
      // If value is an object, recursively set its properties.
      // Create a container for nested data if it doesn't exist.
      // const subElement = element.querySelector(`[data-id="${key}"]`) || element;
      // setDatasetProperties(subElement, value);
    } else if (sanitizedKey !== "transcript") {
      // console.log('stringvalue', key, value.substring(0, 50));
      // Ensure the value is converted to string
      const stringValue = String(value);
      // Assign the value to the dataset
      element.dataset[sanitizedKey] = stringValue;
    }

    // if (typeof value === 'string' && value.includes('[')) {
    //   element.dataset['skip'] = ''
    // }
  });
}

function attachMeditationRowEventListeners({ row, meditation }) {
  // Put meditation data such as the src url into a global meditation player object
  // that the web component can access.
  // spawn a pane
  row.addEventListener("click", () => {
    slidePane({
      direction: "vertical",
      element: createMeditationPaneContents({ row, meditation }),
      title: meditation.title,
      dataset: meditation,
      target: row,
    });
  });
}

window.createMeditationPlayer = function (meditationId, meditationInstance) {
  // console.log('window.createMeditationPlayer()', meditationId)
  const player = document.createElement("meditation-player");
  // const meditationData = window.meditationPlayerList[meditationId];

  player.initialize(meditationInstance);

  return player;
};

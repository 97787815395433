import { restAPIRequest } from "../restapi/restapi_requests";
import { icons } from "../ui_general/icons.ts";
import * as app from "../data/ar_appstate";
import { EventRecording } from "../classes/event_recording.ts";
import { createEventRecordingRow } from "../ui_components/event_recording_row.ts";
import {
  createFilterButton,
  createLibraryFilter,
} from "../ui_components/library_filter.js";
import { printTaxonomyPill } from "../taxonomies/print_taxonomy_pill.ts";


// export async function spcLibraryInit() {
//   // console.log('spcLibraryInit', 'token', app.userState.spcLibraryState, 'first init')

//   // Check if we've already synced from server at app-launch to avoid doing it again
//   if (app.userState.spcLibraryState && !app.state.spcLibraryInit) {
//     await window.Userdata.reconcileUserData("spc_data").then(() => {
//       app.state.spcLibraryInit = true;

//       uiGetSPCPosts();
//       uiUpdateStartedSPCListDOM();
//       updateSpcFavsListDOM();

//       /**
//         Select the saved tab
//       */
//       const savedTab = localStorage.getItem('AR_Userdata_Settings_spc_tab_current');
//       console.log('st', savedTab);
//       document.querySelector(`div.spc_filter_tab[data-target='${savedTab}']`).click();

//       document.addEventListener("loginEvent", function (event) {
//         uiGetSPCPosts();
//         uiUpdateStartedSPCListDOM();
//       });

//       document.addEventListener("logoutEvent", () => {
//         uiGetSPCPosts();
//         uiUpdateStartedSPCListDOM();

//         // Clear Favs
//         const subsectionFavourites = (document.querySelector(
//           "#spc_favs div.spc_list",
//         ).innerHTML = "");

//         // Clear Stared
//         const subsectionStarted = (document.querySelector(
//           "#spc_started div.spc_list",
//         ).innerHTML = "");
//       });
//     });
//   } else {
//     uiGetSPCPosts();
//   }
// }

export const sectionRecordings = document.createElement("section");
sectionRecordings.setAttribute("id", "event-recordings");
sectionRecordings.classList.add("membership", "hidden");
sectionRecordings.innerHTML = `
  <div class="container">

    <div class="section_header">
      <div class='header_wrap'>
        <iconify-icon noobserver icon='${icons.postType.recordings}'></iconify-icon>
        <div class='label'>Recordings</div>
      </div>
    </div>

    <div class="contents">
      <div id="recordings_main" class="block view_set current">


        <div id="recordings_ongoing" class='recordings_list_container' data-target='recordings_ongoing'>

          <div class="subheader">Ongoing Courses & Workshops</div>
          <div id="recordings_ongoing_list" class="recordings_list">
          </div>

        </div>

        <div id="recordings_past" class='recordings_list_container' data-target='recordings_past'>

          <div class="subheader">Previous & Finished</div>
          <div id="recordings_past_list" class="recordings_list">
          </div>

        </div>

        <div id="recording_list_prev_next_wrap" class='pagination_wrap' style='display: none'>
          <button-arrow direction="left" section="recording"></button-arrow>
          <div class="pageCount">
            <div class="count_widget" id='currentPage'></div>/
            <div class="count_widget" id='totalPages'></div>
          </div>
          <button-arrow direction="right" section="recording"></button-arrow>
        </div>
      </div>

    </div>
  </div>
  `;
// sectionRecordings.querySelector("div#recordings_main").append(createLibraryFilter);

// function displayEventRecordings

document.addEventListener("DOMContentLoaded", () => {
});

document.addEventListener("loginEvent", async function (event) {
  const recordings = await uiGetEventRecordingsPosts();
  const ongoing = recordings.posts.filter(recording => !recording.source.eventHasEnded);
  const past = recordings.posts.filter(recording => recording.source.eventHasEnded && !recording.access.areExpired);

  /**
    Display ongoing
  */
  const ongoingContainer = document.querySelector('div#recordings_ongoing_list');
  ongoing.forEach(recording => ongoingContainer.appendChild(
    createEventRecordingRow({ recording: recording })
    // todo: set it up like this - createEventRecordingRow({ recording: EventRecording.getInstance({ eventRecordingSummaryData: recording }) })
  ));

  /**
    Display past
  */
  const pastContainer = document.querySelector('div#recordings_past_list');
  past.forEach(recording => pastContainer.appendChild(
    createEventRecordingRow({ recording: recording })
  ));
});

document.addEventListener("logoutEvent", () => {
  // uiGetSPCPosts();
});

document.addEventListener("spcFavUpdateEvent", () => {
  // updateSpcFavsListDOM();
});

// document.addEventListener("spcLoadEvent", async (event) => {
//   if (app.userState.recordingLibraryState.isLoading) return;

//   const { direction } = event.detail;

//   app.userState.recordingLibraryState.isLoading = true;

//   if (direction === "left" && app.userState.recordingLibraryState.page > 1) {
//     app.userState.recordingLibraryState.page -= 1;
//   } else if (direction === "right") {
//     app.userState.recordingLibraryState.page += 1;
//   }

//   try {
//     // Invoke the network request and wait for it to complete
//     await uiGetSPCPosts({
//       page: app.userState.recordingLibraryState.page,
//     });
//   } catch (error) {
//     console.error("Failed to fetch SPC posts:", error);
//   } finally {
//     console.log("dispatch", app.userState.recordingLibraryState);
//     const loadCompleteEvent = new CustomEvent("restAPILoadComplete", {
//       bubbles: true,
//       composed: true,
//       detail: {
//         page: app.userState.recordingLibraryState.page,
//         totalPages: app.userState.recordingLibraryState.totalPages,
//         section: "spc",
//       },
//     });

//     document.dispatchEvent(loadCompleteEvent);
//     app.userState.recordingLibraryState.isLoading = false;

//     // Scroll to top
//     sectionRecordings.scrollTo(0, 0);
//   }
// });

// document.addEventListener("spcUpdatePlaycount", () =>
//   uiUpdateStartedSPCListDOM(),
// );

// const spcTabs = sectionRecordings.querySelectorAll('div.spc_filter_tab');
// const recordingsLists = sectionRecordings.querySelectorAll('div.spc_list_container')
// spcTabs.forEach(activeTab => {
//   activeTab.addEventListener('click', () => {
//     spcTabs.forEach(tab => tab.classList.remove('active'));
//     activeTab.classList.add('active');
//     localStorage.setItem('AR_Userdata_Settings_spc_tab_current', activeTab.dataset.target);

//     recordingsLists.forEach(list => list.classList.remove('active'));
//     sectionRecordings.querySelector(`div.spc_list_container[data-target=${activeTab.dataset.target}`).classList.add('active');
//   });
// })

// export function uiUpdateSPCForYouListDOM() {
//   // get pills
//   const container = document.querySelector('div#foryou_taxonomypills_container');
//   const spcForYouList = document.querySelector('div#spc_list_for_you');
//   const spcForYouCount = document.querySelector('div#foryou_explainer span.count');
//   let count = 0;

//   if (container && spcForYouList) {
//     container.innerHTML = "";
//     spcForYouList.innerHTML = "";
//   } else {
//     return;
//   }

//   const issues = window.Userdata.profileData?.psychology?.issueTargeted;
//   if (issues) {
//     Object.keys(issues).forEach(termSlug => {
//       container.appendChild(printTaxonomyPill(termSlug));
//     });

//     /**

//       Ideally, copy courses out of 'all', and filter them
//       get the actual data, then pass it through 'process posts'

//       For now, just copy the dom items. That'll only work so long as we get them all
//       in one request.

//     */

//     document.querySelectorAll('div#spc_list_all div.spc_row').forEach(spcRow => {
//       const spcInstance = SPC.instances.get(Number(spcRow.dataset['postId']));

//       if (spcInstance) {
//         const issueTargeted = JSON.parse(spcInstance.taxonomyList.issue_targeted);
//         const hasCommonTaxonomy = issueTargeted.some(key => key in issues);

//         if (hasCommonTaxonomy) {
//           const newRow = createSPCRow({ spc: spcInstance, showTaxonomies: true });
//           spcForYouList.appendChild(newRow);
//           spcInstance.updateCompletions(); // This needs to be called after SPCRows are added to the DOM. In the future it needs to be refactored so that it can access the completion-bars and update them directly.
//           spcForYouCount.innerText = count += 1;
//         }
//       }
//     });
//   }
// }

// window.uiUpdateSPCForYouListDOM = uiUpdateSPCForYouListDOM;

// function updateSpcFavsListDOM() {
//   // return;
//   const favsListDOM = sectionRecordings.querySelector("div#spc_favs_list");
//   const favs = window.Userdata.spcData.getFavourites();
//   processSPCPosts({ posts: favs, target: favsListDOM });
// }

export async function uiGetEventRecordingsPosts({
  page = app.userState.recordingLibraryState.page,
  postType = "event-recordings",
  postsPerPage = 5,
} = {}) {
  const config = { postType, page, postsPerPage };

  const recordingsMain = sectionRecordings.querySelector("div#recordings_main");
  const recordingsList = sectionRecordings.querySelector("div#recordings_ongoing_list");
  const recordingsPagination = sectionRecordings.querySelector("div.pagination_wrap");
  const loadSpinner = document.createElement("loading-spinner");

  if (recordingsMain && !recordingsMain.querySelector("loading-spinner")) {
    recordingsMain.appendChild(loadSpinner);
  }
  recordingsList.classList.add("global_blur");
  recordingsPagination.classList.add("global_blur");

  return restAPIRequest(config)
    .then((data) => {
      app.userState.recordingLibraryState.page = data.page;
      app.userState.recordingLibraryState.totalPages = data.totalPages;

      // processSPCPosts(data);

      document.dispatchEvent(
        new CustomEvent("restAPILoadComplete", {
          bubbles: true,
          composed: true,
          detail: {
            page: data.page,
            totalPages: data.totalPages,
            section: "recordings",
          },
        }),
      );

      return data;

      // const allCoursesCount = document.querySelector('div.spc_filter_tab[data-target=spc_all]').innerHTML = `All Courses <div class='count'>(${data.posts.length})</span>`;
    })
    .finally((data) => {
      app.userState.recordingLibraryState.isLoading = false;

      const loadingSpinner = recordingsMain.querySelector("loading-spinner");

      if (loadingSpinner) {
        recordingsMain.removeChild(loadingSpinner);
      }

      recordingsList.classList.remove("global_blur");
      recordingsPagination.classList.remove("global_blur");

      // uiUpdateSPCForYouListDOM();

    })
    .catch((error) => {
      console.error("uiGetEventRecordingsPosts()", error);
      recordingsList.innerHTML = "";

      const retryButton = document.createElement('button-std');
      retryButton.innerText = "Retry";
      retryButton.setAttribute('icon', icons.repeat);
      retryButton.addEventListener('click', () => {
        recordingsList.innerHTML = "";
        uiGetEventRecordingsPosts();
        // maybe make it also retry meditations?
      });

      const errorBox = document.createElement('div');
      errorBox.classList.add('error_box');
      errorBox.innerHTML = `
        <p>Couldn't load Courses</p>
      `;
      // todo: if network error, print it here.
      errorBox.appendChild(retryButton);

      recordingsList.appendChild(errorBox);
    });
}

// export function processSPCPosts({
//   posts: spcPosts,
//   target: target,
//   totalPages: totalPages,
//   page: page,
// } = {}) {
//   const recordingsList = sectionRecordings.querySelector("div#spc_list_all");
//   if (target === undefined) target = recordingsList;

//   // Update pages count widgets
//   /**
//     Check this... need a better way to handle it
//   */
//   if (document.querySelector("#spc_list_prev_next_wrap")) {
//     document.querySelector(
//       "#spc_list_prev_next_wrap div#totalPages",
//     ).innerText = totalPages || "";
//     document.querySelector(
//       "#spc_list_prev_next_wrap div#currentPage",
//     ).innerText = page || "";
//   }

//   if (spcPosts) {
//     target.innerHTML = "";
//     spcPosts.forEach((spcPost) => {
//       const spc = new SPC(spcPost);
//       target.appendChild(createSPCRow({ spc, showTaxonomies: true }));
//       spc.updateCompletions();
//     });
//   }
// }

// export function uiUpdateStartedSPCListDOM() {
//   const spcStartedListDOM = document.querySelector("div#spc_started_list");

//   if (spcStartedListDOM) {
//     const startedSPCs = window.Userdata.spcData.getListOfStartedSPCs();

//     if (startedSPCs.size) {
//       spcStartedListDOM.innerHTML = "";
//       startedSPCs.forEach((spc) => {
//         spcStartedListDOM.appendChild(createSPCRow({ spc, showTaxonomies: true }))
//       });
//     }
//   }
// }

/**
  Just to check it quickly and easily.
*/
window.recc = window.Userdata.eventRecordingData.usageData;

import { Meditation, MeditationSummaryData } from "./meditation";
import { MembershipLevel } from "../types/membership_level";
import { EntityId } from "../types/global";

export type SpcSummaryData = {
  postType: "spc";
  spcId: EntityId;
  title: string;
  summary: string | undefined;
  summarySentence: string | undefined;
  images: {
    spc_image_url_thumbnail: string,
    spc_image_url_medium: string,
    spc_image_url_full: string,
  };
  includedModules: Array<IncludedModules>;
  membershipLevel: MembershipLevel;
  taxonomyList: string[];
  canAccess: boolean;
  subtitle: string | undefined;
  spcType: string | undefined;
};

type Stats = {
  favourite: boolean;
  timestamp: string | undefined;
  completed: boolean;
  started: boolean;
  lastPlayed: string | undefined;
};

type IncludedModules = {
  module_title: string,
  description: string,
  files: Array<IncludedModuleFile>
}

type IncludedModuleFile = FileLecture | FileMeditation | FileDocument;

interface FileLecture extends IncludedModuleFileBase {
  acf_fc_layout: "lecture",
  title: string,
  duration: number | null,
  video_url: string | null,
  audio_url: string | null,
  ar_basics: ARBasics,
  format: {
    value: 'video' | 'audio',
    label: 'Video' | 'Audio'
  },
}

interface FileMeditation extends IncludedModuleFileBase {
  acf_fc_layout: "meditation",
  ar_basics: ARBasics,
  meditation_library_all: MeditationSummaryData,
}

interface FileDocument extends IncludedModuleFileBase {
  acf_fc_layout: "document",
  document_title: string,
  format: "PDF" | "Graphic",
}

type IncludedModuleFileBase = {
  acf_fc_layout: string,
}

type ARBasics = {
  value: boolean,
  label: 'True' | 'False'
}

export class SPC {
  /**
    Track all the instances and ensure there is only ever one of each
  */
  public static instances: Map<EntityId, SPC> = new Map();

  /**
    Instead of initialising this Class with `new` it should be initialised with this method. It returns a new instance if one doesn't exist.

    eg. const spc= SPC.getInstance(SpcSummaryData);
  */
  public static getInstance({
    spcSummaryData,
    spc_id,
  }: {
    spcSummaryData?: SpcSummaryData;
    spc_id?: EntityId;
  }): SPC | undefined {
    // Access an existing SPC by the ID
    if (spc_id) {
      if (SPC.instances.has(spc_id)) {
        return SPC.instances.get(spc_id);
      }
    }

    // Initialise new SPC or get one by the ID
    if (spcSummaryData) {
      const spcId: EntityId = String(spcSummaryData.spcId);

      if (SPC.instances.has(spcId)) {
        return SPC.instances.get(spcId)!;
      }

      const instance: SPC = new SPC(spcSummaryData);
      SPC.instances.set(String(spcId), instance);

      return instance;
    }
  }

  public userStats: Stats = {
    favourite: false,
    timestamp: undefined,
    completed: false,
    lastPlayed: undefined,
    started: false,
  };


  public readonly postType: "spc";
  public readonly spcId: EntityId;
  public readonly id: EntityId;
  public readonly title: string;
  public readonly summary: string | undefined;
  public readonly summarySentence: string | undefined
  public readonly images: {
    spc_image_url_thumbnail: string,
    spc_image_url_medium: string,
    spc_image_url_full: string,
  };
  public readonly includedModules: Array<IncludedModules>;
  public readonly membershipLevel: MembershipLevel;
  public readonly taxonomyList: string[];
  public readonly canAccess: boolean;
  public readonly subtitle: string | undefined;
  public readonly spcType: string | undefined;

  public readonly completionCount: number;
  public readonly meditationCount: number;

  private constructor(spcSummaryData: SpcSummaryData) {
    this.spcId = String(spcSummaryData.spcId);
    this.id = this.spcId;
    this.postType = spcSummaryData.postType;
    this.title = spcSummaryData.title;
    this.summary = spcSummaryData.summary;
    this.summarySentence = spcSummaryData.summarySentence;
    this.images = spcSummaryData.images;
    this.includedModules = spcSummaryData.includedModules;
    this.membershipLevel = spcSummaryData.membershipLevel;
    this.taxonomyList = spcSummaryData.taxonomyList;
    this.canAccess = spcSummaryData.canAccess;
    this.subtitle = spcSummaryData.subtitle;
    this.spcType = spcSummaryData.spcType;

    this.meditationCount = this.countIncludedMeditations();
    this.completionCount = this.countCompletedMeditations();

    this.initialiseSelf(spcSummaryData);
    this.updateCompletions();
  }

  private initialiseSelf(spcSummaryData: SpcSummaryData): void {
    SPC.instances.set(this.spcId, this);

    this.userStats =
      window.Userdata.spcData.usageData.get(this.spcId)?.userStats
      ?? this.userStats;

    this.userStats.started = Boolean(this.countCompletedMeditations());
  }

  toggleFavourite(newValue: boolean): boolean {
    this.userStats.favourite = newValue || !this.userStats.favourite;
    this.saveToUserUsageData();
    this.watchFavourite();

    return this.userStats.favourite;
  }

  watchFavourite(): void {
    document.dispatchEvent(
      new CustomEvent("spcFavUpdateEvent", {
        bubbles: true,
        composed: true,
      }),
    );

    document
      .querySelectorAll<HTMLElement>(`div.spc_row[data-spc-id='${this.spcId}']`)
      .forEach(spcRowElement => {
        spcRowElement.dataset.favourite = String(this.userStats.favourite);
      });
  }

  watchCompletions(): void {
    document.dispatchEvent(
      new CustomEvent("spcCompletionsUpdateEvent", {
        bubbles: true,
        composed: true,
      }),
    );

    document
      .querySelectorAll<HTMLElement>(`div.spc_row[data-spc-id='${this.spcId}']`)
      .forEach(spcRowElement => {
        spcRowElement.dataset.started = String(Boolean(this.completionCount));
      });
  }

  private instanceUpdated() {
    return (this.userStats.timestamp = new Date().toString());
  }

  public updateCompletions(): void {
    this.updateCompletionCounts();
    this.updateCompletionBars();
    this.watchCompletions();
  }

  public signalFromChildMeditation(): void {
    this.userStats.started = Boolean(this.countCompletedMeditations());
    this.updateCompletions();
    this.watchFavourite();
    this.watchCompletions();
    this.saveToUserUsageData();
  }

  public removeStarted(): void {
    this.userStats.started = false;
  }

  public updateCompletionCounts(): void {
    const completionBars = document.querySelectorAll(
      `div.pane_wrap[data-pane-id='${this.spcId}'] completion-bar,
      div.spc_row[data-spc-id='${this.spcId}'] completion-bar
      `,
    ) as NodeListOf<HTMLElement>;

    if (completionBars) {
      completionBars.forEach((completionBarComponent: HTMLElement) => {
        const counter = completionBarComponent.shadowRoot?.querySelector('div.count');
        if (counter instanceof HTMLElement) {
          counter.innerText = `${this.countCompletedMeditations()} / ${this.countIncludedMeditations()}`;
        }
      })
    }
  }

  public updateCompletionBars(): void {

    const completionBarElements = document.querySelectorAll(
      `div.pane_wrap[data-pane-id='${this.spcId}'] div.completion_bar_container completion-bar,
      div.spc_row[data-spc-id='${this.spcId}'] div.completion_bar_container completion-bar
      `,
    ) as NodeListOf<HTMLElement>;

    const completionPercentage = (this.countCompletedMeditations() / this.countIncludedMeditations()) * 100;

    if (completionBarElements) {
      completionBarElements.forEach((bar) => {
        bar.setAttribute('completion-percentage', String(completionPercentage));
      })
    }
  }

  countIncludedMeditations(): number {

    const filesCount = this.includedModules?.reduce(
      (acc, module) => {
        if (module.files) {
          module.files.forEach((file) => {
            if (file.acf_fc_layout === "lecture") {
              acc["lectures"] += 1;
            } else if (file.acf_fc_layout === "meditation") {
              acc["meditations"] += 1;
            }
          });
        }
        return acc;
      },
      { lectures: 0, meditations: 0 },
    );

    return filesCount.meditations;
  }

  countCompletedMeditations(): number {
    let completedCount: number = 0;

    this.includedModules.forEach(module => {
      module.files.forEach(file => {
        if (file['acf_fc_layout'] === 'meditation') {

          const meditationId: EntityId = String(file['meditation_library_all'].meditationId);
          const meditationInstance = window.Userdata.meditationData.usageData.get(meditationId);

          if (meditationInstance?.userStats.completed) {
            completedCount += 1;
          }

        }
      })
    });

    // console.log(`countCompletedMeditations(), ${completedCount}/${this.countIncludedMeditations()}`);

    return completedCount;


    // const spcPaneId = document
    //   .querySelector("div.pane_wrap.show[data-pane-type='spc']") as HTMLElement;

    // if (spcPaneId) {
    //   console.log('count hi')
    //   const spcId = String(spcPaneId.dataset.paneId);
    //   console.log("spc meditation-> notifyspc", spcId);
    //   if (!spcId) return 0;


    //   const completedCount = spcPaneId.querySelectorAll('div.meditation_row[data-completed=true]').length;

    //   console.log(`countCompletedMeditations(), ${completedCount}/${this.countIncludedMeditations()}`);
    //   console.log()
    //   return completedCount;
    // } else {
    //   return null;
    // }
  }

  saveToUserUsageData() {
    const { ...flatSPC }: SpcSummaryData = this;

    this.instanceUpdated();
    window.Userdata.spcData.update(flatSPC);
  }

  updatePlaycount() {
    /**
      To decide if to update the count or not, we check if the meditation has been restarted since the last time this was pressed.
     **/

    this.userStats.lastPlayed = new Date().toString();

    console.log("this.userStats.playcount");

    // this.saveToUserUsageData();

    document.dispatchEvent(
      new CustomEvent("spcUpdatePlaycount", {
        bubbles: true,
        composed: true,
        detail: {},
      }),
    );

    // this.emitChange();
  }


  unusedCountCompletedMeditations(): number {
    /**
      Search all instantiated meditations with this spcId for ones with .completed
      return the count
    */

    /**
      Ideally we could count like this, but we don't yet have it so that every meditation knows its source. So instead we shall just count the completed rows in the DOM, which will be accurate because every meditation knows itself if it has been completed.
    */
    const completedCount = [...Meditation.instances.values()].reduce(
      (acc, meditation): number => {
        if (String(meditation.sourceSpc?.spc_id) === String(this.spcId)) {
          if (meditation.userStats.completed) {
            return acc + 1;
          }
        }
        return acc;
      },
      0,
    );
    return completedCount;
  }

}

import { icons } from "./icons.ts";
import dateFormat from "dateformat";

/*
  args = {
    menu_items: [item1, item2, {
      label: required,
        type: optional / default: "menu_item",
      icon: optional,
      children: optional,
      }
    ]
    params (tippy): {
      position: undefined
      theme: undefined
      content:
    }
  }

*/

export class CreateMenu {
  constructor(menu, params) {
    this.menu_items = menu.menu_items.map((item) => {
      if (!item.enabled) return null;

      const children = "";
      const status = item.type || "";
      const label_short =
        "label_" + item.label.replace(/\s/g, "_").toLowerCase();
      let icon = "";
      let actions = "";

      // Build icons
      if (item.hasOwnProperty("icon")) {
        if (typeof item.icon === "string") {
          icon = `<iconify-icon noobserver icon='${item.icon}'></iconify-icon>`;
        } else if (typeof item.icon === "object") {
          icon = Object.entries(item.icon)
            .map(([key, val]) => {
              return `<iconify-icon noobserver icon='${val}' class='${key}'></iconify-icon>`;
            })
            .join("");
        }
      }

      // Build menuItem
      const menuItem = document.createElement("div");
      menuItem.classList.add("menu_item");
      menuItem.classList.add(status);
      menuItem.classList.add(label_short);
      menuItem.innerHTML = `
        <div class='menu_icon'>${icon}</div>
        <div class='label'>${item.label}</div>
      `;

      // Info Item
      if (item.type === "info_item") {
        menuItem.innerHTML += `<div class='value'></div>`;
      }

      // Build Actions
      // [] None have this implemented yet. These are things that trigger behaviour and more controls within the menu itself
      if (item.hasOwnProperty("actions")) {
        actions = item.actions
          .map((action) => {
            let type = item.actionType;
            return `<button type='button' class='${type}'>${action}</button>`;
          })
          .join("");

        if (item.actionType === "select") {
          actions = `<div class='buttonSet'>${actions}</div>`;
        } else if (item.actionType === "confirm") {
          actions = `<div class='buttonConfirm'>${actions}</div>`;
        } else if (item.actionType === "toggle") {
          actions = `<div class='buttonToggle'>${actions}</div>`;
        } else {
          actions = `<div class='actions'>${actions}</div>`;
        }
      }

      // Bind functions
      //  - one off functions that run immediately when the item is clicked
      // Do differently based on type toggle/confirm etc.
      if (item.hasOwnProperty("function")) {
        menuItem.addEventListener("click", (event) => {
          if (item.type === "toggle_item") {
            const toggle = item.function();
            menuItem.classList.toggle("on", toggle);
          }

          if (item.type === "action") {
            // console.log('menu', menu.dataset)
            item.function.action(menu.dataset);
          }
        });
      }

      // Set initial state
      if (item.type === "toggle_item") {
        let toggle;
        if (item.label === "Favourite") {
          toggle =
            item.data?.favourite ?? false;
        } else {
          toggle = item.data;
        }

        menuItem.classList.toggle("on", toggle);
      }

      // Playcount
      if (item.label === "Playcount") {
        const playCount = item.data ? item.data : "0";
        if (playCount) {
          menuItem.querySelector("div.label").innerText += `: ${playCount}`;
        }
      }

      // Last Played
      if (item.label === "Last Played") {
        const lastPlayed = item.data ? dateFormat(item.data, "mid") : "";
        if (lastPlayed) {
          menuItem.querySelector("div.label").innerText += `: ${lastPlayed}`;
        }
      }

      // Final Menu
      menuItem.innerHTML += `
        ${actions}
        ${children}
      `;

      return menuItem;
    });

    // Build final output menu
    const outputMenu = document.createElement("div");
    outputMenu.classList.add("menu");
    this.menu_items = this.menu_items.filter((item) => item !== null);
    this.menu_items.forEach((item) => outputMenu.append(item));
    delete this.menu_items;

    this.menu = outputMenu;
  }
}

export function buildSectionMenu({
  menuObj: menuObject = {},
  name = "",
  header = "",
}) {
  const htmlContainer = document.createElement("div");
  htmlContainer.classList.add("menu_items_container");
  htmlContainer.setAttribute("data-menu", `${name}Items`);
  htmlContainer.setAttribute("id", name);

  if (header) {
    htmlContainer.innerHTML = `
    <div class='subheader'>${header}</div>
    `;
  }

  const menuItemsList = Object.entries(menuObject)
    .filter(([, menuItem]) => menuItem.visible)
    .map((menu) => {
      const navIcon =
        menu[1].type === "link" ? icons.linkExternal : icons.arrowRight;

      const menuItem = document.createElement("div");
      menuItem.classList.add("list_item", `${menu[1].size}`);
      menuItem.dataset.menu = menu[0];
      menuItem.dataset.url = menu[1].url;
      menuItem.dataset.enabled = menu[1].enabled;
      menuItem.innerHTML = `
        <iconify-icon noobserver icon="${menu[1].icon}"></iconify-icon>
        <div class='label'>${menu[1].label}</div>
        <iconify-icon class='forward ${menu[1].type}' noobserver icon="${navIcon}"></iconify-icon>
      `;

      return menuItem;
    });

  menuItemsList.forEach((menuItem) => htmlContainer.appendChild(menuItem));

  return htmlContainer;
}

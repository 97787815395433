//@ts-ignore
import { linkToSPCPaneBlock } from "../blocks/spc_view_block";
import { printTaxonomyPillsContainer } from "../ui_components/taxonomyPill";
//@ts-ignore
import { membershipPill } from "./membership_pill";
//@ts-ignore
import { SPC, SpcSummaryData } from "../classes/spc.ts";
//@ts-ignore
import { uiUpdateStartedSPCListDOM } from "../sections/section_courses";
import { icons } from "../ui_general/icons";

export function createSPCRow({ spc, showTaxonomies = true }: { spc: SPC, showTaxonomies: boolean }) {
  // console.log('createSPCRow:' spc.title);
  const spcInstance = SPC.getInstance({ spcSummaryData: spc });

  const spcRow: HTMLElement = document.createElement("div");
  spcRow.classList.add("spc_row", "horizontal", "grid");
  spcRow.dataset["canAccess"] = String(spc.canAccess);
  spcRow.dataset["spcId"] = String(spc.spcId);
  spcRow.dataset["spcType"] = String(spc.spcType);
  spcRow.dataset['started'] = String(spc.userStats.started);
  spcRow.dataset["membershipLevel"] = String(spc.membershipLevel.value);

  const filesSummary = document.createElement("div");
  filesSummary.classList.add("files_summary");

  if (spc.includedModules) {
    const filesCount = spc.includedModules.reduce(
      (acc, module) => {
        if (module.files) {
          module.files.forEach((file) => {
            if (file.acf_fc_layout === "lecture") {
              acc["lectures"] += 1;
            } else if (file.acf_fc_layout === "meditation") {
              acc["meditations"] += 1;
            }
          });
        }
        return acc;
      },
      { lectures: 0, meditations: 0 },
    );

    spcRow.dataset["filesLectures"] = String(filesCount["lectures"]);
    spcRow.dataset["filesMeditations"] = String(filesCount["meditations"]);

    const lecturesPill = filesCount["lectures"]
      ? `<div class='files_pill lecture_count'>${filesCount["lectures"]} ${filesCount["lectures"] > 1 ? "Lectures" : "Lecture"}</div>`
      : "";
    const meditationsPill = filesCount["meditations"]
      ? `<div class='files_pill meditation_count'>${filesCount["meditations"]} ${filesCount["meditations"] > 1 ? "Meditations" : "Meditation"}</div>`
      : "";

    filesSummary.insertAdjacentHTML("beforeend", lecturesPill);
    filesSummary.insertAdjacentHTML("beforeend", meditationsPill);
    // console.log('filesCount', filesCount)
  }

  spcRow.innerHTML = `
    <img class='featured' src='${import.meta.env.VITE_HOST_URL}/${spc.images.spc_image_url_full}' />
    <div class='title'>${spc.title}</div>
    <div class='extra_data'></div>
    `;

  if (showTaxonomies) {
    // console.log('started show tax');
    const taxonomyPills = printTaxonomyPillsContainer({ postInstance: spc });
    // console.log('started pill', taxonomyPills)
    if (taxonomyPills) {
      spcRow.querySelector("div.extra_data")?.appendChild(taxonomyPills);
    }
  }

  /**
    Favourites
  */
  const isFavourite =
    window.Userdata.spcData.usageData.get(spc.spcId)?.userStats.favourite ??
    false;
  spcRow.dataset["favourite"] = String(isFavourite);

  // if (isFavourite) {
  //   /**

  //     Generate a bookmark icon and append it to the DOM.
  //     Can't really use it until we get proper reactive rows that update in real time.

  //   */
  //   const favouriteIcon = `<iconify-icon noobserver class='noaccess favourite' icon="${icons.fav.on}"></iconify-icon>`;
  //   spcRow.innerHTML += favouriteIcon;
  // }

  spcRow.append(filesSummary);
  spcRow.append(membershipPill(spc.membershipLevel));

  spcRow.insertAdjacentHTML('beforeend', `
    <div class='completion_bar_container'>
      <completion-bar></completion-bar>
    </div>
  `);

  spcRow.innerHTML += `
    <div class="close"><iconify-icon icon='${icons.close}'></iconify-icon></div>
    `;
  spcRow.querySelector('div.close')?.addEventListener('click', (event) => {
    event.stopPropagation();
    if (spcInstance) {
      spcInstance?.removeStarted();
      uiUpdateStartedSPCListDOM();
    }
  })

  spcRow.addEventListener("click", () => {
    linkToSPCPaneBlock(spc);
  });

  return spcRow;
}

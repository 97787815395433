import { Meditation } from "../classes/meditation";
import { SPC } from "../classes/spc";
import { printTaxonomyPill } from "../taxonomies/print_taxonomy_pill.ts";
import { ARPostType } from "../types/global.js";
import * as app from "../data/ar_appstate.js"

export function printTaxonomyPillsContainer({ postInstance }: { postInstance: ARPostType }) {
  if (
    !postInstance.taxonomyList ||
    Object.values(postInstance.taxonomyList).length < 1
  ) return;

  const taxonomySet = (() => {
    const postType = postInstance?.postType;
    const taxonomies = app.info[postType].taxonomies || null;

    return taxonomies ? {
      enabled: app.info.enabledTaxonomies(postType),
      info: taxonomies,
    } : null
  })();
  if (!taxonomySet || !taxonomySet.enabled?.length) return;

  // Print taxonomy labels into this pillsContainer
  // [] refactor into a function that returns a pills container
  const pillsContainer = document.createElement("div");
  pillsContainer.classList.add("pills_container");

  taxonomySet.enabled.forEach((taxonomySlug: string) => {
    const taxonomyTerms = JSON.parse(postInstance.taxonomyList[taxonomySlug]) ?? [];

    if (taxonomyTerms) {
      const termBubbles = taxonomyTerms
        .map((termSlug: string) => printTaxonomyPill(termSlug, taxonomySlug).outerHTML)
        .join("");

      if (termBubbles.length) {
        const taxonomyContainer = document.createElement("div");
        taxonomyContainer.classList.add("taxonomy_container");
        taxonomyContainer.innerHTML = `
          <div class='label'>${taxonomySet.info[taxonomySlug].nameShort}</div>
          <div class='terms_container'>${termBubbles}</div>
        `;
        pillsContainer.appendChild(taxonomyContainer);
      }
    }
  });

  if (pillsContainer.children.length > 0) return pillsContainer;
}

import { terms_issue_targeted } from "../taxonomies/issue_targeted";
import { uiUpdateSPCForYouListDOM } from "../sections/section_courses";

export const issueTargetedBlock = document.createElement("div");
issueTargetedBlock.setAttribute('id', "issue_targeted_block");
issueTargetedBlock.classList.add('block');
issueTargetedBlock.innerHTML = `
  <div class='block'>
    <div class='subheader'>Issues</div>
    <p>Select which issues you want to work on</p>
  </div>
  `;

const issueTargetedForm = document.createElement("form");
issueTargetedForm.setAttribute("id", "issue_targeted_form");
issueTargetedForm.innerHTML = `
  <fieldset id='issue_targeted_fieldset'></fieldset>
  `;
issueTargetedBlock.querySelector('div.block').appendChild(issueTargetedForm);

/**

  Print Checkboxes for the issues
  todo: Make this run after they have been gotten from the RESTAPI...

*/
if (terms_issue_targeted) {
  terms_issue_targeted.forEach((issueObj) => {
    if (issueObj.name === "Any" || issueObj.slug === "") return;

    const issueHTML = document.createElement("label");
    issueHTML.setAttribute("for", issueObj.slug);
    issueHTML.innerHTML = `
      <div class='label'>${issueObj.name}</div>
    `;

    const input = document.createElement("input");
    input.setAttribute("type", "checkbox");
    input.setAttribute("id", issueObj.slug);
    input.addEventListener("change", (event) => {
      console.log("input change", event);
      const issueTargeted = window.Userdata.profileData?.psychology.issueTargeted;
      if (!issueTargeted) return;

      /**
        Store in Userdata
      */

      event.target.checked
        ? issueTargeted[event.target.id] = true
        : delete issueTargeted[event.target.id];

      // This I'm sure should be centralied properly into a method in the Userdata object that handles adding new data.
      window.Userdata.profileData.lastUpdated = new Date().toString();
      window.Usermethods.saveToLS('profileData', window.Userdata.profileData);

      /**
        Update all representations...
        This should be handled better elsewhere but works here for now
      */
      uiUpdateSPCForYouListDOM();
    });

    issueHTML.appendChild(input);
    issueTargetedForm.querySelector("fieldset").appendChild(issueHTML);
  });
}

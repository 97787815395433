import { icons } from "../ui_general/icons.ts";

export const mainMenu = document.createElement("div");
mainMenu.setAttribute("id", "membership_menu");
mainMenu.innerHTML = `
  <div id="sync_widget" style='display: none'>
    <iconify-icon noobserver sync icon="${icons.arrowsRotate}"></iconify-icon>
    <iconify-icon noobserver wait icon="${icons.abstract}"></iconify-icon>
  </div>

  <div class="membership_menu_contents">

    <div class="member_label">
      <iconify-icon noobserver icon="${icons.profile}"></iconify-icon>
      <div class="label">Alice</div>
    </div>

    <div class="tab" data-membership="home">
      <iconify-icon noobserver icon="${icons.home}"></iconify-icon>
      <div class="label">Home</div>
    </div>

    <div class="tab" data-membership="profile">
      <iconify-icon noobserver icon="${icons.profile}"></iconify-icon>
      <div class="label">Profile</div>
    </div>

    <div class="tab" data-membership="meditation">
      <iconify-icon noobserver icon="${icons.postType.meditation}"></iconify-icon>
      <div class="label">Meditation</div>
    </div>

    <div class="tab" data-membership="courses">
      <iconify-icon noobserver icon="${icons.postType.courses}"></iconify-icon>
      <div class="label">Courses</div>
    </div>

    <div class="tab" data-membership="event-recordings">
      <iconify-icon noobserver icon="${icons.postType.recordings}"></iconify-icon>
      <div class="label">Recordings</div>
    </div>

    <div class="tab" data-membership="journal" style="dispay: none;">
      <iconify-icon noobserver icon="${icons.postType.journal}"></iconify-icon>
      <div class="label">Journal</div>
    </div>

    <div class="tab selected" data-membership="chatbot" style="display: none;">
      <iconify-icon noobserver icon="${icons.postType.chat}"></iconify-icon>
      <div class="label">Chat</div>
    </div>


    <div class="tab" data-membership="assessment" style="display: none">
      <iconify-icon noobserver icon="${icons.postType.assessment}"></iconify-icon>
      <div class="label">Assessment</div>
    </div>

    <div class="tab" data-membership="settings" style="display: none">
      <iconify-icon noobserver icon="${icons.settings}"></iconify-icon>
      <div class="label">Settings</div>
    </div>

    <div class="button_set">
      <div class="button settings" data-membership="settings">
        <iconify-icon noobserver icon="${icons.settings}"></iconify-icon>
        <div class="label" style="display: none">Settings</div>
      </div>
      <div class="button sync">
        <iconify-icon noobserver icon="${icons.sync}"></iconify-icon>
      </div>
      <div class="button notifications">
        <iconify-icon noobserver icon="${icons.bell}"></iconify-icon>
      </div>
      <div class="button logout">
        <iconify-icon noobserver icon="${icons.logout}"></iconify-icon>
      </div>
    </div>
  </div>
`;

// when clicked, it simply toggles the "selected" value on all tabs,
// and on their respective <section> in the DOM.

mainMenu.querySelectorAll("div.tab:not(.disabled)").forEach((tab) => {
  tab.addEventListener("click", (event) => {
    const sectionName = event.currentTarget.getAttribute("data-membership");

    // hide other sections
    mainMenu
      .querySelectorAll("div.tab.selected")
      .forEach((tab) => tab.classList.remove("selected"));
    document
      .querySelectorAll("section.membership")
      .forEach((section) => section.classList.add("hidden"));

    // show selected section
    event.currentTarget.classList.toggle("selected");
    document
      .querySelectorAll(`section.membership[id='${sectionName}']`)
      .forEach((section) => section.classList.remove("hidden"));

    // append anchor to URL
    window.history.pushState("", "", `#${sectionName}`);

    // Notify section that we are switching to it
    notifySwitchToSection();
  });
});

export function notifySwitchToSection() {
  const currentSectionName = document
    .querySelector("section.membership:not(.hidden)")
    ?.getAttribute("id");
  document.dispatchEvent(new CustomEvent(`section_${currentSectionName}`));
}

document.addEventListener("DOMContentLoaded", () => {
  // Load initial tab based on URL parameter
});

export const icons = {
  fav: {
    on: "iconamoon:bookmark-fill",
    off: "iconamoon:bookmark-duotone",
  },
  check: {
    on: "iconamoon:check-circle-1-fill",
    off: "iconamoon:check-circle-1",
  },
  abstract: "eos-icons:abstract",
  lightning: "ph:lightning",
  lotus: "lucide-lab:flower-lotus",
  lotusBold: "ph:flower-lotus-bold",
  account: "iconamoon:profile-circle-fill",
  bin: "iconamoon:trash-duotone",
  profile: "iconamoon:profile-circle-fill",
  linkExternal: "iconamoon:link-external-bold",
  arrowRight: "iconamoon:arrow-right-2-bold",
  arrowLeft: "iconamoon:arrow-left-2-bold",
  arrowLeft2: "mingcute:left-fill",
  arrowLeftSmall: "iconamoon:arrow-left-2-fill",
  arrowDownSmall: "iconamoon:arrow-down-2-fill",
  arrowDown2: "iconamoon:arrow-down-2-bold",
  arrowDownCircleFill: "iconamoon:arrow-down-6-circle-fill",
  arrowDownCircleDuo: "iconamoon:arrow-down-6-circle-duotone",
  arrowDownCircleBold: "iconamoon:arrow-down-6-circle-bold",
  arrowsRotate: "eos-icons:arrow-rotate",
  assessments: "iconamoon:cheque-fill",
  membership: "iconamoon:star-duotone",
  timestampList: "uim:list-ul",
  lastPlayed: "mingcute:time-fill",
  home: "iconamoon:home",
  settings: "iconamoon:settings-fill",
  newsletter: "uil:newspaper",
  tiktok: "tabler:brand-tiktok",
  youtube: "tabler:brand-youtube",
  facebook: "tabler:brand-facebook",
  reddit: "tabler:brand-reddit",
  padlock: "iconamoon:lock-duotone",
  close: "iconamoon:close-bold",
  play: "mingcute:play-fill",
  menuDots: "iconamoon:menu-kebab-vertical-bold",
  pause: "mingcute:pause-fill",
  loading: "mingcute:loading-fill",
  cloudError: "iconamoon:cloud-no",
  volume: "f7:speaker-2-fill",
  login: "material-symbols:login-rounded",
  logout: "material-symbols:logout-rounded",
  register: "line-md:account-add",
  bell: "tabler:bell-filled",
  sync: "uil:sync",
  clock: "iconamoon:clock-duotone",
  search: "iconamoon:search-duotone",
  sendMessage: "lets-icons:send",
  chatBubble: "basil:chat-solid",
  sortingLeft: "iconamoon:sorting-left-bold",
  plus: "iconamoon:sign-plus-bold",
  repeat: "ph:repeat-bold",
  document: "iconamoon:file-document",
  postType: {
    meditation: "solar:meditation-round-outline",
    courses: "iconamoon:folder-video",
    recordings: "fluent:video-recording-20-regular",
    chat: "heroicons-outline:chat",
    journal: "solar:document-add-outline",
    assessment: "solar:chart-2-bold-duotone",
    transcript: "lets-icons:paper-fill",
  },
};

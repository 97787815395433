import { CSSSelector } from "../types/global";

const listOfContainersThatCanBeEmpty: CSSSelector[] = [
  "div.meditation_list",
  "div.spc_list",
  "div.journal_list",
];

const createEmptyNotice = (): HTMLElement => {
  const emptyNotice = document.createElement('div');
  emptyNotice.classList.add('empty_nothing_yet');
  emptyNotice.innerText = 'Nothing yet';
  return emptyNotice;
};

const shouldCheckElement = (element: HTMLElement): boolean => {
  // Skip elements with journal_list class and meditation_journal_previous id
  if (element.classList.contains('journal_list') &&
    element.id === 'meditation_journal_previous') {
    return false;
  }
  return true;
};

const checkIfEmpty = (element: HTMLElement) => {
  // Skip checking for elements that should be excluded
  if (!shouldCheckElement(element)) {
    return;
  }

  // Remove existing empty notices to prevent duplicates
  element.querySelector('div.empty_nothing_yet')?.remove();

  if (element.children.length === 0) {
    element.appendChild(createEmptyNotice());
  }
};

const contentCallback: MutationCallback = function (mutations: MutationRecord[]) {
  mutations.forEach((mutation: MutationRecord) => {
    const targetIsElement = mutation.target instanceof Element;
    const addedNodeIsElement = mutation.addedNodes[0] instanceof Element;

    if (!(targetIsElement && mutation.target.classList.contains('empty_nothing_yet')) &&
      !(addedNodeIsElement && (mutation.addedNodes[0] as Element).classList.contains('empty_nothing_yet'))) {
      const element = mutation.target as HTMLElement;
      checkIfEmpty(element);
    }
  });
};

// Watch for when our target containers appear in the DOM
const bodyObserver = new MutationObserver((mutations) => {
  mutations.forEach(() => {
    listOfContainersThatCanBeEmpty.forEach(selector => {
      const containers = document.querySelectorAll(selector);

      containers.forEach(containerElement => {
        // Skip adding observers to excluded elements
        if (!shouldCheckElement(containerElement as HTMLElement)) {
          return;
        }

        if (!containerElement.hasAttribute('data-observed')) {
          const observer = new MutationObserver(contentCallback);
          observer.observe(containerElement, {
            childList: true,
            subtree: false,
          });
          containerElement.setAttribute('data-observed', 'true');
          checkIfEmpty(containerElement as HTMLElement);
        }
      });
    });
  });
});

bodyObserver.observe(document.body, {
  childList: true,
  subtree: true
});

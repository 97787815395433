import * as tippy from "../ui_general/tippy.ts";
import { icons } from "../ui_general/icons.ts";
import * as app from "../data/ar_appstate";
import { terms_issue_targeted } from "../taxonomies/issue_targeted";
import { terms_purpose } from "../taxonomies/purpose";
import { terms_techniques } from "../taxonomies/techniques";
import { uiGetSPCPosts } from "../sections/section_courses.js";
import { uiGetMeditationPosts, applyCSSHighlight } from "../meditation/meditation_functions.js";

export const filterPostType = {
  meditation: {
    uiGet: uiGetMeditationPosts,
    state: app.userState.spcLibraryState,
    enabledFilters: app.info.enabledFilters('meditation'),
  },
  spc: {
    uiGet: uiGetSPCPosts,
    state: app.userState.spcLibraryState,
    enabledFilters: app.info.enabledFilters('spc'),
  },
};

export const createLibraryFilter = (postSlug, filterButton) => {
  console.log('createLibraryFilter', postSlug);
  const libraryFilter = document.createElement("div");
  libraryFilter.setAttribute("id", "libraryFilter")
  libraryFilter.dataset.postSlug = String(postSlug);
  libraryFilter.innerHTML = `
    <div class="taxonomy_filters">

      <div class="filter_container" style="display:none">
        <div class="filter_widget">
          <div class="filter_name">Date Recorded</div>
          <!-- toggle switch before/after -->
          <!-- date input field calendar -->
          <div class="radio_selector">
            <input type="radio" id="before" name="date_selector" value="before">
            <label for="before">Before</label>
            <input type="radio" id="after" name="date_selector" value="after">
            <label for="after">After</label>
          </div>
          <input type="date">
        </div>
      </div>

      <div class="filter_container" style="display:none">
        <div class="filter_header">Online-Event</div>
      </div>

      <div class="filter_container" style="display:none">
        <div class="filter_header">Self-Paced Course</div>
      </div>

      <div class="filter_container" style="display:none">
        <div class="filter_header">Attachment Style</div>
      </div>

      <div class="filter_container" style="display:none">
        <div class="filter_header">Schema</div>
      </div>

      <div class='button_wrap'>

        <button-std size='normal' id='clear_filter' type='clear' icon='iconamoon:unavailable-bold'></button-std>

        <button-std size='normal' id='apply_filter'icon='iconamoon:funnel-fill' >Apply</button-std>

      </div>

    </div>
  `;

  if (postSlug === 'meditation') {
    const durationForm = `
      <div class="filter_group" data-filter-term="duration">
        <div class="filter_name">Duration</div>
          <fieldset id='taxonomy_duration'>
            <label data-filter-term-duration='any'>
              <input type='radio' name='duration' value='' checked />
              <div class='label'>Any</div>
            </label>
            <label data-filter-term-duration='10'>
              <input type='radio' name='duration' value='10' />
              <div class='label'>~ 10 mins</div>
            </label>
            <label data-filter-term-duration='20'>
              <input type='radio' name='duration' value='20' />
              <div class='label'>20 mins</div>
            </label>
            <label data-filter-term-duration='30'>
              <input type='radio' name='duration' value='30' />
              <div class='label'>+ 30 mins</div>
            </label>
          </fieldset>
        </div>
      </div>
    `;
    libraryFilter.querySelector('div.taxonomy_filters').insertAdjacentHTML('afterbegin', durationForm);
  }

  // Apply All button
  libraryFilter
    .querySelector("button-std#apply_filter")
    .addEventListener(
      "click",
      async function applyLibraryFilter(event) {

        console.log('applyRESTFilter', postSlug, libraryFilter)
        console.log('apply filter settings', getLibraryFilterSettings(postSlug, libraryFilter))
        // close the tippy popup
        filterButton._tippy.hide();

        try {
          // Invoke the network request and wait for it to complete
          await filterPostType[postSlug].uiGet.call(null, {
            page: 1,
            ...getLibraryFilterSettings(postSlug, libraryFilter),
          });

          // ButtonArrow.updateAllButtons();
        } catch (error) {
          console.error(`libraryFilter failed to fetch ${postSlug} posts:`, error);
        } finally {
        }

        // Save state into local storage (only after user confirms with Apply button)
        filterPostType[postSlug].enabledFilters.forEach((taxonomyName) => {
          window.Userdata.setSettings(
            `${postSlug}_libraryFilter_${taxonomyName}`,
            libraryFilter[taxonomyName],
          );
        });
      },
    );

  // Clear All button
  libraryFilter
    .querySelector("button-std#clear_filter")
    .addEventListener("click", () => clearRESTFilter(postSlug, libraryFilter));

  // Build out the input forms
  // 1. Loop over filterPostType[postSlug].enabledFilters
  // 2. Check the type, build a form depending on the type
  // 3. Append result into libraryFilter.querySelector("div.container)
  filterPostType[postSlug].enabledFilters.reverse().forEach((taxonomyName) => {
    const filterTerm = app.info[postSlug].filters[taxonomyName];

    const formContainer = document.createElement("div");
    formContainer.classList.add("filter_group", "topicselector");
    formContainer.dataset.filterTerm = taxonomyName;

    // Filter input header text
    formContainer.innerHTML = `<div class="filter_name">${filterTerm.name}</div>`;

    // Build one or another type of form depending on the taxonomy
    const elementType = filterTerm.elementType;

    if (elementType === "input") {
      if (filterTerm.inputType === "radio") {
        buildFilterFormRadio(taxonomyName).forEach((input) => {
          formContainer.appendChild(input);
        });
      } else if (filterTerm.inputType === "text") {
        formContainer.appendChild(buildFilterFormInput(filterTerm));
      }
    } else if (elementType === "select") {
      formContainer.appendChild(buildFilterFormSelect(taxonomyName));
    }

    if (taxonomyName !== "duration") {
      libraryFilter
        .querySelector("div.taxonomy_filters")
        .insertAdjacentElement("afterbegin", formContainer);
    }
  });

  // Map Library Filter UI Controls to internal state of the libraryFilter object
  filterPostType[postSlug].enabledFilters.forEach((taxonomyName) => {
    const filterTerm = app.info[postSlug].filters[taxonomyName];
    const elementType = filterTerm.elementType;

    const queryString = `div.filter_group[data-filter-term='${taxonomyName}'] ${elementType}[name='${taxonomyName}']`;
    const filterGroups = libraryFilter.querySelectorAll(queryString);
    // console.log('each', taxonomyName, elementType, filterGroups)

    filterGroups.forEach((filterGroup) => {
      if (filterTerm.inputType === "input[type='text']") {
        filterGroup.addEventListener("input", (event) => {
          libraryFilter[taxonomyName] = event.target.value;
        });
      } else {
        filterGroup.addEventListener("change", (event) => {
          libraryFilter[taxonomyName] = event.target.value;
        });
      }
    });
  });

  /**
    Refactor these get/set functions to operate depending on the input field type (text/select/radio etc) so they're more generalisable. Then make them auto-set themselves up based on whether the field is enabled or not.
  */

  // Library Filter Getters/Setters

  // loop over each input field type. run a block depending on the type. extract commonalities to run first, then type-specific code to run after inside a switch statement.
  filterPostType[postSlug].enabledFilters.forEach(filter => {
    // Define property accessors
    const filterGroup = app.info[postSlug].filters[filter];
    // console.log('filter', filter, filterGroup)
    if (filterGroup?.input?.includes('input')) {

      Object.defineProperty(libraryFilter, filter, {
        get: function () {
          switch (filterGroup.input) {
            case "input[type='text']":
            case "input[type='radio']":
              {
                const checked = filterGroup.input === "input[type='radio']"
                  ? ':checked'
                  : '';
                const input = this.querySelector(`div.filter_group[data-filter-term=${filterGroup.slug}] input${checked}`);

                return input?.value || "";
              }
            case "select":
              {
              }
          }
        },

        set: function (newValue = "") {
          console.log('checkFiler run update')
          updateStatusDot(postSlug);
          switch (filterGroup.input) {
            case "input[type='radio']": {
              {
                const input = this.querySelector(
                  `div.filter_group[data-filter-term=${filterGroup.slug}] input[value='${newValue}']`,
                );
                if (input) {
                  input.checked = true;
                }
              }
            }
            case "input[type='text']":
              {
                const input = this.querySelector(
                  `div.filter_group[data-filter-term=${filterGroup.slug}] input`,
                );
                if (input) {
                  input.value = newValue;
                }
              }
            case "select":
              {
              }
            default:
              break;
          }
        }
      })
    }
  });

  Object.defineProperty(libraryFilter, "techniques", {
    get: function () {
      const techniquesSelect = this.querySelector(
        "div.filter_group[data-filter-term=techniques] select",
      );
      const techniquesSelected =
        techniquesSelect.options[techniquesSelect.selectedIndex];
      const techniquesValue =
        techniquesSelected.value !== "any" ? techniquesSelected.value : "";
      return techniquesSelected ? techniquesValue : "";
    },
    set: function (newValue) {
      const input = this.querySelector(
        `div.filter_group[data-filter-term=techniques] select`,
      );
      if (input) {
        input.value = newValue;
      }
      updateStatusDot(postSlug);
    },
  });

  Object.defineProperty(libraryFilter, "issue_targeted", {
    get: function () {
      const issueSelect = this.querySelector(
        "div.filter_group[data-filter-term=issue_targeted] select",
      );
      const issueSelected =
        issueSelect.options[issueSelect.selectedIndex] ?? issueSelect.options[0];

      if (issueSelected) {
        const issueValue =
          issueSelected?.value !== "any" ? issueSelected.value : "";
        return issueSelected ? issueValue : "";
      } else {
        return "";
      }
    },
    set: function (newValue) {
      const input = this.querySelector(
        `div.filter_group[data-filter-term=issue_targeted] select`,
      );
      if (input) {
        input.value = newValue;
      }
      updateStatusDot(postSlug);
    },
  });


  function clearRESTFilter(postSlug = "meditation", libraryFilter) {

    // Clear DOM filter sheet
    filterPostType[postSlug].enabledFilters.forEach(
      taxonomyName => libraryFilter[taxonomyName] = "",
    );

    // Remove CSS Highlight sheets
    document
      .querySelectorAll(`style[title=${postSlug}LibraryFilterHighlights]`)
      .forEach(sheet => sheet.remove())

    // Reset Status dot
    updateStatusDot(postSlug);
  }

  function updateStatusDot(postSlug) {
    const statusDot = document.querySelector(`section#${postSlug} div#${postSlug}_library_filter_button.action_button div.status_dot`);
    const checkFilter = Object.entries(getLibraryFilterSettings(postSlug))
      .some(([_, value]) => value);
    console.log('checkFilter', checkFilter)

    statusDot?.classList.toggle("active", checkFilter);
  }

  function termRadioObj(
    label = "Any",
    slug = label.toLowerCase().replace(" ", "-"),
    description = ""
  ) {
    return {
      name: label,
      slug: slug,
      description: description,
      default: label === "Any" ? "checked" : "",
    };
  }

  function buildFilterFormSelect(taxonomyName) {
    // Setup selectWrap
    const selectWrap = document.createElement("div");
    selectWrap.classList.add("select_wrap");
    selectWrap.innerHTML = `
      <select name="${taxonomyName}" id="${taxonomyName}"></select>
    `;

    let tempTerms;
    switch (taxonomyName) {
      case "issue_targeted":
        tempTerms = terms_issue_targeted;
        break;
      case "techniques":
        tempTerms = terms_techniques;
        break;
    }

    // Generate tempTerms quickly:
    // `jq '[[[.[] | {name, slug, count, description}]]]' terms.json`

    if (!tempTerms) return selectWrap;

    tempTerms.unshift(termRadioObj("Any"));

    // Loop over each item, build out...
    tempTerms.forEach((term) => {
      if (term.count === 0) return;
      const selected = term.name === "Any" ? "selected" : "";

      // Filter <select> options block
      const termCount = term.name !== "Any" ? ` (${term.count})` : "";
      selectWrap.querySelector("select").innerHTML += `
          <option value="${term.slug}" ${selected}>${term.name}${termCount}</option>
        `;
    });

    return selectWrap;
  }

  function buildFilterFormInput(filter) {
    const inputWrap = document.createElement("div");
    inputWrap.dataset.filterTerm = String(filter.taxonomy);
    inputWrap.classList.add("filter_group--input");

    const input = document.createElement("input");
    input.type = "text";
    input.name = filter.name;
    input.placeholder = filter.placeholder;

    inputWrap.appendChild(input);
    return inputWrap;
  }

  function buildFilterFormRadio(taxonomyName) {
    // call the restAPI here, get list of terms
    let radioItems = terms_purpose;

    // Generate radioItems quickly:
    // `jq '[[[.[] | {name, slug, count, description}]]]' purpose.json`

    if (taxonomyName === "purpose") {
      radioItems.unshift(termRadioObj("Any"));
    }

    // Loop over each item, build out...
    const inputs = [];
    Object.values(radioItems).forEach((term) => {
      if (term.count === 0) return;

      // Filter input radio block
      const topicDiv = document.createElement("div");

      // todo: [] This should be abstracted better into the function that gives "any".

      const termObj = termRadioObj(term.name, term.slug);

      topicDiv.classList.add("topic", termObj.slug);
      topicDiv.innerHTML = `
        <label data-filter-term-${taxonomyName}="${termObj.slug}">
          <div class="label">${term.name}</div>
          <input type="radio" name="${taxonomyName}" value="${termObj.slug}" ${termObj.default}>
        </label>
      `;

      // Description
      if (termObj.slug !== "") {
        const descriptionButton = document.createElement("div");
        descriptionButton.classList.add("description");
        descriptionButton.innerHTML = "i";
        initialiseTippyOn({
          element: descriptionButton,
          theme: "library_filter_description",
          placement: "top",
          content: term.description,
          allowHTML: true,
          // offset: [0, 10],
        });
        topicDiv.appendChild(descriptionButton);
      }

      // Save it
      inputs.push(topicDiv);
    });

    return inputs;
  }

  document.addEventListener('loginEvent', () => clearRESTFilter(postSlug, libraryFilter));
  document.addEventListener('logoutEvent', () => clearRESTFilter(postSlug, libraryFilter));

  window.libraryFilterMeditation = libraryFilter;
  return libraryFilter;
}

export const createFilterButton = (postSlug = "meditation") => {

  const filterButton = document.createElement("div");
  filterButton.classList.add("action_button");
  filterButton.setAttribute("id", `${postSlug}_library_filter_button`);
  filterButton.innerHTML = `
    <div class='status_dot'>o</div>
    <iconify-icon noobserver icon="${icons.search}"></iconify-icon>
    <div class="label">Filter</div>
  `;

  const libraryFilter = createLibraryFilter(postSlug, filterButton);


  const tippyFilterInstance = tippy.initialiseTippyOn({
    element: filterButton,
    theme: "library_filter",
    placement: "bottom",
    content: "", // Start empty
    allowHTML: true,
    offset: [0, 10],
    animation: "shift-away",
    duration: [200, 100],
    onShow: (instance) => {
      // Clear any existing content from the tooltip and append fresh libraryFilter.
      instance.setContent("");
      instance.setContent(libraryFilter);
      filterButton.classList.add("open");
    },
    onHidden: (instance) => {
      filterButton.classList.remove("open");

      // Ensure libraryFilter goes back to DOM area
      const libraryMain = document.querySelector(`section#${postSlug} div#${postSlug}_main`);
      if (libraryMain && !libraryMain.contains(libraryFilter)) {
        libraryMain.appendChild(libraryFilter);
      }
    },
  });

  return filterButton;
}


// Functions
export function getLibraryFilterSettings(postSlug = "meditation") {
  const enabledFilters = filterPostType[postSlug].enabledFilters;

  // libraryFilter needs to get the DOM object, always. based on postSlug.
  const libraryFilter = window.libraryFilterMeditation;

  if (libraryFilter) {
    const libraryFilterSettings = enabledFilters.reduce((acc, taxonomyName) => {
      acc[taxonomyName] = libraryFilter[taxonomyName];
      return acc;
    }, {});

    return libraryFilterSettings;
  }

  return [];
}

/**
  Load any RESTFilter settings from localStorage
*/
export function loadRESTFilter(postSlug) {
  // Load state out of local storage
  filterPostType[postSlug].enabledFilters.forEach((taxonomyName) => {
    const localStorageValue = window.Userdata.getSettings(
      `${postSlug}_libraryFilter_${taxonomyName}`,
    );

    if (localStorageValue) {
      const libraryFilter = document.querySelector(`section#${postSlug} div#libraryFilter`);

      if (libraryFilter) {
        libraryFilter[taxonomyName] = localStorageValue;
      }
    }
  });
}

document.addEventListener('loginEvent', () => {
  loadRESTFilter('meditation');
});
